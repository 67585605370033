<template>
    <div @click="tryRevealChest" class="notification-container">
        <p v-if="!chestStatus[chestType]" class="notification-container-text small-text">Reveal {{ commit.amount }} {{ $t(`items.${chestType}`) }}</p>
        <p v-else class="notification-container-text">Loading...</p>
    </div>
</template>

<script>
import { mapState, mapActions } from "vuex";

export default {
    name: "BaseNotification",

    components: {},

    props: {
        commit: {
            type: Object,
            default: () => {},
        },
        chestType: {
            type: String,
            default: "wooden",
        },
    },

    mounted() {},

    data() {
        return {
            loading: false,
        };
    },

    methods: {
        ...mapActions("contracts", ["callContractFunction"]),

        tryRevealChest() {
            this.loading = true;
            this.callContractFunction({
                contract: "itemController",
                functionName: "revealChest",
                params: {
                    name: this.chestType,
                },
            });

            setTimeout(() => {
                this.loading = false;
            }, 30 * 1000);
        },
    },

    computed: {
        ...mapState("contracts/itemController", ["chestStatus"]),
    },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.notification-container {
    width: 16rem;
    height: 3rem;
    border-image: url("../../../public/images/modalBg.png") 6 fill repeat;
    border-image-width: 0.5rem;

    p {
        font-size: 1.5rem !important;
    }

    display: flex;
    justify-content: center;
    align-items: center;

    cursor: pointer;

    margin-top: 0.5rem;

    &-text {
        font-size: 1.5rem;
    }

    &:hover {
        filter: brightness(1.1);
    }

    animation: slide-in 0.15s ease-in-out;

    &.error {
        filter: hue-rotate(-20deg);
    }

    &.success {
        filter: hue-rotate(90deg);
    }

    &.warn {
        filter: hue-rotate(17deg);
    }
}

@keyframes slide-in {
    0% {
        transform: translateX(100%) translateY(32px);
    }
    100% {
        transform: translateX(0%) translateY(0px);
    }
}
</style>

<template>
    <div id="app">
        <Transition name="fade">
            <div class="logo-loader" v-if="loading">
                <img width="512" src="../public/images/loader_logo.png" />
                <p class="big=text">Loading...</p>
            </div>
        </Transition>

        <div v-if="!loading" style="width: 100vw; height: 100vh; overflow: hidden">
            <LoginPage v-if="isNewUser || isUnregisteredUser || !isOnBlast"> </LoginPage>
            <router-view v-else></router-view>
        </div>

        <TransitionGroup name="list" class="app-notifications" tag="div">
            <BaseNotification v-for="(notification, index) in notifications" :key="notification.id"
                :notification="notification"></BaseNotification>
        </TransitionGroup>

        <TransitionGroup name="list" class="app-chests" tag="div">
            <template v-for="(chest, index) in AVAILABLE_CHESTS">
                <ChestNotification v-if="pendingCommits[chest]" :key="chest" :commit="pendingCommits[chest]"
                    :chestType="chest"></ChestNotification>
            </template>
        </TransitionGroup>

        <template v-if="!isNewUser && !isUnregisteredUser && isOnBlast">
            <SideMenu></SideMenu>
            <div class="game-info-text">
                <p :title="'Coming soon'" class="small-text">
                    Experience
                    <span class="highlight">{{ (exp / 1e18).toFixed(1) }}</span>
                </p>

                <p class="small-text" v-if="pendingRewards > 0">
                    Pending Harvest
                    <span class="highlight">{{ finalPendingRewards }}</span>
                </p>
            </div>
        </template>
        <component :is="currentModal" v-if="currentModal" @close="closeModal"></component>

        <div v-if="showNPCDialogue" class="app-npc-dialogue">
            <BaseNPCDialogue :npcType="selectedNPC"></BaseNPCDialogue>
        </div>

        <Loading style="z-index: 10001" :active.sync="showSpinner" :can-cancel="false" :background-color="'#000'"
            :opacity="0.6" :is-full-page="true">
            <CustomLoader :spinnerType="spinnerType" :spinnerAnimation="spinnerAnimation" :message="spinnerMessage">
            </CustomLoader>
        </Loading>
        <InfoTooltip v-if="showTooltip"></InfoTooltip>

        <div v-if="showSelectedAdventurer" style="
                transform: scale(2);
                display: flex;
                position: absolute;
                left: 0;
                top: 0;
                right: 0;
                bottom: 0;
                z-index: 10001;
                justify-content: center;
                align-items: center;
            ">
            <div @click="tryHideAdventurerCard"
                style="position: fixed; top: 0; right: 0; left: 0; bottom: 0; background: rgba(0, 0, 0, 0.75)"></div>

            <AdventurerCard :adventurerMetadata="selectedAdventurer"></AdventurerCard>
        </div>
    </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from "vuex";
import _ from "lodash";

import LoginPage from "@/views/LoginPage.vue";
import SideMenu from "@/components/sidebar/SideMenu.vue";
import InfoTooltip from "@/components/modal/InfoTooltip.vue";
import BaseNotification from "@/components/ui/BaseNotification.vue";
import ChestNotification from "@/components/ui/ChestNotification.vue";
import CustomLoader from "@/components/ui/CustomLoader.vue";
import AdventurerCard from "@/components/adventurers/AdventurerCard.vue";
import BaseNPCDialogue from "@/components/ui/BaseNPCDialogue.vue";
// Import component
import Loading from "vue-loading-overlay";
// Import stylesheet
import "vue-loading-overlay/dist/vue-loading.css";
//
export default {
    name: "App",

    components: {
        LoginPage,
        BaseNotification,
        SideMenu,
        InfoTooltip,
        ChestNotification,
        CustomLoader,
        Loading,
        AdventurerCard,
        BaseNPCDialogue
    },

    data() {
        return {};
    },

    beforeDestroy() {
        this.clearUserRefreshInterval();
    },

    computed: {
        ...mapState("site", [
            "currentModal",
            "loading",
            "initialized",
            "showSpinner",
            "spinnerFullscreen",
            "spinnerMessage",
            "spinnerType",
            "spinnerAnimation",
        ]),
        ...mapState("site/npcDialogue", ["showNPCDialogue", "selectedNPC"]),
        ...mapState("site/notifications", ["notifications"]),
        ...mapState("site/tooltip", ["showTooltip"]),
        ...mapState("user", ["pendingRewards", "exp", "profileHarvestBonus"]),
        ...mapGetters("user", ["isNewUser", "isUnregisteredUser", "isOnBlast"]),

        // TEMPORARY
        ...mapState("contracts/itemController", ["AVAILABLE_CHESTS", "pendingCommits"]),
        ...mapState("contracts/adventurers", ["showSelectedAdventurer", "selectedAdventurer"]),

        chestsTimeLeft() {
            const countdown = 1711224041874;
            const time = countdown - Date.now();

            return time < 0 ? 0 : time;
        },

        isMobile() {
            const width = window.innerWidth;
            return width < 768;
        },

        finalPendingRewards() {
            const harvestBonus = this.profileHarvestBonus / 1e18;
            return (this.pendingRewards * (1 + harvestBonus)).toFixed(1);
        },
    },

    methods: {
        ...mapActions("gacha", ["drawGacha"]),
        ...mapActions("site", ["setCurrentModal", "resetCurrentModal", "clearUserRefreshInterval", "boot"]),
        ...mapActions("site/notifications", ["addNotification"]),
        ...mapActions("site/settings", ["playSound"]),
        ...mapActions("site/npcDialogue", ["closeDialogue"]),
        ...mapActions("user", ["getStats"]),
        ...mapActions("contracts/adventurers", ["toggleShowSelectedAdventurer"]),

        closeModal() {
            this.resetCurrentModal();
            this.closeDialogue();
        },

        tryHideAdventurerCard() {
            this.toggleShowSelectedAdventurer(false);
        },
    },
};
</script>

<style lang="scss">
@font-face {
    font-family: "Valley";
    src: url("../public/fonts/ByteBounce.ttf");
}

body {
    margin: 0;
}

.divider {
    width: 100%;
    height: 0.1rem;
    background-color: #743f39;
    opacity: 0.5;
    margin: 0.25rem 0;
}

.list-move,
/* apply transition to moving elements */
.list-enter-active,
.list-leave-active {
    transition: all 0.5s ease;
}

.list-enter-from,
.list-leave-to {
    opacity: 0;
    transform: translateX(30px);
}

/* ensure leaving items are taken out of layout flow so that moving
   animations can be calculated correctly. */
.list-leave-active {
    position: absolute;
}

.fade-move,
/* apply transition to moving elements */
.fade-enter-active,
.fade-leave-active {
    transition: all 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
    opacity: 0;
}

#app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
    overflow: hidden;

    font-family: "Valley", sans-serif;
    color: rgb(255, 243, 243);

    image-rendering: pixelated;

    user-select: none;

    width: 100vw;
    height: 100vh;
}

.beta-notice {
    padding: 0.1rem;
    background: #2b2d31;
    color: lightgrey;
    font-size: 1rem;
}

body {
    overflow: hidden;
    background: #2b2d31;
}

.app-notifications {
    position: fixed;
    top: 2rem;
    right: 2rem;
    z-index: 10001;
}

.app-chests {
    position: fixed;
    bottom: 10rem;
    right: 1rem;
    z-index: 10001;
}

.app-npc-dialogue {
    position: fixed;
    bottom: 0;
    left: calc(50% - 291px);
    z-index: 10001;
}

/* width */
::-webkit-scrollbar {
    width: 1rem;
}

/* Track */
::-webkit-scrollbar-track {
    background: #2c1c1a;
    border-radius: 0.25rem;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #bf6f4a;
    border-radius: 0.25rem;
    border: 2px solid #5d2c28;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #e69c69;
    border: 2px solid #302b28;
}

@keyframes fade-in {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

.logo-loader {
    width: 100vw;
    height: 100vh;

    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    p {
        font-size: 2.5rem;
    }

    gap: 3rem;

    animation: fade-in 0.2s ease-in-out;
    background-color: #2b2d31;

    position: fixed;
    z-index: 9999;
}

.ghost {
    position: absolute;
    opacity: 0.01;
    pointer-events: none;
    user-select: none;
}

.social-links {
    position: fixed;
    bottom: 20px;
    right: 20px;
    z-index: 10;
}

.social-links a {
    display: inline-block;
    margin-left: 10px;
    color: white;
    background-color: rgba(0, 0, 0, 0.8);
    padding: 10px;
    border-radius: 5px;
    text-decoration: none;
}

.account-temporary {
    position: fixed;
    bottom: 20px;
    left: 20px;
    z-index: 10;
    color: white;
    background-color: rgba(0, 0, 0, 0.8);
    padding: 10px;
    border-radius: 5px;
    text-decoration: none;
}

a {
    color: #ffd664;
    cursor: pointer;
    text-decoration: none;

    &:hover {
        color: white;
    }
}

.special-timer {
    display: flex;
    position: absolute;
    bottom: 1.25rem;
    right: 1rem;
    gap: 1rem;
    width: 24rem;
    justify-content: center;
    align-items: center;

    border-radius: 0.25rem;
    border: 1px solid black;
    background: rgba(0, 0, 0, 0.75);
    height: 2rem;
    border-radius: 0.25rem;
    line-height: 2rem;
    padding-left: 1rem;
    padding-right: 1rem;
    cursor: pointer;

    &:hover {
        transform: scale(1.05);
    }
}

.game-info-text {
    position: fixed;
    z-index: 10001;
    bottom: 1rem;
    right: 2rem;
    width: 18rem;

    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    align-items: center;
    justify-content: center;

    p {
        border: 1px solid black;
        background: rgba(0, 0, 0, 0.75);
        width: 100%;
        height: 2rem;
        border-radius: 0.25rem;
        line-height: 2rem;
        padding-left: 1rem;
        padding-right: 1rem;
        cursor: pointer;

        &:hover {
            transform: scale(1.05);
        }
    }
}

.vld-overlay {
    bottom: 0;
    left: 0;
    right: 0;
    top: unset;

    background: rgba(0, 0, 0, 0) !important;
}

// gradient with fading edges
.vld-background {
    background: linear-gradient(90deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.5) 50%, rgba(0, 0, 0, 0) 100%) !important;
}
</style>

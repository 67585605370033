import { ethers } from "ethers";
import { RPCS, createNotification, finalizeNotification, getTxErrorMessage } from "@/libs/storeHelper";
import { getPotionERC1155Rewards, getERC1155FromReceipt } from "@/libs/web3Helper";

const state = () => ({
    craftingStationContract: null,

    commitData: undefined,

    selectedRecipe: undefined,
    craftAmount: 1,

    lastResults: [],
    lastResultsItemCount: 0,
    shouldReveal: false,
    revealedReels: [0, 0, 0, 0, 0],
    revealStarted: false,

    showPotionsReceivedModal: false,

    adminRevealResult: [],
});

const mutations = {
    initializeContract(state, contract) {
        state.craftingStationContract = contract;
    },

    setCommitData(state, data) {
        state.shouldReveal = false;
        state.commitData = data;
    },

    setSelectedRecipe(state, recipe) {
        state.selectedRecipe = recipe;
    },

    setCraftAmount(state, amount) {
        state.craftAmount = amount;
    },

    setRevealData(state, data) {
        state.revealedReels = [0, 0, 0, 0, 0];
        state.lastResults = data;
        state.shouldReveal = true;
    },

    toggleRevealReel(state, index) {
        state.revealedReels[index] = 1;
    },

    setRevealStarted(state, value) {
        state.revealStarted = value;
    },

    setShowPotionsReceivedModal(state, value) {
        state.showPotionsReceivedModal = value;
    },

    setLastResultsItemCount(state, count) {
        state.lastResultsItemCount = count;
    },

    setAdminReveal(state, result) {
        state.adminRevealResult = result;
    },
};

const actions = {
    boot({ commit, rootState }, { contractAddress }) {
        const abi = require("@/plugins/artefacts/craftingStation.json");

        const { signer, defaultSigner } = rootState.site;
        const contract = new ethers.Contract(contractAddress, abi, signer || defaultSigner);

        commit("initializeContract", contract);
    },

    async initializeData({ commit, state, rootState }) {
        const contract = state.craftingStationContract;
        const { storedAccount } = rootState.user;

        const userCommitData = await contract.getCommitData(storedAccount);

        const data = { commitBlock: Number(userCommitData.commitBlock), amount: Number(userCommitData.amount), recipeId: Number(userCommitData.recipeId) };

        if (!userCommitData || Number(userCommitData.commitBlock) === 0) {
            commit("setCommitData", undefined);
            return;
        }

        const { recipes } = rootState.content;
        const recipe = recipes.find((x) => x.id === data.recipeId);

        const resultTokenId = recipe.baseTokenId;
        data.resultTokenId = resultTokenId;

        if (userCommitData.commitBlock) {
            commit("setCommitData", data);
        }
    },

    selectRecipe({ commit, state }, recipe) {
        commit("setSelectedRecipe", recipe);
    },

    setCraftAmount({ commit }, amount) {
        commit("setCraftAmount", amount);
    },

    toggleRevealReel({ commit, dispatch, state }, { index }) {
        commit("toggleRevealReel", index);

        const totalRevealed = state.revealedReels.reduce((a, b) => a + b, 0);

        if (totalRevealed === state.commitData.amount) {
            setTimeout(() => {
                dispatch("resetGacha");

                if (state.lastResultsItemCount === 0) return;

                commit("setShowPotionsReceivedModal", true);
            }, 500);
        }
    },

    resetGacha({ commit }) {
        commit("setRevealData", []);
        commit("setRevealStarted", false);
        commit("setCommitData", undefined);
    },

    async craftItem({ state, commit, rootState, dispatch }, { recipeId, amount }) {
        try {
            const contract = state.craftingStationContract;
            const { baseGwei } = rootState.site.settings;

            const tx = await contract.commitCraft(recipeId, amount, {
                //gasPrice: baseGwei,
            });

            createNotification({
                dispatch,
                message: `Commit: Craft ${amount} items!`,
                type: "warn",
                id: tx.hash,
                showSpinner: true,
            });

            await tx.wait();

            finalizeNotification({ dispatch, id: tx.hash, message: "Token Approved!" });

            await dispatch("initializeData");
        } catch (err) {
            dispatch("site/notifications/clearAll", {}, { root: true });
            getTxErrorMessage(dispatch, err);
        }
    },

    async revealCommit({ state, commit, rootState, dispatch }) {
        try {
            const contract = state.craftingStationContract;
            const { storedAccount } = rootState.user;
            const { baseGwei } = rootState.site.settings;

            const tx = await contract.revealCraft();

            createNotification({
                dispatch,
                message: "Reveal potions!",
                type: "warn",
                id: tx.hash,
                showSpinner: true,
            });

            commit("setRevealStarted", true);

            const receipt = await tx.wait({
                //gasPrice: baseGwei,
            });
            const results = getPotionERC1155Rewards(receipt, contract);

            const itemIds = [];
            const itemAmounts = [];

            for (let i = 0; i < results.length; i++) {
                if (results[i] === 0) continue;

                const itemId = state.commitData.resultTokenId + (results[i] - 1);
                const itemAmount = 1;

                itemIds.push(itemId);
                itemAmounts.push(itemAmount);
            }

            commit("setLastResultsItemCount", itemIds.length);

            commit("user/setLastItemsReceived", { itemIds, itemAmounts }, { root: true });
            commit("setRevealData", results);

            finalizeNotification({ dispatch, id: tx.hash, message: `Crafted s` });
        } catch (err) {
            dispatch("site/notifications/clearAll", {}, { root: true });
            getTxErrorMessage(dispatch, err);
        }
    },

    async adminRevealForUser({ state, dispatch, rootState, commit }, { txHash }) {
        const contract = state.craftingStationContract;
        const { cropsContract } = rootState.contracts.crops;

        const provider = new ethers.JsonRpcProvider(RPCS.mainnet);
        const transactionDetails = await provider.getTransaction(txHash);
        const { from, blockNumber } = transactionDetails;
        const blockDetails = await provider.getBlock(blockNumber);
        const { hash } = blockDetails;

        let tx;

        try {
            const gasEstimation = await contract.revealCraftForUser.estimateGas(from, hash);
            tx = await contract.revealCraftForUser(from, hash, {
                gasLimit: parseInt(Number(gasEstimation)),
            });

            createNotification({
                dispatch,
                type: "warn",
                message: `Tx Pending: Revealing Craft`,
                id: `${tx.hash}`,
                showSpinner: true,
            });

            const receipt = await tx.wait();

            const itemsFromReceipt = getERC1155FromReceipt(receipt, cropsContract);
            const gachaResult = [];

            itemsFromReceipt.ids.forEach((id, index) => {
                for (let i = 0; i < itemsFromReceipt.values[index]; i++) {
                    gachaResult.push(id);
                }
            });

            const itemIds = seedsFromReceipt.ids.map((c) => c);
            const itemAmounts = seedsFromReceipt.values.map((c) => c);

            const { itemsDataRaw } = rootState.content;
            const seeds = itemIds.map((id, index) => itemsDataRaw.find((c) => c.tokenId === id).name + " x " + itemAmounts[index]);

            commit("setAdminReveal", seeds);
            dispatch("site/notifications/remove", tx.hash, { root: true });
        } catch (error) {
            // dispatch("site/notifications/clearAll", {}, { root: true });
            console.log(error);
            getTxErrorMessage(dispatch, error);
        }
    },
};

const getters = {};

export default {
    state,
    mutations,
    getters,
    actions,
    namespaced: true,
};

import Vue from "vue";

import { ethers } from "ethers";
import { createNotification, finalizeNotification } from "@/libs/storeHelper";

import { getMarketStats, getMarketplaceOrders, getRecentActivity } from "@/api/marketplace";

import _ from "lodash";

import BigNumber from "bignumber.js";
const marketFilters = require("@/plugins/marketFilters.json");

const state = () => ({
    marketplaceContract: null,

    marketplaceOrders: [],
    marketplaceUserOrders: [],
    recentActivity: [],
    userRecentActivity: [],

    currentPage: 0,
    navigationGuard: false,
    pageSize: 8,

    currentItem: null,
    currentOrder: null,

    hasApproval: false,
    hasTokenApproval: false,
    tokenAllowance: 0,

    marketFilters: [],
    marketFiltersMapping: {},

    searchFilters: [],
    searchQuery: "",
    searchQueryFilters: [],

    orderDirection: "asc",
    orderBy: "price",
    myListings: false,

    bulkActionType: "none",
    bulkActionOrders: [],

    isLoading: false,

    totalVolume: 0,
    totalListings: 0,
    totalFees: 0,
    totalSales: 0,
});

const mutations = {
    initializeContract(state, contract) {
        state.marketplaceContract = contract;
    },

    setMarketStats(state, stats) {
        const { totalFees, totalListings, totalSales, volume } = stats;

        state.totalFees = (5018 + Number(totalFees) / 1e18).toFixed();
        state.totalListings = totalListings.toFixed();
        state.totalSales = (40704 + totalSales).toFixed();
        state.totalVolume = (100360 + Number(volume) / 1e18).toFixed();
    },

    // Marketplace Orders & Recent Activity
    setMarketplaceOrders(state, orders) {
        state.marketplaceOrders = orders;
    },

    setUserRecentActivity(state, activity) {
        state.userRecentActivity = activity;
    },

    setRecentActivity(state, activity) {
        state.recentActivity = activity;
    },

    setUserMarketplaceOrders(state, orders) {
        state.marketplaceUserOrders = orders;
    },

    // Navigation

    setCurrentItem(state, item) {
        state.currentItem = item;
    },

    setCurrentOrder(state, order) {
        state.currentOrder = order;
    },

    setApproval(state, hasApproval) {
        state.hasApproval = hasApproval;
    },

    setTokenApproval(state, hasApproval) {
        state.hasTokenApproval = hasApproval;
    },

    setTokenAllowance(state, tokenAllowance) {
        state.hasTokenApproval = tokenAllowance > 0;
        state.tokenAllowance = tokenAllowance;
    },

    setLoadingState(state, isLoading) {
        state.isLoading = isLoading;
    },

    setSortType(state, { type }) {
        const split = type.split(" - ");
        const field = split[0].toLowerCase();
        const direction = split[1].toLowerCase();

        state.orderBy = field;
        state.orderDirection = direction;
    },

    setMyListings(state, myListings) {
        state.myListings = myListings;
    },

    setSearchQuery(state, query) {
        state.searchQuery = query;
    },

    initializeMarketFilters(state, filters) {
        const keys = Object.keys(filters);

        const guid = () => {
            const s4 = () =>
                Math.floor((1 + Math.random()) * 0x10000)
                    .toString(16)
                    .substring(1);
            return `${s4() + s4()}-${s4()}-${s4()}-${s4()}-${s4() + s4() + s4()}`;
        };

        const _shouldExpand = (fname) => {
            return ["all" /*,"crops", "items", "loot"*/].includes(fname);
        };

        const recursiveFilter = (filter, parent) => {
            Object.keys(filter).forEach((key) => {
                const isOption = filter[key].options;

                const filterId = guid();
                const modifiedFilter = {
                    ...filter[key],
                    name: _.startCase(key),
                    selected: true,
                    expanded: _shouldExpand(key),
                    id: filterId,
                };

                if (isOption) {
                    modifiedFilter.options = {};
                    recursiveFilter(filter[key].options, modifiedFilter);
                }

                if (parent.options) {
                    parent.options[key] = modifiedFilter;
                } else {
                    parent[key] = modifiedFilter;
                }

                state.marketFiltersMapping[filterId] = modifiedFilter;
            });
        };

        state.marketFiltersMapping = {};
        recursiveFilter(filters, filters);
        state.marketFilters = filters;
    },

    remakeFilters(state) {
        state.searchFilters = [];
        state.searchQueryFilters = [];
        const keys = Object.keys(state.marketFiltersMapping);

        const searchQuery = state.searchQuery.toLowerCase();

        keys.forEach((key) => {
            const filter = state.marketFiltersMapping[key];
            const name = filter.name.toLowerCase();

            const isSearchIncluded = searchQuery !== "" && name.includes(searchQuery);
            if ((!filter.options && filter.selected) || isSearchIncluded) {
                // only leaves
                const tokenId = filter.tokenId || 0;
                if (tokenId === 0) return;

                if (isSearchIncluded) {
                    state.searchQueryFilters.push(filter);
                    state.searchFilters.push(tokenId);
                    return;
                }

                if (searchQuery !== "" && !isSearchIncluded) return;

                state.searchFilters.push(tokenId);
            }
        });
    },

    navigatePage(state, direction) {
        if (direction === "next") {
            state.currentPage++;
        } else {
            let prevPage = state.currentPage;
            state.currentPage = Math.max(0, state.currentPage - 1);
        }
    },

    resetFilters(state) {
        state.currentPage = 0;
        state.searchFilters = [];
        state.myListings = false;
        state.orderBy = "price";
        state.orderDirection = "asc";
        state.searchQuery = [];
    },

    // Management

    incrementOrderBalance(state, { orderId, amount }) {
        const order = state.marketplaceOrders.find((order) => order.orderId === orderId);
        if (!order) return;

        order.balance += amount;

        let orders = [...state.marketplaceOrders];
        orders = orders.filter((order) => order.balance > 0);

        Vue.set(state, "marketplaceOrders", orders);
    },

    setBulkActionType(state, type) {
        state.bulkActionType = type;
    },

    upsertBulkActionOrder(state, { orderId, amount, price = 0, rawPrice = 0 }) {
        const index = state.bulkActionOrders.findIndex((order) => order.orderId == orderId);
        if (index === -1) {
            state.bulkActionOrders.push({ orderId, amount, price, rawPrice });
        } else {
            state.bulkActionOrders[index].amount = amount;
        }
    },

    removeBulkActionOrder(state, { orderId }) {
        state.bulkActionOrders = state.bulkActionOrders.filter((order) => order.orderId !== orderId);
    },

    clearBulkActionOrders(state) {
        state.bulkActionType = "none";
        state.bulkActionOrders = [];
    },
};

const actions = {
    boot({ commit, dispatch, rootState }, { contractAddress }) {
        const abi = require("@/plugins/artefacts/marketplace.json");

        const { signer, defaultSigner } = rootState.site;
        const contract = new ethers.Contract(contractAddress, abi, signer || defaultSigner);
        commit("initializeContract", contract);

        commit("initializeMarketFilters", marketFilters);
        // Fetch initial data
        dispatch("getApproval");
    },

    // -- SETTERS --

    setCurrentItem({ commit }, item) {
        commit("setCurrentItem", item);
    },

    setCurrentOrder({ commit }, order) {
        commit("setCurrentOrder", order);
    },

    setSearchQuery({ state, commit }, { query }) {
        state.searchQuery = query;
        commit("remakeFilters");
    },

    async navigatePage({ state, dispatch, commit }, { direction }) {
        commit("navigatePage", direction);
        await dispatch("fetchMarketplaceOrders");
    },

    toggleFilter({ state, commit }, { id }) {
        const filter = state.marketFiltersMapping[id];
        filter.selected = !filter.selected;

        const recursiveUnchecking = (filter) => {
            if (filter.options) {
                Object.keys(filter.options).forEach((key) => {
                    filter.options[key].selected = filter.selected;
                    recursiveUnchecking(filter.options[key]);
                });
            }
        };

        recursiveUnchecking(filter);

        commit("remakeFilters");
    },

    toggleFilterExpanded({ state, commit }, { id }) {
        const filter = state.marketFiltersMapping[id];
        if (filter.name === "All") return;
        filter.expanded = !filter.expanded;
    },

    resetMarketplaceFilters({ state, commit }) {
        const keys = Object.keys(state.marketFiltersMapping);
        state.searchQuery = "";

        keys.forEach((key) => {
            const filter = state.marketFiltersMapping[key];
            if (filter.name === "All") {
                filter.selected = true;
                return;
            }

            filter.selected = true;
            if (!filter.options) {
                filter.expanded = false;
            }
        });

        commit("remakeFilters");
    },

    resetMarketplace({ state, commit }) {
        commit("resetFilters");
        commit("setMarketplaceOrders", []);
        commit("setCurrentItem", null);
    },

    // -- FETCH --

    async getMarketplace({ state, commit, dispatch }) {
        try {
            const promises = [];

            promises.push(dispatch("fetchMarketStats"));
            promises.push(dispatch("fetchMarketplaceOrders"));
            promises.push(dispatch("fetchRecentActivity"));

            commit("setLoadingState", true);
            await Promise.all(promises);
            commit("setLoadingState", false);
        } catch (err) {
            console.error(err);
            commit("setLoadingState", false);
        }
    },

    async getMarketplaceOrders({ state, commit, dispatch }) {
        try {
            commit("setLoadingState", true);
            await dispatch("fetchMarketplaceOrders");
            commit("setLoadingState", false);
        } catch (err) {
            console.error(err);
            commit("setLoadingState", false);
        }
    },

    async getApproval({ state, commit, rootState }) {
        const contract = state.marketplaceContract;
        const { itemsContract } = rootState.contracts.items;
        const { yieldContract } = rootState.contracts.yield;
        const { storedAccount } = rootState.user;

        const hasApproval = await itemsContract.isApprovedForAll(storedAccount, contract.target);
        commit("setApproval", hasApproval);

        const tokenAllowance = Number(await yieldContract.allowance(storedAccount, contract.target));
        commit("setTokenAllowance", tokenAllowance);
    },

    async fetchMarketStats({ commit }) {
        try {
            const stats = await getMarketStats();
            commit("setMarketStats", stats);
        } catch (err) {
            console.error(err);
        }
    },

    async fetchMarketplaceOrders({ state, rootState, commit }) {
        try {
            const { itemsContract } = rootState.contracts.items;
            const { allItemsData } = rootState.content;

            const filters = {
                market: itemsContract.target,
                page: state.currentPage,
                perPage: state.pageSize,
                orderDirection: state.orderDirection,
                orderBy: state.orderBy,
                searchFilters: state.searchFilters,
            };

            if (state.myListings) {
                filters.seller = rootState.user.storedAccount.toLowerCase();
            }
            commit("setLoadingState", true);
            const orders = await getMarketplaceOrders(filters);

            orders.forEach((order) => {
                const item = allItemsData.find((item) => item.tokenId == order.tokenId);
                if (!item) return;

                order.item = item;
            });

            commit("setMarketplaceOrders", orders);
            commit("setLoadingState", false);
        } catch (err) {
            console.error(err);
            commit("setLoadingState", false);
        }
    },

    async upsertBatchBuyOrder({ state, rootState, commit }, { tokenId, pricePerItem, budget }) {
        try {
            const { itemsContract } = rootState.contracts.items;
            const { allItemsData } = rootState.content;

            const filters = {
                market: itemsContract.target,
                page: 0,
                orderDirection: state.orderDirection,
                orderBy: state.orderBy,
                searchFilters: [tokenId],
                maxPricePerItem: (Number(pricePerItem) * 1e18).toString(),
            };

            commit("setLoadingState", true);
            const orders = await getMarketplaceOrders(filters);
            let maxBudget = Number(budget);

            orders.forEach((order) => {
                const item = allItemsData.find((item) => item.tokenId == order.tokenId);
                if (!item) return;
                order.item = item;
                if (order.rawPrice > maxBudget) {
                    commit("setLoadingState", false);
                    return;
                }
                let amount = order.balance;
                if (order.rawPrice * order.balance > maxBudget) {
                    amount = Math.floor(maxBudget / order.rawPrice);
                }
                maxBudget -= order.rawPrice * amount;
                commit("upsertBulkActionOrder", { orderId: order.orderId, amount: amount, price: order.price, rawPrice: order.rawPrice });
            });
            commit("setLoadingState", false);
        } catch (err) {
            console.error(err);
            commit("setLoadingState", false);
        }
    },

    async fetchUserMarketplaceOrders({ state, rootState, dispatch, commit }) {
        try {
            const { itemsContract } = rootState.contracts.items;
            const { allItemsData } = rootState.content;
            const storedAccount = rootState.user.storedAccount;

            const filters = {
                market: itemsContract.target,
                page: state.currentPage,
                perPage: 100,
                orderDirection: state.orderDirection,
                orderBy: state.orderBy,
                searchFilters: state.searchFilters,
                seller: storedAccount.toLowerCase(),
            };

            commit("setLoadingState", true);
            const orders = await getMarketplaceOrders(filters);

            orders.forEach((order) => {
                const item = allItemsData.find((item) => item.tokenId == order.tokenId);
                if (!item) return;

                order.item = item;
            });

            commit("setUserMarketplaceOrders", orders);
            commit("setLoadingState", false);
        } catch (err) {
            console.error(err);
            commit("setLoadingState", false);
        }
    },

    async fetchRecentActivity({ state, rootState, commit }) {
        try {
            const { itemsContract } = rootState.contracts.items;
            const { allItemsData } = rootState.content;
            const { storedAccount } = rootState.user;

            const filters = {
                market: itemsContract.target,
                page: state.currentPage,
                perPage: 250,
                orderDirection: state.orderDirection,
                orderBy: state.orderBy,
                searchFilters: state.searchFilters,
            };

            commit("setLoadingState", true);
            const activity = await getRecentActivity(filters);

            activity.forEach((order) => {
                const item = allItemsData.find((item) => item.tokenId == order.tokenId);
                order.rawPrice = Number(order.price / order.balance) / 1e18;
                if (!item) return;

                order.item = item;
            });

            commit("setRecentActivity", activity);
            commit("setLoadingState", false);
        } catch (err) {
            console.error(err);
            commit("setLoadingState", false);
        }
    },

    async fetchUserRecentActivity({ rootState, state, commit }) {
        try {
            const { itemsContract } = rootState.contracts.items;
            const { allItemsData } = rootState.content;
            const { storedAccount } = rootState.user;

            const filters = {
                market: itemsContract.target,
                page: state.currentPage,
                perPage: state.pageSize,
                orderDirection: state.orderDirection,
                orderBy: state.orderBy,
                searchFilters: state.searchFilters,
                seller: storedAccount.toLowerCase(),
                buyer: "",
            };

            commit("setLoadingState", true);
            const activity = await getRecentActivity(filters);

            activity.forEach((order) => {
                const item = allItemsData.find((item) => item.tokenId == order.tokenId);
                order.rawPrice = Number(order.price / order.balance) / 1e18;
                if (!item) return;

                order.item = item;
            });

            commit("setUserRecentActivity", activity);
            commit("setLoadingState", false);
        } catch (err) {
            console.error(err);
            commit("setLoadingState", false);
        }
    },

    async fetchUserPurchases({ rootState, state, commit }) {
        try {
            const { itemsContract } = rootState.contracts.items;
            const { allItemsData } = rootState.content;
            const { storedAccount } = rootState.user;

            const filters = {
                market: itemsContract.target,
                page: state.currentPage,
                perPage: state.pageSize,
                orderDirection: state.orderDirection,
                orderBy: state.orderBy,
                searchFilters: state.searchFilters,
                buyer: storedAccount.toLowerCase(),
            };

            commit("setLoadingState", true);
            const activity = await getRecentActivity(filters);

            activity.forEach((order) => {
                const item = allItemsData.find((item) => item.tokenId == order.tokenId);
                order.rawPrice = Number(order.price / order.balance) / 1e18;
                if (!item) return;

                order.item = item;
            });

            commit("setUserRecentActivity", activity);
            commit("setLoadingState", false);
        } catch (err) {
            console.error(err);
            commit("setLoadingState", false);
        }
    },

    // -- SELL --

    async setApprovalForMarketplace({ state, commit, dispatch, rootState }, { approved = true }) {
        try {
            const contract = state.marketplaceContract;
            const { itemsContract } = rootState.contracts.items;
            const { baseGwei } = rootState.site.settings;

            const tx = await itemsContract.setApprovalForAll(contract.target, approved);

            createNotification({
                dispatch,
                message: `Tx: Approving Marketplace!`,
                type: "warn",
                id: tx.hash,
                showSpinner: true,
            });

            await tx.wait(1);
            commit("setApproval", true);
        } catch (err) {
            console.error(err);
        }
    },

    async setTokenApprovalForMarketplace({ state, commit, dispatch, rootState }, { amount = 0 }) {
        try {
            const contract = state.marketplaceContract;
            const { yieldContract } = rootState.contracts.yield;
            const { baseGwei } = rootState.site.settings;
            const { storedAccount } = rootState.user;

            const bigAmount = amount || BigNumber(1e32).toFixed();

            const tx = await yieldContract.approve(contract.target, bigAmount);

            createNotification({
                dispatch,
                message: `Tx: Approving Marketplace!`,
                type: "warn",
                id: tx.hash,
                showSpinner: true,
            });

            await tx.wait(1);

            const tokenAllowance = Number(await yieldContract.allowance(storedAccount, contract.target));
            commit("setTokenAllowance", tokenAllowance);

            finalizeNotification({ dispatch, id: tx.hash, message: "Token Approved!" });
        } catch (err) {
            console.error(err);
        }
    },

    async sellItem({ state, dispatch, rootState }, { tokenId, price, amount }) {
        try {
            if (price <= 0) {
                createNotification({
                    dispatch,
                    message: `Price must be greater than 0`,
                    type: "error",
                    duration: 5000,
                });
                return;
            }

            const contract = state.marketplaceContract;
            const { baseGwei } = rootState.site.settings;

            const { itemsContract } = rootState.contracts.items;
            const bigPrice = BigNumber(price).multipliedBy(1e18).toFixed();

            const tx = await contract.sell(itemsContract.target, tokenId, bigPrice, amount, {
                //gasPrice: baseGwei,
            });

            createNotification({
                dispatch,
                message: `Tx: Listing ${amount} item${amount > 1 ? "s" : ""}`,
                type: "warn",
                id: tx.hash,
                showSpinner: true,
            });

            await tx.wait();

            finalizeNotification({ dispatch, id: tx.hash, message: "Item Listed!" });
        } catch (err) {
            console.error(err);
        }
    },

    async updateItemPrice({ state, dispatch, rootState }, { orderId, price }) {
        try {
            const contract = state.marketplaceContract;
            const { baseGwei } = rootState.site.settings;

            const { itemsContract } = rootState.contracts.items;
            const bigPrice = BigNumber(price).multipliedBy(1e18).toFixed();

            const tx = await contract.updateSellPrice(orderId, itemsContract.target, bigPrice, {
                //gasPrice: baseGwei,
            });

            createNotification({
                dispatch,
                message: `Tx: Updating price`,
                type: "warn",
                id: tx.hash,
                showSpinner: true,
            });

            await tx.wait();

            const stateOrder = state.marketplaceUserOrders.find((order) => order.orderId === orderId);
            stateOrder.price = bigPrice;
            stateOrder.rawPrice = Number(bigPrice) / 1e18;

            finalizeNotification({ dispatch, id: tx.hash, message: "Price updated!" });
        } catch (err) {
            console.error(err);
        }
    },

    async bulkCancelOrders({ state, commit, dispatch, rootState }) {
        try {
            const contract = state.marketplaceContract;
            const { baseGwei } = rootState.site.settings;
            const { itemsContract } = rootState.contracts.items;

            const orderIds = state.bulkActionOrders.map((order) => order.orderId);
            const amounts = state.bulkActionOrders.map((order) => order.amount);

            const tx = await contract.bulkCancelSell(itemsContract.target, orderIds, amounts, { gasPrice: baseGwei });

            createNotification({
                dispatch,
                message: `Tx: Cancelling ${orderIds.length} items`,
                type: "warn",
                id: tx.hash,
                showSpinner: true,
            });

            commit("clearBulkActionOrders");
            await tx.wait();

            finalizeNotification({ dispatch, id: tx.hash, message: "Sale cancelled!" });
            dispatch("fetchUserMarketplaceOrders");
        } catch (err) {
            console.error(err);
        }
    },

    // -- BUY --

    async bulkBuyOrders({ state, commit, dispatch, rootState }) {
        try {
            const contract = state.marketplaceContract;
            const { baseGwei } = rootState.site.settings;
            const { itemsContract } = rootState.contracts.items;

            const orderIds = state.bulkActionOrders.map((order) => order.orderId);
            const amounts = state.bulkActionOrders.map((order) => order.amount);
            const values = [];

            let totalValue = 0;

            for (let i = 0; i < orderIds.length; i++) {
                const order = state.bulkActionOrders[i];
                values.push(BigNumber(order.amount * order.price).toFixed());
                totalValue += order.amount * order.price;
            }

            const { tokenBalances } = rootState.user;

            if (tokenBalances.rawYield < Number(totalValue / 1e18)) {
                createNotification({
                    dispatch,
                    message: `Insufficient funds`,
                    type: "error",
                    duration: 5000,
                });
                return;
            }

            const tx = await contract.bulkBuy(itemsContract.target, orderIds, values, amounts, {
                //gasPrice: baseGwei,
            });

            createNotification({
                dispatch,
                message: `Tx: Buying ${orderIds.length} items`,
                type: "warn",
                id: tx.hash,
                showSpinner: true,
            });

            commit("clearBulkActionOrders");
            await tx.wait();

            finalizeNotification({ dispatch, id: tx.hash, message: "Items bought!" });

            orderIds.forEach((orderId, index) => {
                commit("incrementOrderBalance", { orderId, amount: Number(-amounts[index]) });
            });
        } catch (err) {
            console.error(err);
        }
    },
};

const getters = {};

export default {
    state,
    mutations,
    getters,
    actions,
    namespaced: true,
};

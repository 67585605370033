import { ethers } from "ethers";
import { createNotification } from "@/libs/storeHelper";

const state = () => ({
    pointsContract: null,
});

const mutations = {
    initializeContract(state, contract) {
        state.pointsContract = contract;
    },
};

const actions = {
    boot({ commit, rootState }, { contractAddress }) {
        const abi = require("@/plugins/artefacts/points.json");

        const { signer, defaultSigner } = rootState.site;
        const contract = new ethers.Contract(contractAddress, abi, signer || defaultSigner);

        commit("initializeContract", contract);
    },

    async getUserInfo({ state, rootState }) {
        const contract = state.pointsContract;
        const { storedAccount } = rootState.user;

        const userDataContract = await contract.getUserData(account);
        const missionsStatus = await contract.getUserMissionsStatus(account);
        const { codes } = userDataContract;
        const availableCodes = await contract.checkCodes(codes.map((c) => c));

        let finalCodes = [];

        for (let i = 0; i < codes.length; i++) {
            if (availableCodes[i]) {
                finalCodes.push(codes[i]);
            }
        }

        const userData = {
            codes: finalCodes,
            points: userDataContract.points,
            invitesClaimed: userDataContract.invitesClaimed,
        };

        return { userData, missionsStatus };
    },

    async claimCode({ state, dispatch, rootState }, { code }) {
        const contract = state.pointsContract;
        const { account } = rootState.user;

        const tx = await contract.join(account, code);

        createNotification({
            type: "warn",
            message: `Tx: Claiming code ${code}`,
            id: `${tx.hash}`,
        });

        await tx.wait();

        dispatch("site/notifications/remove", tx.hash, { root: true });

        createNotification({
            dispatch,
            message: `Successfully claimed code ${code}`,
            duration: 5000,
        });

        dispatch("user/getProfile", {}, { root: true });
    },

    async claimMission({ state, dispatch, rootState }, { missionId, gnomeCode }) {
        const missionIds = [0, 100, 101, 666];

        if (!missionIds.includes(missionId)) {
            throw new Error("Invalid mission id");
        }

        const contract = state.pointsContract;
        let tx;

        let message = "";

        if (missionId === 666) {
            tx = await contract.claimGnomePoints(gnomeCode);
            message = `Tx: Rubbing the gnome for points`;
        }
        if (missionId === 0) {
            tx = await contract.claimFarmLevelMission();
            message = `Tx: Completing Valley Mission`;
        }
        if (missionId === 100) {
            tx = await contract.claimCropsMission();
            message = `Tx: Completing Crops Mission`;
        }
        if (missionId === 101) {
            tx = await contract.claimPacksMission();
            message = `Tx: Completing Packs Mission`;
        }

        createNotification({
            type: "warn",
            message: message,
            id: `${tx.hash}`,
        });

        await tx.wait();

        dispatch("site/notifications/remove", tx.hash, { root: true });

        createNotification({
            dispatch,
            message: `Successfully completed mission`,
            duration: 5000,
        });

        dispatch("user/getProfile", {}, { root: true });
    },
};

const getters = {};

export default {
    state,
    mutations,
    getters,
    actions,
    namespaced: true,
};

import { ethers } from "ethers";
import addressManager from "@/libs/addressManager";

const state = () => ({
    relayerContract: null,
});

const mutations = {
    initializeContract(state, contract) {
        state.relayerContract = contract;
    },
};

const actions = {
    async boot({ commit, dispatch, rootState }) {
        const abi = require("@/plugins/artefacts/relayer.json");

        const { defaultSigner, signer } = rootState.site;
        const { chainId } = rootState.user;

        const contract = new ethers.Contract(addressManager[chainId].relayer, abi, defaultSigner || signer);

        commit("initializeContract", contract);

        await dispatch("getRelayerContracts");
    },

    async getRelayerContracts({ state, rootState, dispatch }) {
        const contract = state.relayerContract;

        const contractNames = [
            "yield",
            "farm",
            "dailyChest",
            "crops",
            "seedVendor_v7",
            "seedReveal",
            "profile",
            "items",
            "itemController",
            "wage",
            "sage_v3",
            "bank",
            "dataAggregator_new",
            "tokenReader",
            "farmProxy_v5",
            "leaderboard",
            //
            "adventurers",
            //
            "pfpLens",
            "pfpController",
            "pfp",
            //
            "marketplace_v2",
            "lottery",
            "craftingStation_v2",
            "fishing_v2",
        ];

        const contracts = (await contract.getMultipleContracts(contractNames)).map((x) => x);

        console.log(contracts);

        for (let i = 0; i < contracts.length; i++) {
            const contractData = contracts[i];

            // farmProxy_v2 -> farmProxy etc
            const contractName = contractData[0].split("_")[0];
            const contractAddress = contractData[1];

            if (contractName === "farmProxy") {
                dispatch(
                    `contracts/farm/bootFarmProxy`,
                    { contractAddress },
                    {
                        root: true,
                    },
                );

                continue;
            }

            if (contractName === "seedReveal") {
                // console.log
                dispatch(
                    `contracts/seedVendor/bootSeedReveal`,
                    { contractAddress },
                    {
                        root: true,
                    },
                );

                continue;
            }

            dispatch(
                `contracts/${contractName}/boot`,
                { contractAddress },
                {
                    root: true,
                },
            );
        }

        return contracts;
    },
};

const getters = {};

export default {
    state,
    mutations,
    getters,
    actions,
    namespaced: true,
};

import { ethers } from "ethers";
import { createNotification, finalizeNotification } from "@/libs/storeHelper";
import { getMerkleProof } from "@/api/adventurers";

import _, { set } from "lodash";
import BigNumber from "bignumber.js";

import Vue from "vue";

const state = () => ({
    adventurersContract: null,

    whitelistBalance: 0,
    playerMinted: 0,
    playerLockedMinted: 0,

    userAdventurers: [],

    lastTokenId: 0,
    lastRevealedTokenId: 0,
    lastTokenRarity: 0,
    lastTokenRevealed: false,

    showSpiritAnimation: false,

    showSelectedAdventurer: false,
    selectedAdventurer: undefined,

    price: 1,
    whitelistPrice: 0.5,
    lockedPrice: 100_000,
    maxSupply: 3000,

    isLive: 0,
    totalSupply: 0,
    whitelistOpenTimestamp: 0,
    whitelistCloseTimestamp: 0,

    isSummoning: false,
    summonAnimationPhase: "",

    summonAnimationsToggle: {
        start_summon: false,
        loop_summon: false,
        end_summon: false,
        spawn_common: false,
        spawn_uncommon: false,
        spawn_rare: false,
        spawn_epic: false,
        spawn_legendary: false,
    },

    whitelistUserData: undefined,
    summoningUpdateKey: 0,

    countdownInterval: undefined,
    countdown: 0,
});

const mutations = {
    initializeContract(state, contract) {
        state.adventurersContract = contract;
    },

    setWhitelistBalance(state, balance) {
        state.whitelistBalance = balance;
    },

    setMinted(state, minted) {
        state.playerMinted = minted;
    },

    setLastTokenId(state, tokenId) {
        state.lastTokenId = tokenId;
    },

    setSummoningState(state, isSummoning) {
        state.isSummoning = isSummoning;
    },

    setSummonAnimation(state, phase) {
        Vue.set(state, "summoningUpdateKey", state.summoningUpdateKey + 1);

        const animations = Object.keys(state.summonAnimationsToggle);
        for (const animation of animations) {
            state.summonAnimationsToggle[animation] = false;
        }

        state.summonAnimationsToggle[phase] = true;
    },

    setUserAdventurers(state, adventurers) {
        state.userAdventurers = adventurers;
    },

    setLastTokenRevealed(state, revealed) {
        state.lastTokenRevealed = revealed;
    },

    setLockedMinted(state, lockedMinted) {
        state.playerLockedMinted = lockedMinted;
    },

    setLastRevealedTokenId(state, tokenId) {
        state.lastRevealedTokenId = tokenId;
    },

    setSelectedAdventurer(state, metadata) {
        state.selectedAdventurer = metadata;
    },

    setShowSelectedAdventurer(state, show) {
        state.showSelectedAdventurer = show;
    },

    setSoulSpawnAnimation(state, { rarityDisplay, rarity }) {
        state.summonAnimationsToggle[`spawn_${rarityDisplay}`] = true;
        state.lastTokenRarity = rarity;
    },

    setContractData(state, data) {
        state.isLive = data.isLive;
        state.totalSupply = Number(data.totalSupply);
        state.whitelistOpenTimestamp = Number(data.whitelistOpenTimestamp);
        state.whitelistCloseTimestamp = Number(data.whitelistCloseTimestamp);
    },

    setWhitelistUserData(state, data) {
        if (!data) return;
        state.whitelistUserData = {
            amount: Number(data[1]),
        };
    },

    setShowSpiritAnimation(state, show) {
        state.showSpiritAnimation = show;
    },

    startCountdown(state) {
        state.countdown = 10;
        state.countdownInterval = setInterval(() => {
            state.countdown -= 1;
            if (state.countdown <= 0) {
                clearInterval(state.countdownInterval);
            }
        }, 1000);
    },

    resetCountdown(state) {
        clearInterval(state.countdownInterval);
        state.countdown = 0;
    },

    resetLastSummon(state) {
        state.lastTokenId = 0;
        state.lastTokenRarity = 0;
        state.lastTokenRevealed = false;
    },
};

const actions = {
    boot({ commit, dispatch, rootState }, { contractAddress }) {
        const abi = require("@/plugins/artefacts/adventurers.json");

        const { signer, defaultSigner } = rootState.site;
        const contract = new ethers.Contract(contractAddress, abi, signer || defaultSigner);

        commit("initializeContract", contract);

        dispatch("initializeData");
    },

    async initializeData({ commit, dispatch, state, rootState }) {
        const contract = state.adventurersContract;

        const { storedAccount } = rootState.user;

        const userData = await contract.getUserData(storedAccount);
        const contractData = await contract.getMintGeneralStats();

        const { totalMinted, whitelistBalance, lockedMinted, lastRevealedTokenId, lastCommitTokenId } = userData;

        const whitelistUsers = require("@/plugins/whitelistData.json");

        commit("setMinted", Number(totalMinted));
        commit("setLastTokenId", Number(lastCommitTokenId));
        commit("setWhitelistBalance", Number(whitelistBalance));
        commit("setLastRevealedTokenId", Number(lastRevealedTokenId));
        commit("setLockedMinted", Number(lockedMinted));

        commit("setContractData", contractData);

        const whitelistUserData = whitelistUsers.find((user) => user[0].toLowerCase() === storedAccount.toLowerCase());
        commit("setWhitelistUserData", whitelistUserData);

        if (Number(lastCommitTokenId) !== 0) {
            commit("setSummonAnimation", "loop_summon");
            commit("setSummoningState", true);
        }

        dispatch("getAdventurers");
    },

    async getAdventurers({ commit, dispatch, state, rootState }) {
        const contract = state.adventurersContract;
        const { storedAccount } = rootState.user;

        const tokens = await contract.getTokensOfOwnerWithData(storedAccount);

        const adventurersTokenIds = tokens[0];
        const adventurersTokenData = tokens[1];
        let adventurers = [];

        adventurersTokenData.forEach((adventurer, index) => {
            let skillCount = [Number(adventurer.skill1), Number(adventurer.skill2)].filter((x) => x !== 0).length;

            const adventurerData = {
                tokenId: Number(adventurersTokenIds[index]),
                rarity: Number(adventurer.rarity),
                stats: {
                    vigor: Number(adventurer.vigor),
                    strength: Number(adventurer.strength),
                    agility: Number(adventurer.agility),
                    intelligence: Number(adventurer.intelligence),
                    spirit: Number(adventurer.spirit),
                    luck: Number(adventurer.luck),
                },
                skillsCount: skillCount,
                skills: [Number(adventurer.skill1), Number(adventurer.skill2)],
                allocationPoints: Number(adventurer.allocationPoints),
                revealed: Boolean(adventurer.revealed),
            };

            adventurers.push(adventurerData);
        });

        adventurers = _.orderBy(adventurers, ["rarity"], ["desc"]);

        commit("setUserAdventurers", adventurers);
    },

    startSummonAnimation({ commit }) {
        commit("setSummoningState", true);
        commit("setSummonAnimation", "start_summon");

        setTimeout(() => {
            commit("setSummonAnimation", "loop_summon");
        }, 1.3 * 1000);
    },

    async startRevealAnimation({ commit, state }, { lastTokenId }) {
        const contract = state.adventurersContract;
        const lastTokenData = await contract.getTokenData(lastTokenId);

        const rarity = Number(lastTokenData.rarity);

        let rarityDisplay = "common";
        switch (rarity) {
            case 1:
                rarityDisplay = "uncommon";
                break;
            case 2:
                rarityDisplay = "rare";
                break;
            case 3:
                rarityDisplay = "epic";
                break;
            case 4:
                rarityDisplay = "legendary";
                break;
        }

        const adventurerMetadata = {
            tokenId: lastTokenId,
            rarity: rarity,
            stats: {
                vigor: Number(lastTokenData.vigor),
                strength: Number(lastTokenData.strength),
                agility: Number(lastTokenData.agility),
                intelligence: Number(lastTokenData.intelligence),
                spirit: Number(lastTokenData.spirit),
                luck: Number(lastTokenData.luck),
            },
            skills: [Number(lastTokenData.skill1), Number(lastTokenData.skill2)],
            allocationPoints: Number(lastTokenData.allocationPoints),
        };

        const animationDurations = {
            common: 1.8,
            uncommon: 1.56,
            rare: 1.68,
            epic: 1.68,
            legendary: 2.64,
        };

        const animationDuration = animationDurations[rarityDisplay];
        commit("setSoulSpawnAnimation", { rarityDisplay, rarity });
        commit("setSelectedAdventurer", adventurerMetadata);
        commit("setShowSpiritAnimation", true);

        setTimeout(() => {
            commit("setSummoningState", false);
        }, 1000);

        setTimeout(() => {
            commit("setSummonAnimation", "end_summon");
            commit("setLastTokenRevealed", true);
            commit("setShowSpiritAnimation", false);
        }, animationDuration * 1000);
    },

    async whitelistMint({ state, commit, dispatch, rootState }, { payWithLocked = false }) {
        const contract = state.adventurersContract;
        const { storedAccount } = rootState.user;

        const { proof, amount } = getMerkleProof(storedAccount);

        console.log("AHAH: ", proof, amount, payWithLocked);

        const tx = await contract.whitelistMint(proof, amount, payWithLocked);

        commit("resetLastSummon");
        dispatch("startSummonAnimation");

        createNotification({
            dispatch,
            message: `Tx: Summoning Ancient Soul!`,
            type: "warn",
            id: tx.hash,
            showSpinner: true,
        });

        dispatch("site/resetCurrentModal", {}, { root: true });

        await tx.wait();

        commit("startCountdown");
        dispatch("initializeData");
    },

    async publicMint({ state, commit, dispatch, rootState }, { payWithLocked = false }) {
        const contract = state.adventurersContract;
        const { storedAccount } = rootState.user;
        const { baseGwei } = rootState.site.settings;

        const tx = await contract.publicMint(payWithLocked, {});

        commit("resetLastSummon");
        dispatch("startSummonAnimation");

        createNotification({
            dispatch,
            message: `Tx: Summoning Ancient Soul!`,
            type: "warn",
            id: tx.hash,
            showSpinner: true,
        });

        dispatch("site/resetCurrentModal", {}, { root: true });

        await tx.wait();

        commit("startCountdown");
        dispatch("initializeData");
    },

    async revealLastSummon({ state, dispatch, rootState }) {
        const contract = state.adventurersContract;
        const { storedAccount } = rootState.user;

        const { lastTokenId } = state;

        if (lastTokenId === 0) {
            createNotification({
                dispatch,
                message: `Error: No summon to reveal!`,
                type: "error",
                duration: 5000,
            });
            return;
        }

        const tx = await contract.reveal(lastTokenId);

        createNotification({
            dispatch,
            message: `Tx: Revealing Ancient Soul!`,
            type: "warn",
            id: tx.hash,
            showSpinner: true,
        });

        await tx.wait();

        dispatch("getAdventurers");
        dispatch("startRevealAnimation", { lastTokenId });
        finalizeNotification({ dispatch, id: tx.hash, message: "Ancient Soul Revealed!" });
        dispatch("initializeData");
    },

    //

    toggleShowSelectedAdventurer({ commit }, { show }) {
        commit("setShowSelectedAdventurer", show);
    },

    setSelectedAdventurer({ commit }, { adventurerMetadata, show = false }) {
        commit("setSelectedAdventurer", adventurerMetadata);
        commit("setShowSelectedAdventurer", show);
    },
};

const getters = {
    hasSummonPending(state) {
        return state.isSummoning;
    },
};

export default {
    state,
    mutations,
    getters,
    actions,
    namespaced: true,
};

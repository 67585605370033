import { ethers } from "ethers";
import { createNotification, finalizeNotification } from "@/libs/storeHelper";

const state = () => ({
    itemsContract: null,
});

const mutations = {
    initializeContract(state, contract) {
        state.itemsContract = contract;
    },
};

const actions = {
    boot({ commit, rootState }, { contractAddress }) {
        const abi = require("@/plugins/artefacts/items.json");

        const { signer, defaultSigner } = rootState.site;
        const contract = new ethers.Contract(contractAddress, abi, signer || defaultSigner);

        commit("initializeContract", contract);
    },

    async getCropsBalance({ state, rootState }) {
        const contract = state.itemsContract;
        const cropTypes = rootState.content.cropTypes;
        const account = rootState.user.storedAccount;

        const accounts = new Array(cropTypes.length).fill(account);
        const seedIds = [];
        for (let i = 1; i <= cropTypes.length; i++) {
            seedIds.push(i);
        }

        const cropsBalances = await contract.balanceOfBatch(accounts, seedIds);

        const crops = cropsBalances.map((balance, index) => {
            return {
                tokenId: index,
                balance: parseFloat(balance),
            };
        });

        return crops;
    },

    async sendNft({ state, commit, dispatch, rootState }, { tokenId, to, amount = 1 }) {
        const contract = state.itemsContract;
        const account = rootState.user.storedAccount;

        if (!to || to === "") {
            createNotification({
                dispatch,
                message: `Error: No recipient address!`,
                type: "error",
                duration: 5000,
            });
            return;
        }

        if (to.toLowerCase() === account.toLowerCase()) {
            createNotification({
                dispatch,
                message: `Error: Cannot send NFT to yourself!`,
                type: "error",
                duration: 5000,
            });
            return;
        }

        const tx = await contract.safeTransferFrom(account, to, tokenId, amount, "0x");

        createNotification({
            dispatch,
            message: `Tx: Sending NFT!`,
            type: "warn",
            id: tx.hash,
            showSpinner: true,
        });
        await tx.wait(1);

        finalizeNotification({ dispatch, id: tx.hash, message: `NFT sent!`, duration: 5000 });

        if (tokenId < 100) {
            dispatch("getCropsBalance");
        } else {
            dispatch("getItemsBalances");
        }
    },

    async getItemsBalances({ state, rootState }) {
        const contract = state.itemsContract;
        const itemsDataRaw = rootState.content.itemsDataRaw;
        const account = rootState.user.storedAccount;

        const accounts = new Array(itemsDataRaw.length).fill(account);
        const itemIds = itemsDataRaw.map((i) => i.tokenId);

        const itemsBalances = await contract.balanceOfBatch(accounts, itemIds);
        const items = itemsBalances.map((balance, index) => {
            return {
                tokenId: itemsDataRaw[index].tokenId,
                balance: parseFloat(balance),
            };
        });

        return items;
    },

    async getFishesBalances({ state, rootState }) {
        const contract = state.itemsContract;
        const fishesDataRaw = rootState.content.fishesDataRaw;
        const account = rootState.user.storedAccount;

        const accounts = new Array(fishesDataRaw.length).fill(account);
        const itemIds = fishesDataRaw.map((i) => i.tokenId);

        const itemsBalances = await contract.balanceOfBatch(accounts, itemIds);
        const items = itemsBalances.map((balance, index) => {
            return {
                tokenId: fishesDataRaw[index].tokenId,
                balance: parseFloat(balance),
            };
        });

        return items;
    },
};

const getters = {};

export default {
    state,
    mutations,
    getters,
    actions,
    namespaced: true,
};

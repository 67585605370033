const { axios } = require("../libs/axios");

/*export async function getContentCache() {
    try {
        const result = await axios.get("/api/content/packed");
        const data = result.data;
        return data;
    } catch (err) {
        return {};
    }
}*/

/// TODO: Placeholder until the server goes live
export async function getContentCache() {
    try {
        const data = {
            items,
            crops,
            fishes,
        };
        return data;
    } catch (err) {
        return {};
    }
}

const crops = [
    {
        name: "wheat",
        tokenId: 1,
        imageIndex: 1,
        price: 1,
        life: 10800,
        produceId: 1001,
        reward: 50,
        rarity: "common",
        description: "A basic ingredient for bakers and brewers in the realm.",
        seedDescription: "Seed for planting wheat, essential for bread and brew crafting.",
    },
    {
        name: "tomato",
        tokenId: 2,
        imageIndex: 2,
        price: 1,
        life: 10800,
        produceId: 1002,
        reward: 50,
        rarity: "common",
        description: "Essential for cooking sauces and stews for adventurers.",
        seedDescription: "Seed for growing tomatoes, perfect for concocting hearty meals.",
    },
    {
        name: "carrot",
        tokenId: 3,
        imageIndex: 3,
        price: 1,
        life: 10800,
        produceId: 1003,
        reward: 50,
        rarity: "common",
        description: "Crunchy and sweet, perfect for a quick snack or a stew ingredient.",
        seedDescription: "Seed for cultivating carrots, known for their sweet crunch.",
    },
    {
        name: "corn",
        tokenId: 4,
        imageIndex: 4,
        price: 1,
        life: 10800,
        produceId: 1004,
        reward: 50,
        rarity: "common",
        description: "Versatile for meals and snacks, favored by travelers.",
        seedDescription: "Seed for sowing corn, yields versatile grains for various dishes.",
    },
    {
        name: "pumpkin",
        tokenId: 5,
        imageIndex: 5,
        price: 3,
        life: 14400,
        produceId: 1005,
        reward: 90,
        rarity: "uncommon",
        description: "Good for pies and magical brews, a fall favorite.",
        seedDescription: "Seed for pumpkin, crucial for fall festivals and magical concoctions.",
    },
    {
        name: "chilli",
        tokenId: 6,
        imageIndex: 6,
        price: 3,
        life: 14400,
        produceId: 1006,
        reward: 90,
        rarity: "uncommon",
        description: "Adds fire to any dish, handle with care.",
        seedDescription: "Seed for growing chilli, ignites dishes with fiery spice.",
    },
    {
        name: "parsnip",
        tokenId: 7,
        imageIndex: 7,
        price: 3,
        life: 14400,
        produceId: 1007,
        reward: 90,
        rarity: "uncommon",
        description: "A peppery root that's popular in salads and potions.",
        seedDescription: "Seed for parsnip, brings a peppery kick to dishes and brews.",
    },
    {
        name: "celery",
        tokenId: 8,
        imageIndex: 8,
        price: 5,
        life: 28800,
        produceId: 1008,
        reward: 120,
        rarity: "rare",
        description: "Crunchy and refreshing, used in healing potions.",
        seedDescription: "Seed for celery, grows into a crunchy snack with refreshing powers.",
    },
    {
        name: "broccoli",
        tokenId: 9,
        imageIndex: 9,
        price: 5,
        life: 28800,
        produceId: 1009,
        reward: 120,
        rarity: "rare",
        description: "Valued for its restorative properties in cooking.",
        seedDescription: "Seed for broccoli, sprouts into a vegetable with healing virtues.",
    },
    {
        name: "cauliflower",
        tokenId: 10,
        imageIndex: 10,
        price: 10,
        life: 43200,
        produceId: 1010,
        reward: 200,
        rarity: "epic",
        description: "A mystical vegetable, used in many elixirs.",
        seedDescription: "Seed for cauliflower, grows into a mystical vegetable for enchanting elixirs.",
    },
    {
        name: "berry",
        tokenId: 11,
        imageIndex: 11,
        price: 25,
        life: 43200,
        produceId: 1011,
        reward: 200,
        rarity: "epic",
        description: "Sweet and mystical, used in many elixirs.",
        seedDescription: "Seed for berries, cultivates sweet fruits for enchanting elixirs.",
    },
    {
        name: "grapes",
        tokenId: 12,
        imageIndex: 12,
        price: 10,
        life: 86400,
        produceId: 1012,
        reward: 650,
        rarity: "legendary",
        description: "Favored by nobility, used in fine dining and rare potions.",
        seedDescription: "Seed for grapes, grows vines for the fruits of nobility and fine wines.",
    },

    {
        name: "banana",
        tokenId: 13,
        imageIndex: 9,
        life: 10800,
        premium: true,
        reward: 125,
        rarity: "common",
        description: "A staple of tropical diets, energizing adventurers and locals alike.",
        seedDescription: "Seed for bananas, grows into lush trees bearing the fruit of energy and nutrition.",
    },
    {
        name: "mango",
        tokenId: 14,
        imageIndex: 1,
        life: 10800,
        premium: true,
        reward: 125,
        rarity: "common",
        description: "Exotic and juicy, sought after for its sweet, revitalizing essence.",
        seedDescription: "Seed for mangoes, cultivating trees that produce the golden treasure of the tropics.",
    },
    {
        name: "avocado",
        tokenId: 15,
        imageIndex: 8,
        life: 10800,
        premium: true,
        reward: 125,
        rarity: "common",
        description: "Creamy and nutritious, a favorite for health potions and gourmet meals.",
        seedDescription: "Seed for avocados, sprouts into trees with fruits beloved for their healing fats.",
    },
    {
        name: "pineapple",
        tokenId: 16,
        imageIndex: 4,
        life: 14400,
        premium: true,
        reward: 225,
        rarity: "uncommon",
        description: "Tart and sweet, used in potions for strength and cooking for exotic flavor.",
        seedDescription: "Seed for pineapples, yields spiky plants with the fruit of warriors and explorers.",
    },
    {
        name: "blueberry",
        tokenId: 17,
        imageIndex: 3,
        life: 14400,
        premium: true,
        reward: 225,
        rarity: "uncommon",
        description: "Tiny yet powerful, imbued with magic to boost health and vitality.",
        seedDescription: "Seed for blueberries, grows into bushes laden with the berries of life.",
    },
    {
        name: "artichoke",
        tokenId: 18,
        imageIndex: 11,
        life: 14400,
        premium: true,
        reward: 225,
        rarity: "uncommon",
        description: "Mystical in appearance and taste, enhances magical resistance when consumed.",
        seedDescription: "Seed for artichokes, produces thistle-like plants with magical properties.",
    },
    {
        name: "papaya",
        tokenId: 19,
        imageIndex: 2,
        life: 28800,
        premium: true,
        reward: 300,
        rarity: "rare",
        description: "Sweet and healing, papayas offer restoration and are prized in medicinal brews.",
        seedDescription: "Seed for papaya, grows tropical trees with the fruit of regeneration.",
    },
    {
        name: "fig",
        tokenId: 20,
        imageIndex: 10,
        life: 28800,
        premium: true,
        reward: 300,
        rarity: "rare",
        description: "Ancient and sweet, figs are known to unlock mystic energies and wisdom.",
        seedDescription: "Seed for figs, cultivates trees that bear the fruit of knowledge and power.",
    },
    {
        name: "lichi",
        tokenId: 21,
        imageIndex: 6,
        life: 43200,
        premium: true,
        reward: 500,
        rarity: "epic",
        description: "Exotic and fragrant, lychees are coveted for their burst of sweet energy.",
        seedDescription: "Seed for lychees, grows trees with fruits that enchant the senses and invigorate the soul.",
    },
    {
        name: "lavender",
        tokenId: 22,
        imageIndex: 5,
        life: 43200,
        premium: true,
        reward: 500,
        rarity: "epic",
        description: "Calming and aromatic, used in spells for peace and potions for rest.",
        seedDescription: "Seed for lavender, yields fragrant plants with the power to soothe and heal.",
    },
    {
        name: "dragonfruit",
        tokenId: 23,
        imageIndex: 7,
        life: 86400,
        premium: true,
        reward: 1625,
        rarity: "legendary",
        description: " Unique and refreshing, known for their striking appearance and sweet taste.",
        seedDescription: "Produces vibrant cacti with nutrient-rich, flavorful fruits.",
    },

    {
        name: "potato",
        tokenId: 24,
        imageIndex: 1,
        reward: 10,
        rarity: "common",
        life: 10800,
        produceId: 1001,
        description: "A staple of many diets, versatile and filling.",
        seedDescription: "Seed for potatoes, essential for hearty meals and fortifying stews.",
    },
    {
        name: "lettuce",
        tokenId: 25,
        imageIndex: 2,
        reward: 18,
        rarity: "uncommon",
        life: 14400,
        produceId: 1002,
        description: "Crisp and refreshing, a favorite for salads and wraps.",
        seedDescription: "Seed for lettuce, grows into leafy greens for refreshing meals.",
    },
    {
        name: "cabbage",
        tokenId: 26,
        imageIndex: 3,
        reward: 24,
        rarity: "rare",
        life: 28800,
        produceId: 1003,
        description: "A hearty vegetable, perfect for fortifying meals.",
        seedDescription: "Seed for cabbage, yields leafy greens for fortifying meals.",
    },
    {
        name: "onion",
        tokenId: 27,
        imageIndex: 4,
        reward: 40,
        rarity: "epic",
        life: 43200,
        produceId: 1004,
        description: "A pungent and versatile ingredient for many dishes.",
        seedDescription: "Seed for onions, essential for savory meals and healing brews.",
    },
    {
        name: "radish",
        tokenId: 28,
        imageIndex: 5,
        reward: 130,
        rarity: "legendary",
        life: 86400,
        produceId: 1005,
        description: "A peppery root that's popular in salads and potions.",
        seedDescription: "Seed for radish, brings a peppery kick to dishes and brews.",
    },

    {
        name: "feeble_potato",
        tokenId: 29,
        imageIndex: 1,
        reward: 0.5,
        rarity: "common",
        life: 10800,
        produceId: 1001,
        description: "A staple of many diets, versatile and filling.",
        seedDescription: "Seed for potatoes, essential for hearty meals and fortifying stews.",
    },
    {
        name: "feeble_lettuce",
        tokenId: 30,
        imageIndex: 2,
        reward: 0.9,
        rarity: "uncommon",
        life: 14400,
        produceId: 1002,
        description: "Crisp and refreshing, a favorite for salads and wraps.",
        seedDescription: "Seed for lettuce, grows into leafy greens for refreshing meals.",
    },
    {
        name: "feeble_cabbage",
        tokenId: 31,
        imageIndex: 3,
        reward: 1.2,
        rarity: "rare",
        life: 28800,
        produceId: 1003,
        description: "A hearty vegetable, perfect for fortifying meals.",
        seedDescription: "Seed for cabbage, yields leafy greens for fortifying meals.",
    },
    {
        name: "feeble_onion",
        tokenId: 32,
        imageIndex: 4,
        reward: 2,
        rarity: "epic",
        life: 43200,
        produceId: 1004,
        description: "A pungent and versatile ingredient for many dishes.",
        seedDescription: "Seed for onions, essential for savory meals and healing brews.",
    },
    {
        name: "feeble_radish",
        tokenId: 33,
        imageIndex: 5,
        reward: 6.5,
        rarity: "legendary",
        life: 86400,
        produceId: 1005,
        description: "A peppery root that's popular in salads and potions.",
        seedDescription: "Seed for radish, brings a peppery kick to dishes and brews.",
    },
];
const items = [
    {
        name: "growthElixir",
        tokenId: 100,
        price: 1,
        rarity: "common",
        contract: "watercan_v2",
        tKey: "growth",
        value: 3,
        uses: 2,
    },
    {
        name: "growthElixirII",
        tokenId: 101,
        price: 2,
        rarity: "uncommon",
        contract: "watercan_v2",
        tKey: "growth",
        value: 6,
        uses: 2,
    },
    {
        name: "growthElixirIII",
        tokenId: 102,
        price: 3,
        rarity: "rare",
        contract: "watercan_v2",
        tKey: "growth",
        value: 12,
        uses: 2,
    },

    {
        name: "fertilizer",
        tokenId: 103,
        price: 1,
        rarity: "rare",
        contract: "plotModifier_v2",
        tKey: "fertilizer",
        value: 4,
        uses: 1,
    },
    {
        name: "fertilizerII",
        tokenId: 104,
        price: 2,
        rarity: "epic",
        contract: "plotModifier_v2",
        tKey: "fertilizer",
        value: 6,
        uses: 1,
    },
    {
        name: "fertilizerIII",
        tokenId: 105,
        price: 3,
        rarity: "legendary",
        contract: "plotModifier_v2",
        tKey: "fertilizer",
        value: 8,
        uses: 1,
    },

    {
        name: "pesticide",
        tokenId: 106,
        price: 1,
        rarity: "uncommon",
        contract: "plotModifier_v2",
        tKey: "pesticide",
        value: 1,
        uses: 1,
    },
    {
        name: "pesticideII",
        tokenId: 107,
        price: 2,
        rarity: "rare",
        contract: "plotModifier_v2",
        tKey: "pesticide",
        value: 2,
        uses: 1,
    },
    {
        name: "pesticideIII",
        tokenId: 108,
        price: 2,
        rarity: "rare",
        contract: "plotModifier_v2",
        tKey: "pesticide",
        value: 3,
        uses: 1,
    },
    {
        name: "woodenChest",
        tokenId: 109,
        rarity: "common",
        contract: "woodenChest",
        tKey: "woodenChest",
    },
    {
        name: "bronzeChest",
        tokenId: 110,
        rarity: "rare",
        contract: "bronzeChest",
        tKey: "bronzeChest",
    },
    {
        name: "silverChest",
        tokenId: 111,
        rarity: "epic",
        contract: "silverChest",
        tKey: "silverChest",
    },
    {
        name: "goldenChest",
        tokenId: 112,
        rarity: "legendary",
        contract: "goldenChest",
        tKey: "goldenChest",
    },
    {
        name: "platinumChest",
        tokenId: 113,
        rarity: "legendary",
        contract: "platinumChest",
        tKey: "platinumChest",
    },
    {
        name: "baitI",
        tokenId: 114,
        rarity: "common",
        contract: "",
        tKey: "baitI",
    },
    {
        name: "baitII",
        tokenId: 115,
        rarity: "uncommon",
        contract: "",
        tKey: "baitII",
    },
    {
        name: "baitIII",
        tokenId: 116,
        rarity: "rare",
        contract: "",
        tKey: "baitIII",
    },
    {
        name: "lifeBud",
        tokenId: 130,
        rarity: "legendary",
        contract: "",
        tKey: "lifeBud",
    },
    {
        name: "anchovy",
        tokenId: 121,
        rarity: "common",
        contract: "",
        tkey: "anchovy",
    },
    {
        name: "sardine",
        tokenId: 122,
        rarity: "common",
        contract: "",
        tkey: "sardine",
    },
    {
        name: "herring",
        tokenId: 123,
        rarity: "uncommon",
        contract: "",
        tkey: "herring",
    },
    {
        name: "smallTrout",
        tokenId: 124,
        rarity: "uncommon",
        contract: "",
        tkey: "smallTrout",
    },
    {
        name: "yellowPerch",
        tokenId: 125,
        rarity: "rare",
        contract: "",
        tkey: "yellowPerch",
    },
    {
        name: "salmon",
        tokenId: 126,
        rarity: "rare",
        contract: "",
        tkey: "salmon",
    },
    {
        name: "orangeRoughy",
        tokenId: 127,
        rarity: "epic",
        contract: "",
        tkey: "orangeRoughy",
    },
    {
        name: "catfish",
        tokenId: 128,
        rarity: "epic",
        contract: "",
        tkey: "catfish",
    },
    {
        name: "smallShark",
        tokenId: 129,
        rarity: "legendary",
        contract: "",
        tkey: "smallShark",
    },
];

const fishes = [
    {
        name: "anchovy",
        tokenId: 121,
        rarity: "common",
        contract: "",
        tkey: "anchovy",
    },
    {
        name: "sardine",
        tokenId: 122,
        rarity: "common",
        contract: "",
        tkey: "sardine",
    },
    {
        name: "herring",
        tokenId: 123,
        rarity: "uncommon",
        contract: "",
        tkey: "herring",
    },
    {
        name: "smallTrout",
        tokenId: 124,
        rarity: "uncommon",
        contract: "",
        tkey: "smallTrout",
    },
    {
        name: "yellowPerch",
        tokenId: 125,
        rarity: "rare",
        contract: "",
        tkey: "yellowPerch",
    },
    {
        name: "salmon",
        tokenId: 126,
        rarity: "rare",
        contract: "",
        tkey: "salmon",
    },
    {
        name: "orangeRoughy",
        tokenId: 127,
        rarity: "epic",
        contract: "",
        tkey: "orangeRoughy",
    },
    {
        name: "catfish",
        tokenId: 128,
        rarity: "epic",
        contract: "",
        tkey: "catfish",
    },
    {
        name: "smallShark",
        tokenId: 129,
        rarity: "legendary",
        contract: "",
        tkey: "smallShark",
    },
];

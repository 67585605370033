import Panzoom from "@panzoom/panzoom";

const state = {
    panzoom: null,
    panzoomLocked: false,

    lockX: 0,
    lockY: 0,

    scaleLevel: 0.75,
};

const mutations = {
    initializePanzoom(state, panzoom) {
        state.panzoom = panzoom;
    },

    lockPanzoom(state, { x, y }) {
        state.panzoomLocked = true;
        state.lockX = x;
        state.lockY = y;
    },

    unlockPanzoom(state) {
        state.panzoomLocked = false;
    },

    setScaleLevel(state, scale) {
        state.scaleLevel = scale;
    },
};

const actions = {
    initializePanzoom({ commit, rootState, state }, panzoomContainer) {
        const panzoom = Panzoom(panzoomContainer, {
            maxScale: 2,
            minZoom: 2,
            setTransform: (elem, { scale, x, y }) => {
                if (state.panzoomLocked) {
                    panzoom.setStyle("transform", `rotate(0) scale(${scale}) translate(${state.lockX}px, ${state.lockY}px)`);
                    return;
                }

                panzoom.setStyle("transform", `rotate(0) scale(${scale}) translate(${x}px, ${y}px)`);
            },
        });

        panzoomContainer.parentElement.addEventListener("wheel", (e) => {
            commit("setScaleLevel", panzoom.getScale());
            panzoom.zoomWithWheel(e);
        });

        commit("setScaleLevel", panzoom.getScale());
        commit("initializePanzoom", panzoom);
    },

    zoomTo({ commit, rootState, state }, { x, y, scale, lock = false }) {
        state.panzoom.pan(x, y);
        state.panzoom.zoom(scale);

        /*if (lock) {
            commit("lockPanzoom", { x, y });
        }*/
    },

    resetZoom({ commit, rootState, state }, forceScale = 0) {
        state.panzoom.pan(0, 0);

        let scale = 2;

        if (window.innerWidth <= 768) {
            scale = 0.75;
            setTimeout(() => {
                state.panzoom.pan(-400, 70);
            });
        } else if (window.innerWidth <= 1024) {
            scale = 0.925;
        } else if (window.innerWidth <= 1440) {
            scale = 1.375;
        }

        state.panzoom.zoom(forceScale || scale);
    },

    lockPanzoom({ commit, rootState, state }) {
        commit("lockPanzoom");
    },

    unlockPanzoom({ commit, rootState, state }) {
        commit("unlockPanzoom");
    },

    destroyPanzoom({ commit, rootState, state }) {
        state.panzoom.destroy();
    },
};

const getters = {};

const panzoomModule = {
    state: () => state,
    mutations,
    getters,
    actions,
    namespaced: true,
};

export default panzoomModule;

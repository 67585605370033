const contracts = {
    // MUMBAI TESTNET

    168587773: {
        relayer: "0x1401439789259ac682637b1e87ea77df0599dc55",
        pfpOffset: 1,
        pfpSupply: 25,
    },
    81457: {
        relayer: "0x07b26b421c1297cd442dcb4e464fc4ecd977be60",
        pfpOffset: 0,
        pfpSupply: 1500,
    },
};

export default contracts;

import { ethers } from "ethers";
import addressManager from "@/libs/addressManager";

const state = () => ({
    pfpLensContract: null,
});

const mutations = {
    initializeContract(state, contract) {
        state.pfpLensContract = contract;
    },
};

const actions = {
    boot({ commit, rootState }, { contractAddress }) {
        const abi = require("@/plugins/artefacts/pfpLens.json");

        const { signer, defaultSigner } = rootState.site;
        const contract = new ethers.Contract(contractAddress, abi, signer || defaultSigner);

        commit("initializeContract", contract);
    },

    async getPfpIds({ state, rootState }) {
        const pfpContract = rootState.contracts.pfp.pfpContract;
        const { storedAccount, chainId } = rootState.user;
        const pfpAddress = pfpContract.target;
        const pfpOffset = addressManager[chainId].pfpOffset;
        const pfpSupply = addressManager[chainId].pfpSupply;
        /** @type {bigint[]} */
        const pfpIds = await state.pfpLensContract.tokenIdsOf(storedAccount, pfpAddress, pfpOffset, pfpSupply);
        return pfpIds.map((id) => Number(id));
    },
};

const getters = {};

export default {
    state,
    mutations,
    getters,
    actions,
    namespaced: true,
};

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{ref:"game",staticClass:"game-frame"},[_c('div',{staticClass:"game-frame-overworld"},[_c('img',{staticStyle:{"width":"100%","height":"100%"},attrs:{"src":require("../../../public/images/overworld.gif")}}),_c('MapBanner',{attrs:{"position":{
                x: 2165,
                y: 1050,
            },"size":'big',"text":_vm.$t('menu.farm')},on:{"clicked":function($event){return _vm.goToRoute('farm')}}}),_c('MapBanner',{attrs:{"position":{
                x: 1300,
                y: 1150,
            },"size":'big',"text":_vm.$t('menu.tavern')},on:{"clicked":function($event){return _vm.goToRoute('tavern')}}}),_c('MapBanner',{attrs:{"position":{
                x: 2480,
                y: 950,
            },"size":'big',"text":_vm.$t('menu.house')},on:{"clicked":function($event){return _vm.goToRoute('house')}}}),_c('MapBanner',{attrs:{"position":{
                x: 380,
                y: 460,
            },"size":'big',"text":_vm.$t('menu.hermit')},on:{"clicked":function($event){return _vm.goToRoute('hermit-cave')}}}),_c('MapBanner',{attrs:{"position":{
                x: 1650,
                y: 1370,
            },"text":_vm.$t('menu.marketplace'),"size":'big'},on:{"clicked":function($event){return _vm.goToRoute('marketplace')}}})],1),_c('div',{staticClass:"cloud-container"},_vm._l((_vm.clouds),function(cloud){return _c('div',{key:cloud.id,style:(_vm.getCloudShadowStyle(cloud.id))},[_c('img',{attrs:{"src":_vm.getCloudImage(cloud.id)}})])}),0),_c('div',{staticClass:"cloud-container",class:[_vm.scaleLevel < 0.75 ? 'fade-in' : 'fade-out']},_vm._l((_vm.clouds),function(cloud){return _c('div',{key:cloud.id,style:(_vm.getCloudStyle(cloud.id))},[_c('img',{attrs:{"src":_vm.getCloudImage(cloud.id)}})])}),0)])
}
var staticRenderFns = []

export { render, staticRenderFns }
import Vue from "vue";
import VueRouter from "vue-router";
import store from "@/store";

import OverworldScreen from "@/components/overworld/OverworldScreen";
import FarmScreen from "@/components/farm/FarmScreen";
import HouseScreen from "@/components/house/HouseScreen";
import MarketplaceScreen from "@/components/marketplace/MarketplaceScreen";
import TavernScreen from "@/components/tavern/TavernScreen";
import HermitCaveScreen from "@/components/hermitCave/HermitCaveScreen";
import AdminScreen from "@/components/admin/AdminScreen.vue";

import { countryLocales } from "@/libs/country";

Vue.use(VueRouter);

const routes = [
    {
        path: "/",
        name: "overworld",
        component: OverworldScreen,
    },
    {
        path: "/farm",
        name: "farm",
        component: FarmScreen,
    },
    {
        path: "/house",
        name: "house",
        component: HouseScreen,
    },
    {
        path: "/tavern",
        name: "tavern",
        component: TavernScreen,
    },
    {
        path: "/marketplace",
        name: "marketplace",
        component: MarketplaceScreen,
    },
    {
        path: "/hermit-cave",
        name: "hermit-cave",
        component: HermitCaveScreen,
    },
    {
        path: "/admin",
        name: "admin",
        component: AdminScreen,
    },
];

let currentLocale = "en"; //localStorage.getItem("site:locale") ?? "";

if (!countryLocales.hasOwnProperty(currentLocale) || currentLocale == "en") {
    currentLocale = "";
}

const validLocales = Object.keys(countryLocales);
const orLocales = validLocales.join("|");

//const baseURL = `${process.env.BASE_URL}${currentLocale ?? ""}`;
const baseURL = `${process.env.BASE_URL}`;

const router = new VueRouter({
    mode: "history",
    base: baseURL,
    routes,
});

router.beforeEach(async (to, from, next) => {
    store.dispatch("site/setLoading", true, { root: true });

    if (!store.state.site.initialized) {
        try {
            await store.dispatch("site/boot", null, { root: true });
        } catch (error) {}
        return next();
    }

    return next();
});

router.beforeResolve((to, from, next) => {
    setTimeout(() => {
        store.dispatch("site/setLoading", false, { root: true });
    }, 500);

    return next();
});

export default router;

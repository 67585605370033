<template>
    <div class="base-input">
        <input :max="max" :min="min" :step="step" v-model="modelValue" type="range" />
    </div>
</template>

<script>
export default {
    name: "BaseInput",

    components: {},

    props: {
        value: {
            type: Number,
            default: 0,
        },

        min: {
            type: Number,
            default: 0,
        },
        max: {
            type: Number,
            default: 100,
        },
        step: {
            type: Number,
            default: 1,
        },
    },

    data() {
        return {};
    },

    methods: {},

    computed: {
        modelValue: {
            get() {
                return Number(this.value);
            },
            set(newValue) {
                this.$emit("input", Number(newValue));
            },
        },
    },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.base-input {
    width: 100%;
    height: 100%;
    min-height: 4rem;
    max-height: 4rem;

    display: flex;
    justify-content: space-between;
    align-items: center;

    transition: all 0.1s ease-in;
    cursor: pointer;

    user-select: none;

    background-image: url("../../assets/gui/slider3.png");
    background-size:     contain;                      /* <------ */
    background-repeat:   no-repeat;
    background-position: center center;    

    input {
        width: 100%;
        height: 100%;
        min-height: 4rem;
        max-height: 4rem;

        border: none;
        outline: none;
        background: transparent;

        font-size: 1rem;
        font-weight: 600;
        color: #fff;

        padding: 0.5rem 0.75rem 0.75rem 0.75rem;

        &::placeholder {
            color: #fff;
            opacity: 0.5;
        }
    }
}

input[type="range"] {
    -webkit-appearance: none;
    appearance: none;
    background: transparent;
    cursor: pointer;

    margin-left: auto;
    margin-right: auto;

    padding: 0.5rem;
    margin-top: 0.35rem;
    margin-bottom: 0.4rem;
}

/******** Firefox ********/
input[type="range"]::-moz-range-track {
    height: 0.25rem;
    border-radius: 0.5rem;
}

input[type="range"]::-webkit-slider-thumb {
    -webkit-appearance: none; /* Override default look */
    appearance: none;
    height: 32px;
    width: 10px;

    background-image: url("../../assets/gui/sliderThumb.png");
    background-size:     contain;                      /* <------ */
    background-repeat:   no-repeat;   
}
</style>

<template>
    <div v-show="planterState === 'inactive' && initialized && !loading" class="right-menu">
        <div class="right-menu-buttons">
            <RightMenuItem @clicked="tryOpenSettings" :title="'Settings'" :name="'settings'"></RightMenuItem>
            <RightMenuItem @clicked="tryOpenInventory" :title="'Inventory'" :name="'inventory'"></RightMenuItem>
            <RightMenuItem @clicked="tryOpenTutorial" title:="'Tutorial'" :name="'tutorial'"></RightMenuItem>
        </div>
    </div>
</template>

<script>
import RightMenuItem from "@/components/sidebar/RightMenuItem.vue";
import { mapActions, mapGetters, mapState } from "vuex";

export default {
    name: "RightMenu",

    components: {
        RightMenuItem,
    },

    props: {},

    data() {
        return {};
    },

    methods: {
        ...mapActions("site", ["setCurrentModal"]),

        goToRoute(name) {
            if (this.$route.name === name) return;

            this.$router.push({ name });
        },

        async tryConnectMetamask() {
            await this.connectWallet({
                forceConnect: true,
            });
        },

        tryOpenInventory() {
            this.setCurrentModal("inventory");
        },

        tryOpenSettings() {
            this.setCurrentModal("settings");
        },

        tryOpenTutorial() {
            this.setCurrentModal("tutorial");
        },
    },

    computed: {
        ...mapState("site", ["initialized", "loading"]),
        ...mapGetters("user", ["isLoggedIn"]),
        ...mapState("planter", ["planterState"]),

        isOverworld() {
            return this.$route.name === "overworld";
        },
    },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.right-menu {
    z-index: 10000;

    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;

    transform-origin: 0 0;
    gap: 0.25rem;

    pointer-events: none;

    &-buttons {
        pointer-events: all;
        display: flex;
        flex-direction: row;
        gap: 1.5rem;
        align-items: center;
    }
}

.invis {
    opacity: 0;
}
</style>

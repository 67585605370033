import { ethers } from "ethers";
import { createNotification, getTxErrorMessage } from "@/libs/storeHelper";

const state = () => ({
    pfpControllerContract: null,
});

const mutations = {
    initializeContract(state, contract) {
        state.pfpControllerContract = contract;
    },
};

const actions = {
    boot({ commit, rootState }, { contractAddress }) {
        const abi = require("@/plugins/artefacts/pfpController.json");

        const { signer, defaultSigner } = rootState.site;
        const contract = new ethers.Contract(contractAddress, abi, signer || defaultSigner);

        commit("initializeContract", contract);
    },

    /**
     * @param {Object} context
     * @param {Object} payload
     * @param {number[]} payload.slots
     * @param {number[]} payload.tokenIds
     */
    async stake({ state, rootState, dispatch }, { slots, tokenIds }) {
        // console.log("stake", slots, tokenIds);
        try {
            const contract = state.pfpControllerContract;
            const { baseGwei } = rootState.site.settings;

            const tx = await contract.stake(slots, tokenIds, {
                //gasPrice: baseGwei,
            });

            createNotification({
                dispatch,
                message: `Tx: Staking PFP!`,
                type: "warn",
                id: tx.hash,
                showSpinner: true,
            });

            await tx.wait(1);

            dispatch("user/getProfile");
            dispatch("site/notifications/remove", tx.hash, { root: true });
        } catch (error) {
            dispatch("site/notifications/clearAll", {}, { root: true });
            getTxErrorMessage(dispatch, error);
        }
    },

    /**
     * @param {Object} context
     * @param {Object} payload
     * @param {number[]} payload.slots
     */
    async unstake({ state, rootState, dispatch }, { slots }) {
        try {
            const contract = state.pfpControllerContract;
            const { baseGwei } = rootState.site.settings;

            const tx = await contract.unstake(slots, {
                //gasPrice: baseGwei,
            });

            createNotification({
                dispatch,
                message: `Tx: Unstaking PFP!`,
                type: "warn",
                id: tx.hash,
                showSpinner: true,
            });

            await tx.wait(1);

            dispatch("user/getProfile");
            dispatch("site/notifications/remove", tx.hash, { root: true });
        } catch (error) {
            dispatch("site/notifications/clearAll", {}, { root: true });
            getTxErrorMessage(dispatch, error);
        }
    },

    /**
     * @param {Object} context
     * @param {Object} payload
     * @param {number} payload.slot
     */
    async switchProfilePicture({ state, rootState, dispatch }, { slot }) {
        try {
            const contract = state.pfpControllerContract;
            const { baseGwei } = rootState.site.settings;

            const tx = await contract.switchProfilePicture(slot, {});

            createNotification({
                dispatch,
                message: `Tx: Switching PFP!`,
                type: "warn",
                id: tx.hash,
                showSpinner: true,
            });

            await tx.wait(1);

            dispatch("user/getProfile");
            dispatch("site/notifications/remove", tx.hash, { root: true });
        } catch (error) {
            dispatch("site/notifications/clearAll", {}, { root: true });
            getTxErrorMessage(dispatch, error);
        }
    },

    async getPfpSlots({ state, rootState }) {
        const { storedAccount } = rootState.user;
        /** @type {[string, bigint, bigint]} */
        const slots = await state.pfpControllerContract.playerStakeData(storedAccount);
        const slots_ = [Number(slots[0]), Number(slots[1])];
        return slots_;
    },
};

const getters = {};

export default {
    state,
    mutations,
    getters,
    actions,
    namespaced: true,
};

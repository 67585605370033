import { ethers } from "ethers";

const state = () => ({
    yieldContract: null,
});

const mutations = {
    initializeContract(state, contract) {
        state.yieldContract = contract;
    },
};

const actions = {
    boot({ commit, rootState }, { contractAddress }) {
        const abi = require("@/plugins/artefacts/yield.json");

        const { signer, defaultSigner } = rootState.site;
        const contract = new ethers.Contract(contractAddress, abi, signer || defaultSigner);

        commit("initializeContract", contract);
    },

    async getYieldBalance({ state, rootState }) {
        const { storedAccount } = rootState.user;
        const balance = await state.yieldContract.balanceOf(storedAccount);
        return Number(balance) / 1e18;
    },
};

const getters = {};

export default {
    state,
    mutations,
    getters,
    actions,
    namespaced: true,
};

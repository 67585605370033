import Vue from "vue";

import { ethers } from "ethers";
import { getERC1155FromReceipt } from "@/libs/web3Helper";
import { RPCS, createNotification, getTxErrorMessage } from "@/libs/storeHelper";

import { getSeedVendorRevealData } from "@/api/seedVendor";

const state = () => ({
    seedVendorContract: null,
    seedVendorRevealContract: null,
    lastPackId: 0,
    txState: {
        pico: false,
        basic: false,
        premium: false,
    },

    adminRevealResult: [],

    discountEnabled: false,
    seedDiscounts: [0.12, 0.09, 0.06, 0.03],
});

const mutations = {
    initializeContract(state, seedVendorContract) {
        state.seedVendorContract = seedVendorContract;
    },

    initializeSeedRevealContract(state, seedVendorRevealContract) {
        state.seedVendorRevealContract = seedVendorRevealContract;
    },

    setTxState(state, { type, value }) {
        Vue.set(state.txState, type, value);
    },

    setLastPackId(state, packId) {
        state.lastPackId = packId;
    },

    setAdminReveal(state, result) {
        state.adminRevealResult = result;
    },

    setDiscountEnabled(state, value) {
        state.discountEnabled = value;
    },
};

const actions = {
    boot({ commit, dispatch, rootState }, { contractAddress }) {
        const { signer, defaultSigner } = rootState.site;

        const abi = require("@/plugins/artefacts/seedVendorCommit.json");
        const contract = new ethers.Contract(contractAddress, abi, signer || defaultSigner);

        commit("initializeContract", contract);
    },

    bootSeedReveal({ commit, dispatch, rootState }, { contractAddress }) {
        const { signer, defaultSigner } = rootState.site;

        const abi = require("@/plugins/artefacts/seedVendorReveal.json");
        const contract = new ethers.Contract(contractAddress, abi, signer || defaultSigner);

        commit("initializeSeedRevealContract", contract);
    },

    async getCommitData({ state, commit, rootState }) {
        const contract = state.seedVendorContract;
        const { storedAccount } = rootState.user;
        contract.getCommitData(storedAccount).then((data) => {
            commit("user/setVendorCommitData", data, { root: true });
        });

        contract.discountEnabled().then((data) => {
            commit("setDiscountEnabled", data);
        });
    },

    async commitSeed({ state, commit, dispatch, rootState }, { amount, type = "pico", useDiscount = false }) {
        const contract = state.seedVendorContract;
        const { baseGwei } = rootState.site.settings;
        const { tokenBalances, freeFeebleRolls, freePicoRolls, freeSeedRolls, freePremiumSeedRolls } = rootState.user;
        const { feebleSeedCost, picoSeedCost, seedCost, premiumSeedCost } = rootState.gacha;

        let cost = 0;
        let discountedDelta = 0;
        let seedType = 0;

        if (type === "feeble") {
            cost = Math.max(0, amount - freeFeebleRolls) * feebleSeedCost;
        } else if (type === "pico") {
            seedType = 1;
            cost = Math.max(0, amount - freePicoRolls) * picoSeedCost;
        } else if (type === "basic") {
            seedType = 2;
            cost = seedCost * Math.max(0, amount - freeSeedRolls);
        } else {
            seedType = 3;
            cost = premiumSeedCost * Math.max(0, amount - freePremiumSeedRolls);
        }

        if (state.discountEnabled && useDiscount) {
            discountedDelta = cost * state.seedDiscounts[seedType] * 20e18;
            cost = cost - cost * state.seedDiscounts[seedType];

            if (discountedDelta > tokenBalances.rawLockedYield) {
                createNotification({
                    dispatch,
                    type: "error",
                    message: "Insufficient Locked Tokens",
                    id: `insufficient-tokens`,
                    duration: 5000,
                });

                return;
            }
        }

        if (tokenBalances.rawYield < cost) {
            createNotification({
                dispatch,
                type: "error",
                message: "Insufficient Tokens",
                id: `insufficient-tokens`,
                duration: 5000,
            });

            return;
        }

        let tx;

        if (type === "feeble") {
            tx = await contract.commitBuyFeebleSeed(amount, useDiscount, {
                //gasPrice: baseGwei,
            });
        } else if (type === "pico") {
            tx = await contract.commitBuyPicoSeed(amount, useDiscount, {
                //gasPrice: baseGwei,
            });
        }
        if (type === "basic") {
            tx = await contract.commitBuySeed(amount, useDiscount, {
                //gasPrice: baseGwei,
            });
        } else if (type === "premium") {
            tx = await contract.commitBuyPremiumSeeds(amount, useDiscount, {
                //gasPrice: baseGwei,
            });
        }

        commit("setTxState", { type, value: true });

        dispatch("site/resetCurrentModal", {}, { root: true });
        setTimeout(() => {
            dispatch("site/setCurrentModal", "seedShop", { root: true });
        });

        createNotification({
            dispatch,
            type: "warn",
            message: `Tx Pending: Buying ${amount} Seeds`,
            id: `${tx.hash}`,
            showSpinner: true,
        });
        await tx.wait(2);
        await dispatch("getCommitData");

        setTimeout(async () => {
            createNotification({
                dispatch,
                type: "warn",
                message: `Tx Success: Reveal the seeds!`,
                id: `${tx.hash}`,
            });

            commit("setTxState", { type, value: false });
            dispatch("site/notifications/remove", tx.hash, { root: true });
        }, 1000);
    },

    async revealSeed({ state, dispatch, rootState, commit }, { type = "basic" }) {
        const { defaultSigner: provider } = rootState.site;
        const { storedAccount } = rootState.user;

        const seedVendorContract = state.seedVendorContract;
        const seedVendorRevealContract = state.seedVendorRevealContract;
        const { baseGwei } = rootState.site.settings;
        const { cropsContract } = rootState.contracts.crops;

        const blockNumber = await provider.getBlockNumber();

        let commitData;
        let seedType;
        let tx;
        let diverger = 1.2 + Math.random() * 0.2;

        if (type === "feeble") {
            seedType = 0;
            commitData = rootState.user.feebleCommitData;
        } else if (type === "pico") {
            seedType = 1;
            commitData = rootState.user.picoCommitData;
        } else if (type === "basic") {
            seedType = 2;
            commitData = rootState.user.basicCommitData;
        } else if (type === "premium") {
            seedType = 3;
            commitData = rootState.user.premiumCommitData;
        }

        try {
            if (commitData.commitBlock + 250 > blockNumber) {
                if (type === "feeble") {
                    const gasEstimation = await seedVendorContract.revealFeebleSeeds.estimateGas();
                    tx = await seedVendorContract.revealFeebleSeeds({
                        gasLimit: parseInt(Number(gasEstimation) * diverger),
                        //gasPrice: baseGwei,
                    });
                } else if (type === "pico") {
                    const gasEstimation = await seedVendorContract.revealPicoSeeds.estimateGas();
                    tx = await seedVendorContract.revealPicoSeeds({
                        gasLimit: parseInt(Number(gasEstimation) * diverger),
                        //gasPrice: baseGwei,
                    });
                } else if (type === "basic") {
                    const gasEstimation = await seedVendorContract.revealBasicSeeds.estimateGas();
                    tx = await seedVendorContract.revealBasicSeeds({
                        gasLimit: parseInt(Number(gasEstimation) * diverger),
                        //gasPrice: baseGwei,
                    });
                } else if (type === "premium") {
                    const gasEstimation = await seedVendorContract.revealPremiumSeeds.estimateGas();
                    tx = await seedVendorContract.revealPremiumSeeds({
                        gasLimit: parseInt(Number(gasEstimation) * diverger),
                        //gasPrice: baseGwei,
                    });
                }
            } else {
                const { blockHash, signature } = await getSeedVendorRevealData({
                    user: storedAccount,
                    seedType,
                    blockNumber: commitData.commitBlock,
                });

                const gasEstimation = await seedVendorRevealContract.revealSeedWithBlockhash.estimateGas(
                    seedType,
                    commitData.commitBlock,
                    blockHash,
                    signature,
                );

                tx = await seedVendorRevealContract.revealSeedWithBlockhash(seedType, commitData.commitBlock, blockHash, signature, {
                    gasLimit: parseInt(Number(gasEstimation) * diverger),
                });
            }

            const amount = commitData.amount;

            commit("setLastPackId", amount);
            commit("gacha/setGachaLoading", true, { root: true });
            commit("gacha/setGachaType", type, { root: true });
            commit("gacha/setGachaLastSize", amount, { root: true });

            dispatch("site/setCurrentModal", "seedShop", { root: true });

            // reset modal
            dispatch("site/setCurrentModal", "gachaResult", { root: true });

            createNotification({
                dispatch,
                type: "warn",
                message: `Tx Pending: Buying ${amount} Seeds`,
                id: `${tx.hash}`,
            });

            dispatch("site/setShowSpinner", { showSpinner: false, message: "", type: "txWait" }, { root: true });

            const receipt = await tx.wait();

            const seedsFromReceipt = getERC1155FromReceipt(receipt, cropsContract);

            commit("gacha/setGachaLoading", false, { root: true });
            const gachaResult = [];

            seedsFromReceipt.ids.forEach((id, index) => {
                for (let i = 0; i < seedsFromReceipt.values[index]; i++) {
                    gachaResult.push(id);
                }
            });

            dispatch("gacha/drawGacha", { result: gachaResult, size: amount }, { root: true });
            dispatch("getCommitData");
            dispatch("user/getBalances", {}, { root: true });
        } catch (error) {
            dispatch("site/setCurrentModal", "gachaResult", { root: true });
            dispatch("site/setCurrentModal", "seedShop", { root: true });
            dispatch("gacha/clearGacha", {}, { root: true });

            dispatch("site/notifications/clearAll", {}, { root: true });
            getTxErrorMessage(dispatch, error);
        }

        dispatch("site/notifications/remove", tx.hash, { root: true });
    },

    async adminRevealForUser({ state, dispatch, rootState, commit }, { txHash, seedType = 0 }) {
        const contract = state.seedVendorContract;
        const baseGwei = 0.1e9;
        const { cropsContract } = rootState.contracts.crops;

        const provider = new ethers.JsonRpcProvider(RPCS.mainnet);
        const transactionDetails = await provider.getTransaction(txHash);
        const { from, blockNumber } = transactionDetails;
        const blockDetails = await provider.getBlock(blockNumber);
        const { hash } = blockDetails;

        let tx;

        try {
            const gasEstimation = await contract.revealSeedsForUser.estimateGas(seedType, from, hash);
            tx = await contract.revealSeedsForUser(seedType, from, hash, {
                gasLimit: parseInt(Number(gasEstimation)),
            });

            createNotification({
                dispatch,
                type: "warn",
                message: `Tx Pending: Revealing Seeds`,
                id: `${tx.hash}`,
            });

            const receipt = await tx.wait();

            const seedsFromReceipt = getERC1155FromReceipt(receipt, cropsContract);
            const gachaResult = [];

            seedsFromReceipt.ids.forEach((id, index) => {
                for (let i = 0; i < seedsFromReceipt.values[index]; i++) {
                    gachaResult.push(id);
                }
            });

            const seedIds = seedsFromReceipt.ids.map((c) => c);
            const seedAmounts = seedsFromReceipt.values.map((c) => c);

            const { cropsDataRaw } = rootState.content;
            const seeds = seedIds.map((id, index) => cropsDataRaw.find((c) => c.tokenId === id).name + " x " + seedAmounts[index]);

            commit("setAdminReveal", seeds);
            dispatch("site/notifications/remove", tx.hash, { root: true });
        } catch (error) {
            // dispatch("site/notifications/clearAll", {}, { root: true });
            console.log(error);
            getTxErrorMessage(dispatch, error);
        }
    },
};

const getters = {};

export default {
    state,
    mutations,
    getters,
    actions,
    namespaced: true,
};

import { getContentCache } from "@/api/content";
import { set } from "lodash";

const recipes = require("@/plugins/recipes.json");

const state = {
    cropTypes: [],
    cropsData: undefined,
    cropsDataRaw: [],

    itemTypes: [],
    itemsData: [],
    itemsDataRaw: [],

    fishTypes: [],
    fishesData: [],
    fishesDataRaw: [],

    legacySkills: [],

    recipes: [],

    allItemsData: [],

    boostsData: [],
};

const mutations = {
    initializeCrops(state, names) {
        state.cropTypes = names;
    },

    initializeCropsData(state, cropsData) {
        state.cropsData = cropsData;
    },

    initializeCropsDataRaw(state, cropsDataRaw) {
        state.cropsDataRaw = cropsDataRaw;
    },

    setItemsData(state, itemsData) {
        state.itemsData = itemsData;
    },

    setAllItemsData(state, allItemsData) {
        state.allItemsData = allItemsData;
    },

    setRecipesData(state, recipesData) {
        state.recipes = recipesData;
    },

    initializeItems(state, names) {
        state.itemTypes = names;
    },

    initializeItemsDataRaw(state, itemsDataRaw) {
        state.itemsDataRaw = itemsDataRaw;
    },

    initializeBoostsData(state, boostsData) {
        state.boostsData = boostsData;
    },

    initializeLegacySkills(state, legacySkills) {
        state.legacySkills = legacySkills;
    },

    initializeFishes(state, names) {
        state.fishTypes = names;
    },

    initializeFishesDataRaw(state, fishesDataRaw) {
        state.fishesDataRaw = fishesDataRaw;
    },
    setFishesData(state, fishesData) {
        state.fishesData = fishesData;
    }
};

const actions = {
    async boot({ dispatch }) {
        await dispatch("initializeContent", {});
    },

    async initializeContent({ commit, rootState, state }) {
        const content = await getContentCache();

        const cropsRawData = content.crops || [];
        const itemsRawData = content.items || [];
        const fishesRawData = content.fishes || [];

        const crops = cropsRawData.map((c) => c.name);
        const cropsData = {};

        const fishes = fishesRawData.map((f) => f.name);
        const fishesData = {};

        const items = itemsRawData.map((i) => i.name);
        const itemsData = {};

        const allItems = [];

        cropsRawData.forEach((c) => {
            const cropData = {
                type: 'crop',
                tokenId: c.tokenId,
                imageIndex: c.imageIndex,
                rarity: c.rarity,
                life: c.life,
                produceId: c.produceId,
                reward: c.reward,
                name: c.name,
                description: c.description,
                seedDescription: c.seedDescription,
            };

            cropsData[c.name] = cropData;
            allItems.push(cropData);
        });

        itemsRawData.forEach((i) => {
            const itemData = {
                type: 'item',
                tokenId: i.tokenId,
                name: i.name,
                description: i.description,
                effect: i.effect,
                rarity: i.rarity,
                contract: i.contract,
                uses: i.uses,
                value: i.value,
                tKey: i.tKey,
            };

            itemsData[i.name] = itemData;
            allItems.push(itemData);
        });

        fishesRawData.forEach((f) => {
            const fishData = {
                type: 'fish',
                tokenId: f.tokenId,
                name: f.name,
                description: f.description,
                rarity: f.rarity,
                tKey: f.tKey,
            }

            fishesData[f.name] = fishData;
            allItems.push(fishData);
        });

        const boostsData = require("../../plugins/boosts.json");
        const recipes = require("../../plugins/recipes.json");
        const legacySkills = require("../../plugins/legacySkills.json");

        recipes.forEach((recipe) => {
            recipe.rawRecipeIngredients = [];
            for (let i = 0; i < recipe.recipeTokenIds.length; i++) {
                const ingredientTokenId = recipe.recipeTokenIds[i];
                if (Array.isArray(ingredientTokenId)) {
                    const ingredients = allItems.filter((x) => ingredientTokenId.includes(x.tokenId));
                    if (ingredients) recipe.rawRecipeIngredients.push(ingredients);
                } else {
                    const ingredient = allItems.find((x) => x.tokenId === ingredientTokenId);

                    if (ingredient) {
                        recipe.rawRecipeIngredients.push(ingredient);
                    }
                }
            }
        });

        commit("initializeCrops", crops);
        commit("initializeCropsData", cropsData);
        commit("initializeCropsDataRaw", cropsRawData);

        commit("setRecipesData", recipes);

        commit("initializeItems", items);
        commit("setItemsData", itemsData);
        commit("initializeItemsDataRaw", itemsRawData);

        commit("initializeFishes", fishes);
        commit("setFishesData", fishesData);
        commit("initializeFishesDataRaw", fishesRawData);

        commit("setAllItemsData", allItems);

        commit("initializeBoostsData", boostsData);
        commit("initializeLegacySkills", legacySkills);
    },

    getContractContent({ commit, rootState, state }, payload) {
        const farmContract = rootState.contracts.farm.farmContract;
    },
};

const getters = {};

const contractModule = {
    state: () => state,
    mutations,
    getters,
    actions,
    namespaced: true,
};

export default contractModule;

<template>
    <div class="custom-loader">
        <img :src="loaderImage" alt="Loading..." />
        <p class="small-text">{{ message }}</p>
    </div>
</template>
<script>
export default {
    name: "CustomLoader",
    props: {
        message: {
            type: String,
            default: "Waiting for transaction...",
        },
        spinnerType: {
            type: String,
            default: "txConfirm",
        },
        spinnerAnimation: {
            type: Number,
            default: 0,
        },
    },

    computed: {
        loaderImage() {
            console.log("SPINNER ANIMATION: ", this.spinnerAnimation);
            return require(`@/assets/images/loader/${this.spinnerType}_${this.spinnerAnimation}.gif`);
        },
    },
};
</script>
<style>
.custom-loader {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    width: 25vw;
    height: 12rem;

    padding-bottom: 2rem;
}
</style>

import Vue from "vue";
import VueI18n from "vue-i18n";

import en from "@/lang/main.json";

Vue.use(VueI18n);

export const fallbackLocale = "en";
export const selectedLocale = localStorage.getItem("site:locale") || fallbackLocale;

// NOTE that when you want to add a new language to the `messages` below,
// you will also have to add it to the `loadModule` maps in libs/dayjs
// The only locale imported here is en.js since that is the fallback locale and will always be needed

const i18n = new VueI18n({
    locale: fallbackLocale,
    fallbackLocale,
    silentFallbackWarn: true,
    messages: {
        en,
    },
});

export default i18n;

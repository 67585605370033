<template>
    <div class="base-tooltip">
        <div :style="getStyle" class="tooltip bounce-enter-active">
            <div class="tooltip-header"></div>

            <div class="content">
                <div class="content-info">
                    <CropDisplay :displayType="'crop'" :plantType="name"></CropDisplay>
                    <div class="content-info-name">
                        <p class="big-text">{{ $t(`crops.${name}`) }}</p>
                        <p class="small-text" :class="`crops--${cropData.rarity}`">
                            {{ $t(`misc.rarity.${cropData.rarity}`) }}
                            {{ tier }}
                        </p>
                    </div>
                </div>

                <div class="divider"></div>

                <div class="content-stage">
                    <div class="content-row">
                        <p class="small-text start">
                            {{ $t("modals.infoTooltip.growthStage") }}
                        </p>
                        <p class="tiny-text end">
                            <span class="highlight">{{ $t(`misc.growthStage.${stageName[stage]}`) }}</span>
                        </p>
                    </div>
                    <GrowthStage :stage="stage"></GrowthStage>
                </div>

                <div class="divider"></div>

                <br />
                <div class="content-row">
                    <p class="small-text start">{{ $t("modals.infoTooltip.timeLeft") }}:</p>
                    <p :key="timeLeft" class="small-text end">
                        <BaseTimer :timeLeft="timeLeft"></BaseTimer>
                    </p>
                </div>

                <br />
                <div class="content-row">
                    <p class="small-text start">
                        {{ $t("modals.infoTooltip.totalHarvest") }}
                    </p>
                    <p class="small-text end">
                        {{ ((reward + reward * lockedRatio) * (1 + harvestBonus)).toFixed(2) }}
                        $Yield
                    </p>
                </div>

                <div class="content-row" style="margin-bottom: 0.25rem">
                    <p class="tiny-text start">
                        <span class="highlight-red">{{ $t("misc.locked") }}</span>
                    </p>

                    <p class="tiny-text end">
                        <span class="highlight-red">
                            {{ (lockedRatio * reward * (1 + harvestBonus)).toFixed(2) }}
                            $Yield</span>
                    </p>
                </div>

                <div class="content-row">
                    <p class="tiny-text start">
                        <span class="highlight">{{ $t("misc.unlocked") }}</span>
                    </p>
                    <p class="tiny-text end">
                        <span class="highlight">
                            {{ (reward * (1 + harvestBonus)).toFixed(2) }}
                            $Yield</span>
                    </p>
                </div>

                <br />
                <div class="divider"></div>

                <br />

                <div v-if="activeEffect" class="content-row">
                    <p class="small-text start">
                        {{ $t("modals.infoTooltip.activeEffect") }}
                    </p>
                    <p class="small-text end">{{ activeEffect.description }}</p>
                </div>

                <div v-else class="content-row">
                    <p class="small-text start">
                        {{ $t("modals.infoTooltip.noActiveEffect") }}
                    </p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState } from "vuex";

import CropDisplay from "@/components/crops/CropDisplay.vue";
import GrowthStage from "@/components/crops/GrowthStage.vue";

export default {
    name: "PlantInfoTooltip",

    components: { CropDisplay, GrowthStage },
    props: {
        width: {
            type: Number,
            default: 400,
        },
        height: {
            type: Number,
            default: 400,
        },
    },

    data() {
        return {
            showWrapper: false,

            stageName: ["sprout", "vegetative", "budding", "flowering", "harvest"],
        };
    },

    computed: {
        ...mapState("site/settings", ["mousePosition"]),
        ...mapState("planter", ["hoverPlant"]),
        ...mapState("user", ["farmLevel", "lockedRatio", "profileHarvestBonus"]),
        ...mapState("content", ["boostsData"]),

        getStyle() {
            return {
                width: `${this.width}px`,
                height: `${this.height}px`,
                top: `${this.mousePosition.y}px`,
                left: `${this.mousePosition.x + 32}px`,
            };
        },

        cropData() {
            return this.hoverPlant.cropData;
        },

        stage() {
            return Math.max(0, this.hoverPlant.stage);
        },

        name() {
            return this.cropData.name;
        },

        tier() {
            if (this.cropData.tokenId <= 12) {
                return "Basic";
            }
            if (this.cropData.tokenId > 12 && this.cropData.tokenId <= 23) {
                return "Prem.";
            }
            if (this.cropData.tokenId > 23 && this.cropData.tokenId <= 28) {
                return "Pico";
            }
            return "Feeble";
        },

        reward() {
            return this.cropData.reward;
        },

        harvestBonus() {
            const profileBonus = this.profileHarvestBonus / 1e18;
            let [hb, pb] = [0, 0];
            if (this.activeEffect && this.activeEffect.effects) {
                hb = this.activeEffect.effects.hb || 0;
            }
            return Math.min(0.0375, this.farmLevel * 0.0025) + hb + profileBonus;
        },

        activeEffect() {
            const { boostModifier } = this.hoverPlant;

            const boostData = this.boostsData.find((c) => c.id == boostModifier);

            if (!boostData) return;

            return boostData;
        },

        timeLeft() {
            const { timestamp, stage } = this.hoverPlant;
            const life = (this.cropData?.life || 0) * 1000;

            return timestamp + life - Date.now();
        },
    },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@use "@/assets/styles/main.scss";

.base-tooltip {
    position: fixed;
    width: 100vw;
    height: 100vh;

    z-index: 10000;
}

.tooltip {
    &-row {
        width: 100%;
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        gap: 1rem;
    }

    position: fixed;
    z-index: 10000;

    border-image: url("../../../public/images/modalBg.png") 6 fill repeat;
    /*   border-image: url(http://www.css3.info/wp-content/uploads/2007/09/border.png) 25 25 25 25 fill round; */
    border-image-width: 1rem;

    box-shadow: 4px 4px 32px #00000018;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    &-header {
        min-height: 3rem;
        width: 12rem;
        max-width: 12rem;
        height: 3rem;
        z-index: 10001;

        position: absolute;
        top: -1.05rem;
        left: calc(50% - 6rem);

        display: flex;
        justify-content: center;
        margin-bottom: 1rem;
    }
}

.content {
    margin-top: 1rem;

    height: calc(90% - 4rem);
    width: 90%;

    &-row {
        display: flex;
        width: 100%;
        justify-content: space-evenly;
        align-items: center;
        text-align: start !important;

        * {
            &.start {
                width: 50% !important;
                text-align: start;
            }

            &.end {
                width: 50% !important;
                text-align: end;
            }
        }
    }

    &-info {
        display: flex;
        gap: 1rem;

        &-name {
            margin-top: 0.5rem;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
        }
    }

    &-stage {
        width: 100%;
        margin-top: 0.5rem;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 0.25rem;
    }
}

.bounce-enter-active {
    animation: bounce-in 0.5s;
}

.bounce-leave-active {
    animation: bounce-in 0.5s reverse;
}

@keyframes bounce-in {
    0% {
        transform: scale(0);
        opacity: 0.75;
    }

    50% {
        transform: scale(1.05);
    }

    100% {
        transform: scale(1);
        opacity: 1;
    }
}
</style>

<template>
    <div class="game-frame" ref="game">
        <div class="game-frame-house">
            <img style="width: 100%; height: 100%" src="../../../public/images/house_new.png" />
            <MapBanner @clicked="openFishing()" :text="$t('menu.angler')" :position="{ x: 130, y: 295 }"></MapBanner>

            <Character v-if="false"
                @clicked="setCurrentModal('angler')"
                :text="$t('menu.angler')"
                :character="'angler'"
                :bannerSize="'small'"
                :position="{ x: 418, y: 155 }"
            ></Character>

            <MapBanner @clicked="setCurrentModal('referrals')" :text="$t('menu.referrals')" :position="{ x: 712, y: 210 }"></MapBanner>

            <MapBanner @clicked="openBlastGold()" :text="'gold'" :position="{ x: 192, y: 140 }"></MapBanner>

            <Character
                @clicked="setCurrentModal('dailyChest')"
                :flat="true"
                :size="3"
                :hasBanner="true"
                :character="`${chestType}`"
                :position="{ x: 540, y: 225 }"
            ></Character>
            <MapBanner @clicked="openGardener()" :text="'gardener'" :position="{ x: 748, y: 60 }"></MapBanner>

            <Character v-if="isTestnet" @clicked="setCurrentModal('faucet')" :character="'faucet'" :position="{ x: 340, y: 180 }"></Character>
        </div>
    </div>
</template>

<script>
import { mapActions, mapState } from "vuex";

export default {
    name: "HouseScreen",

    components: {},

    props: {},

    data() {
        return {};
    },

    mounted() {
        const panzoomContainer = this.$refs.game;
        this.initializePanzoom(panzoomContainer);
        this.resetZoom();
    },

    beforeDestroy() {
        this.destroyPanzoom();
        this.resetZoom();
    },

    methods: {
        ...mapActions("site", ["setCurrentModal"]),
        ...mapActions("panzoom", ["initializePanzoom", "destroyPanzoom", "zoomTo", "resetZoom"]),
        ...mapActions("site/npcDialogue", ["setSelectedNPC"]),

        openFishing() {
            this.setCurrentModal("fishing");
            this.setSelectedNPC({ selectedNPC: "angler", show: true });
        },

        openBlastGold() {
            this.setCurrentModal("blastGold");
            this.setSelectedNPC({ selectedNPC: "gold", show: true });
        },

        openGardener() {
            this.setCurrentModal("gardener");
            this.setSelectedNPC({ selectedNPC: "gardener", show: true });
        },
    },

    computed: {
        ...mapState("user", ["chainId", "farmLevel"]),

        isTestnet() {
            return false; // this.chainId == "168587773";
        },

        chestType() {
            if (this.farmLevel < 5) {
                return "wooden_chest";
            } else if (this.farmLevel < 10) {
                return "bronze_chest";
            } else if (this.farmLevel < 15) {
                return "silver_chest";
            } else {
                return "golden_chest";
            }
        },
    },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.game-frame {
    width: 100%;
    height: 100%;
    border-radius: 0.25rem;

    &-house {
        width: 960px;
        height: 480px;
        position: absolute;
        border-image: url("../../../public/images/itemBg.png") 6 fill repeat;
        border-image-width: 1rem;
        border-image-outset: 0.5rem;

        position: absolute;
        top: calc(50% - 240px);
        left: calc(50% - 480px);
    }
}
</style>

<template>
    <div class="base-input">
        <select v-model="item">
            <option value="">{{ placeholder }}</option>
            <option v-for="option in options" :key="option.value" :value="option.value">
                {{ option.text }}
            </option>
        </select>
    </div>
</template>

<script>
export default {
    name: "BaseDropdown",

    components: {},

    props: {
        options: {
            type: Array,
            default: () => [],
        },
        placeholder: {
            type: String,
            default: ""
        }
    },

    data() {
        return {
            keyUpTimeout: null,
            value: "",
        };
    },

    computed: {
        item: {
            get() {
                return this.value;
            },
            set(newValue) {
                this.$emit("input", newValue);
            },
        },
    },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.base-input {
    width: 100%;
    max-height: 2.75rem;
    min-height: 2rem;

    display: flex;
    justify-content: space-between;
    align-items: center;

    transition: all 0.1s ease-in;
    cursor: pointer;

    user-select: none;

    border-image: url("../../assets/gui/inputBg.png") 6 fill repeat;
    border-image-width: 0.75rem;

    select {
        width: 100%;
        height: 3rem;
        min-height: 2rem;

        border: none;
        outline: none;
        background: transparent;

        font-size: 1rem;
        font-weight: 600;
        color: #fff;

        padding: 0rem 0.75rem;

        &::placeholder {
            color: #fff;
            opacity: 0.5;
        }

        option {
            color: #000;
        }

        &:hover {
            background: rgba(255, 255, 255, 0.1);
        }
    }
}
</style>

import Vue from "vue";
import App from "./App.vue";
//require("@/libs/walletConnect");

import router from "@/router";
import store from "@/store";

Vue.use(store);
Vue.use(router);

import i18n from "@/lang/i18n";
import I18nHelpersPlugin from "./plugins/i18n-helpers";

import BaseModal from "@/components/modal/BaseModal.vue";
import BaseButton from "@/components/ui/BaseButton.vue";
import SmolButton from "@/components/ui/SmolButton.vue";
import BaseInput from "@/components/ui/BaseInput.vue";
import BaseFormInput from "@/components/ui/BaseFormInput.vue";
import BaseDropdown from "@/components/ui/BaseDropdown.vue";
import BaseNumericInput from "@/components/ui/BaseNumericInput.vue";
import BaseTimer from "@/components/ui/BaseTimer.vue";
import BaseCheckbox from "@/components/ui/BaseCheckbox.vue";
import SecondaryBox from "@/components/ui/SecondaryBox.vue";
import BalanceManager from "@/components/ui/BalanceManager.vue";
import Header from "@/components/ui/Header.vue";
import Label from "@/components/ui/Label.vue";

import MapBanner from "@/components/ui/MapBanner.vue";
import Character from "@/components/ui/Character.vue";

import "@/assets/styles/_text.scss";

Vue.use(I18nHelpersPlugin);

Vue.component("BaseModal", BaseModal);
Vue.component("BaseButton", BaseButton);
Vue.component("SmolButton", SmolButton);
Vue.component("BaseInput", BaseInput);
Vue.component("BaseFormInput", BaseFormInput);
Vue.component("BaseNumericInput", BaseNumericInput);
Vue.component("BaseDropdown", BaseDropdown);
Vue.component("SecondaryBox", SecondaryBox);
Vue.component("BalanceManager", BalanceManager);
Vue.component("Header", Header);
Vue.component("Label", Label);
Vue.component("BaseCheckbox", BaseCheckbox);
Vue.component("BaseTimer", BaseTimer);

Vue.component("MapBanner", MapBanner);
Vue.component("Character", Character);

Vue.config.productionTip = false;

new Vue({
    el: "#app",
    components: { App },
    router,
    store,
    i18n,
    render: (h) => h(App),
    template: "<App/>",
}).$mount("#app");

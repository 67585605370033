import { formatNumber } from "@/libs/kiloNumbers";
import { ZERO_ADDRESS, CHAIN_IDS, isDevelopmentMode } from "@/libs/storeHelper";
import Vue from "vue";

const state = {
    storedAccount: localStorage.getItem("cv:stored_account") || ZERO_ADDRESS,
    account: localStorage.getItem("cv:stored_account") || ZERO_ADDRESS,
    chainId: 0,

    name: "",
    exp: 0,
    farmLevel: 0,
    pendingRewards: 0,
    profileHarvestBonus: 0,
    lockedRatio: 0.1,

    referral: "",
    referralCode: "",
    profilePictureId: 0,

    claimedSage: false,
    claimedWage: false,

    farmPlots: new Array(30).fill({}),

    tokenBalances: {
        lockedYield: 0,
        yield: 0,
        rawYield: 0,
        rawLockedYield: 0,
        xyield: 0,
    },

    balances: {
        seeds: {},
        crops: {},
        items: {},
        fishes: {},
        all: {},
    },

    /** @type {number[]} */
    pfpTokenIds: [],
    slot1: 0,
    slot2: 0,

    /// Seed Buy

    freeFeebleRolls: 0,
    freePicoRolls: 0,
    freeSeedRolls: 0,
    freePremiumSeedRolls: 0,

    picoCommitData: { commitBlock: 0 },
    basicCommitData: { commitBlock: 0 },
    premiumCommitData: { commitBlock: 0 },
    feebleCommitData: { commitBlock: 0 },

    lastItemsReceived: {},

    blastGold: 0,
};

const mutations = {
    /**
     * @param {typeof state} state
     * @param {string} account
     */
    setAccount(state, account) {
        state.account = account;

        if (state.storedAccount !== account) {
            state.storedAccount = account;
            localStorage.setItem("cv:stored_account", account);
        }
    },

    /**
     * @param {typeof state} state
     * @param {number} chainId
     */
    setChainId(state, chainId) {
        state.chainId = chainId;
    },

    /**
     * @param {typeof state} state
     * @param {number} pendingRewards
     */
    setPendingRewards(state, pendingRewards) {
        state.pendingRewards = pendingRewards.toFixed(1);
    },

    /**
     * @param {typeof state} state
     * @param {Object} obj
     * @param {string} obj.token
     * @param {number} obj.value
     */
    setBalance(state, { token, value }) {
        Vue.set(state.tokenBalances, token, value);
    },

    /**
     * @param {typeof state} state
     * @param {Object<string, number>} balances
     */
    setSeedBalances(state, balances) {
        state.balances.seeds = balances;
    },

    /**
     * @param {typeof state} state
     * @param {Object<string, number>} balances
     */
    setCropsBalances(state, balances) {
        state.balances.crops = balances;
    },

    /**
     * @param {typeof state} state
     * @param {Object<string, number>} balances
     */
    setItemsBalances(state, balances) {
        state.balances.items = balances;
    },

    /**
     * @param {typeof state} state
     * @param {Object<string, number>} balances
     */
    setFishesBalances(state, balances) {
        state.balances.fishes = balances;
    },

    setAllBalances(state, balances) {
        state.balances.all = balances;
    },

    /**
     * @param {typeof state} state
     * @param {number} lockedRatio
     */
    setLockedRatio(state, lockedRatio) {
        state.lockedRatio = lockedRatio;
    },

    /**
     * @param {typeof state} state
     * @param {Object} data
     */
    setVendorCommitData(state, data) {
        const { feeble, pico, basic, premium } = data;

        const parseCommit = (c) => {
            return {
                commitBlock: Number(c.commitBlock),
                amount: Number(c.amount),
            };
        };

        state.feebleCommitData = parseCommit(feeble);
        state.picoCommitData = parseCommit(pico);
        state.basicCommitData = parseCommit(basic);
        state.premiumCommitData = parseCommit(premium);
    },

    /**
     * @param {typeof state} state
     * @param {import("../contracts/dataAggregator").PlayerInfoDict} data
     */
    setUserProfile(state, data) {
        state.farmLevel = Number(data.farmLevel);
        state.exp = Number(data.exp);
        state.profileHarvestBonus = Number(data.profileHarvestBonus);
        state.referral = data.referral;
        state.referralCode = data.referralCode;
        state.name = data.playerName;

        state.freeFeebleRolls = Number(data.freeFeebleRolls);
        state.freePicoRolls = Number(data.freePicoRolls);
        state.freeSeedRolls = Number(data.freeSeedRolls);
        state.freePremiumSeedRolls = Number(data.freePremiumSeedRolls);

        state.profilePictureId = Number(data.profilePictureId);
        state.claimedSage = data.claimedSage;
        state.claimedWage = data.claimedWage;

        Vue.set(state.tokenBalances, "rawLockedYield", Number(data.lockedBalance));
        Vue.set(state.tokenBalances, "lockedYield", formatNumber(Number(data.lockedBalance) / 1e18));
    },

    /**
     * @param {typeof state} state
     * @param {number[]} tokenIds
     */
    setPfpTokenIds(state, tokenIds) {
        state.pfpTokenIds = tokenIds;
    },

    /**
     * @param {typeof state} state
     * @param {[number, number]} slots
     */
    setPfpSlots(state, slots) {
        state.slot1 = slots[0];
        state.slot2 = slots[1];
    },

    /**
     * @param {typeof state} state
     * @param {Object[]} farmPlots
     */
    setFarmPlots(state, farmPlots) {
        state.farmPlots = farmPlots;
    },

    /**
     * @param {typeof state} state
     * @param {Object[]} missionsInfo
     */
    setMissionInfo(state, missionsInfo) {
        state.farmMission = missionsInfo[0];
        state.cropsMission = missionsInfo[1];
        state.packsMission = missionsInfo[2];
        state.gnomeMission = missionsInfo[3];
    },

    /**
     * @param {typeof state} state
     */
    setAirdropData(state) {
        const user = state.storedAccount.toLowerCase();
        /** @type {import("@/plugins/airdrop").Airdrop[]} */
        const blastDistribution = require("@/plugins/blastDistribution_4.json");

        const airdropData = blastDistribution.find((c) => c.address.toLowerCase() === user);

        state.blastGold = airdropData.points;
    },

    setLastItemsReceived(state, items) {
        state.lastItemsReceived = items;
    },
};

const actions = {
    async login({ commit, dispatch }, payload) {
        const { account, chainId } = payload;

        commit("user/setAccount", account, { root: true });
        commit("user/setChainId", chainId, { root: true });
    },

    async getBalances({ commit, state, dispatch, rootState }, payload) {
        const { cropTypes, itemTypes, fishTypes } = rootState.content;

        const seedsBalances = await dispatch("contracts/crops/getSeedsBalance", {}, { root: true });
        const cropBalances = await dispatch("contracts/items/getCropsBalance", {}, { root: true });
        const itemsBalances = await dispatch("contracts/items/getItemsBalances", {}, { root: true });
        const fishesBalances = await dispatch("contracts/items/getFishesBalances", {}, { root: true });
        const yieldBalance = await dispatch("contracts/yield/getYieldBalance", {}, { root: true });

        const userSeedBalances = {};
        seedsBalances.forEach((seed) => {
            const name = cropTypes[seed.tokenId];
            userSeedBalances[name] = seed.balance;
        });

        const userCropBalances = {};
        cropBalances.forEach((crop) => {
            const name = cropTypes[crop.tokenId];
            userCropBalances[name] = crop.balance;
        });

        const userItemBalances = {};
        itemsBalances.forEach((item, index) => {
            const name = itemTypes[index];
            userItemBalances[name] = item.balance;
        });

        const userFishesBalances = {};
        fishesBalances.forEach((fish, index) => {
            const name = fishTypes[index];
            userFishesBalances[name] = fish.balance;
        });

        commit("setBalance", {
            token: "yield",
            value: formatNumber(yieldBalance),
        });
        commit("setBalance", { token: "rawYield", value: yieldBalance });
        commit("setSeedBalances", userSeedBalances);
        commit("setCropsBalances", userCropBalances);
        commit("setItemsBalances", userItemBalances);
        commit("setFishesBalances", userFishesBalances);
        commit("setAllBalances", { ...userSeedBalances, ...userCropBalances, ...userItemBalances, ...userFishesBalances });

        commit("setBalance", {});
    },

    async getProfile({ commit, dispatch }) {
        try {
            /** @type {import("../contracts/dataAggregator").PlayerInfoDict} */
            const profileData = await dispatch("contracts/dataAggregator/getPlayerProfile", {}, { root: true });

            commit("setUserProfile", profileData);
        } catch (error) {
            //console.log(error);
        }
    },

    async getPfpTokenIds({ commit, dispatch }) {
        try {
            const tokenIds = await dispatch("contracts/pfpLens/getPfpIds", {}, { root: true });

            commit("setPfpTokenIds", tokenIds);
        } catch (error) {
            console.log(error);
        }
    },

    async getPfpSlots({ commit, dispatch }) {
        try {
            const slots = await dispatch("contracts/pfpController/getPfpSlots", {}, { root: true });

            commit("setPfpSlots", slots);
        } catch (error) {
            console.log(error);
        }
    },

    async getFarmStats({ commit, dispatch }) {
        try {
            const farmerInfo = await dispatch("contracts/farm/getFarmerInfo", {}, { root: true });

            const { farmPlotsData, pendingRewards, lockedRatio } = farmerInfo;

            commit("setFarmPlots", farmPlotsData);
            commit("setPendingRewards", Number(pendingRewards));
            commit("setLockedRatio", lockedRatio);
        } catch (error) {
            console.log(error);
        }
    },

    async getStats({ commit, dispatch }) {
        try {
            if (!state.account || state.account === ZERO_ADDRESS) return;
            await dispatch("getBalances");
            await dispatch("getProfile");
            await dispatch("getPfpSlots");
            await dispatch("getFarmStats");
            commit("setAirdropData");
        } catch (error) {
            console.log(error);
        }
    },
};

/**
 * @param {string} cname
 */
function getCookie(cname) {
    var name = cname + "=";
    var decodedCookie = decodeURIComponent(document.cookie);
    var ca = decodedCookie.split(";");
    for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) == " ") {
            c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
            return c.substring(name.length, c.length);
        }
    }
    return "";
}

const getters = {
    /** @param {typeof state} state */
    isOnBlast(state) {
        return true;

        if (isDevelopmentMode) {
            return state.chainId === CHAIN_IDS.testnet;
        }
        return state.chainId === CHAIN_IDS.mainnet;
    },

    /** @param {typeof state} state */
    isLoggedIn(state) {
        return state.account !== "" && state.account !== ZERO_ADDRESS;
    },

    /** @param {typeof state} state */
    isNewUser(state) {
        const userCookie = getCookie("cookie-user-login-info");
        return !state.account || state.account === ZERO_ADDRESS;
    },

    /** @param {typeof state} state */
    isUnregisteredUser(state) {
        return state.account && state.account !== ZERO_ADDRESS && !state.name;
    },
};

const userModule = {
    state: () => state,
    mutations,
    getters,
    actions,
    namespaced: true,
};

export default userModule;

export function formatNumber(input, decimals = 2) {
    // Convert input to a number if it's not already
    const num = parseFloat(input);

    // Check if the input is a number
    if (isNaN(num)) {
        return "Invalid input"; // or handle the error as you prefer
    }

    const lookup = [
        { value: 1e18, symbol: "E" },
        { value: 1e15, symbol: "P" },
        { value: 1e12, symbol: "T" },
        { value: 1e9, symbol: "G" },
        { value: 1e6, symbol: "M" },
        { value: 1e3, symbol: "k" },
        { value: 1, symbol: "" },
        // Add more if needed
    ];

    const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
    var item = lookup.find(function (item) {
        return num >= item.value || num <= -item.value;
    });

    return item ? (num / item.value).toFixed(decimals).replace(rx, "$1") + item.symbol : num.toFixed(decimals).replace(rx, "$1");
}

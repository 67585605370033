<template>
    <div class="game-frame" ref="game">
        <div class="game-frame-house">
            <img style="width: 100%; height: 100%" src="../../../public/images/house.png" />

            <MapBanner @clicked="setCurrentModal('revealAdmin')" :text="$t('menu.reveal')" :position="{ x: 800, y: 90 }"></MapBanner>

            <MapBanner @clicked="setCurrentModal('revealAdminCraft')" :text="'Stuck Craft'" :position="{ x: 500, y: 90 }"></MapBanner>
        </div>
    </div>
</template>

<script>
import { mapActions, mapState } from "vuex";

export default {
    name: "AdminScreen",

    components: {},

    props: {},

    data() {
        return {};
    },

    mounted() {
        const panzoomContainer = this.$refs.game;
        this.initializePanzoom(panzoomContainer);
        this.resetZoom();
    },

    beforeDestroy() {
        this.destroyPanzoom();
        this.resetZoom();
    },

    methods: {
        ...mapActions("site", ["setCurrentModal"]),
        ...mapActions("panzoom", ["initializePanzoom", "destroyPanzoom", "zoomTo", "resetZoom"]),
    },

    computed: {
        ...mapState("user", ["chainId", "farmLevel"]),

        isTestnet() {
            return false; // this.chainId == "168587773";
        },

        chestType() {
            if (this.farmLevel < 5) {
                return "wooden_chest";
            } else if (this.farmLevel < 10) {
                return "bronze_chest";
            } else if (this.farmLevel < 15) {
                return "silver_chest";
            } else {
                return "golden_chest";
            }
        },
    },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.game-frame {
    width: 100%;
    height: 100%;
    border-radius: 0.25rem;

    &-house {
        width: 960px;
        height: 480px;
        position: absolute;
        border-image: url("../../../public/images/itemBg.png") 6 fill repeat;
        border-image-width: 1rem;
        border-image-outset: 0.5rem;

        position: absolute;
        top: calc(50% - 240px);
        left: calc(50% - 480px);
    }
}
</style>

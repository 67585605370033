import { ethers } from "ethers";
import { createNotification } from "@/libs/storeHelper";

const state = () => ({
    dailyChestContract: null,

    timeTillNextEpoch: 0,
    claimStatus: {
        woodenChest: false,
        bronzeChest: false,
        silverChest: false,
        goldenChest: false,
    },
});

const mutations = {
    initializeContract(state, contract) {
        state.dailyChestContract = contract;
    },

    setTimeTillNextEpoch(state, time) {
        state.timeTillNextEpoch = Number(time) * 1000;
    },

    setClaimStatus(state, claimStatus) {
        state.claimStatus = {
            woodenChest: claimStatus[0],
            bronzeChest: claimStatus[1],
            silverChest: claimStatus[2],
            goldenChest: claimStatus[3],
        };
    },
};

const actions = {
    boot({ commit, rootState }, { contractAddress }) {
        const abi = require("@/plugins/artefacts/dailyChest.json");

        const { signer, defaultSigner } = rootState.site;
        const contract = new ethers.Contract(contractAddress, abi, signer || defaultSigner);

        commit("initializeContract", contract);
    },

    async initializeContractData({ state, rootState, commit }) {
        const contract = state.dailyChestContract;
        const { storedAccount } = rootState.user;
        const time = await contract.timeTillNextEpoch();
        const claimStatus = await contract.canClaim(storedAccount);

        commit("setTimeTillNextEpoch", Number(time));
        commit("setClaimStatus", claimStatus);
    },

    async claim({ state, dispatch, rootState }) {
        const contract = state.dailyChestContract;
        const { baseGwei } = rootState.site.settings;

        const tx = await contract.claim({
            gasPrice: baseGwei
        });

        createNotification({
            dispatch,
            message: `Tx: Claiming daily chest!`,
            type: "warn",
            id: tx.hash,
            showSpinner: true,
        });

        await tx.wait(1);

        dispatch("user/getProfile", {}, { root: true });
        dispatch("site/notifications/remove", tx.hash, { root: true });

        createNotification({
            dispatch,
            message: `Daily chest claimed!`,
            duration: 5000,
        });

        dispatch("initializeContractData");
    },
};

const getters = {};

export default {
    state,
    mutations,
    getters,
    actions,
    namespaced: true,
};

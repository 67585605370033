import Vue from "vue";

import settingsModule from "@/store/site/settings";
import notificationsModule from "@/store/site/notifications";
import tooltipModule from "@/store/site/tooltip";
import npcDialogueModule from "@/store/site/npcDialogue";

import { ethers } from "ethers";
import { RPCS, CHAIN_IDS, ZERO_ADDRESS, isDevelopmentMode, createNotification } from "@/libs/storeHelper";
import _ from "lodash";

import i18n, { selectedLocale } from "@/lang/i18n";
import { ensureLocaleLoaded } from "@/libs/i18n-helpers";

import { countryLocales } from "@/libs/country";
import { mapActions } from "vuex";
/**
 * @typedef {typeof state} State
 */
const state = {
    initialized: false,
    loading: false,

    signer: undefined,
    defaultSigner: undefined,

    locale: selectedLocale,

    modals: {
        planter: false,
        gardener: false,
        seedPicker: false,
        referrals: false,
        seedShop: false,
        gachaResult: false,
        nameModal: false,
        faucet: false,
        inventory: false,
        settings: false,
        sage: false,
        avatarPicker: false,
        dexInterface: false,
        kickstarter: false,
        wallet: false,
        banker: false,
        dailyChest: false,
        chestResult: false,
        revealAdmin: false,
        revealAdminCraft: false,
        leaderboard: false,
        blastGold: false,
        pfp: false,
        marketplace: false,
        marketplaceHelper: false,
        lottery: false,
        craftingStation: false,
        tutorial: false,
        druid: false,
        druidSummon: false,
        adventurers: false,
        fishing: false,
        angler: false,
    },
    modalUpdateKey: 0,

    buttonLoaders: {},

    modalsComponents: {},
    currentModal: null,
    currentModalName: "",

    userDataRefreshInterval: null,
    invalidChainError: false,

    showSpinner: false,
    spinnerFullscreen: false,
    spinnerMessage: "Waiting for transaction...",
    spinnerType: "txConfirm",
    spinnerAnimation: 0,
};

/**
 * @type {import("vuex").MutationTree<State>}
 */
const mutations = {
    setInitialized(state, initialized) {
        state.initialized = initialized;
    },

    setLoading(state, loading) {
        Vue.set(state, "loading", loading);
    },

    setShowSpinner(state, options = { showSpinner, fullscreen: true, type: "txConfirm", message: "Waiting for transaction..." }) {
        if (options.type === "txConfirm") {
            state.spinnerAnimation = Math.floor(Math.random() * 5);
        }

        state.showSpinner = options.showSpinner;
        state.spinnerFullscreen = options.fullscreen;
        state.spinnerMessage = options.message;
        state.spinnerType = options.type;
    },

    setButtonLoader(state, { buttonId, loading }) {
        Vue.set(state.buttonLoaders, buttonId, loading);
    },

    setLocale(state, locale) {
        state.locale = locale;
    },

    setModalsComponents(state, components) {
        state.modalsComponents = components;
    },

    setDefaultSigner(state, signer) {
        state.defaultSigner = signer;
    },

    setSigner(state, signer) {
        state.signer = signer;
    },

    setCurrentModal(state, modalName) {
        state.currentModal = state.modalsComponents[modalName];
        state.currentModalName = modalName;
        state.modalUpdateKey++;
    },

    setUserRefreshInterval(state, interval) {
        state.userDataRefreshInterval = interval;
    },

    clearUserRefreshInterval(state) {
        clearInterval(state.userDataRefreshInterval);
    },
};

/**
 * @type {import("vuex").ActionTree<State, import("@/store/index").RootState>}
 */
const actions = {
    ...mapActions("site/npcDialogue", ["setSelectedNPC"]),
    async boot({ commit, rootState, dispatch, state }) {
        try {
            await dispatch("content/boot", {}, { root: true });
            dispatch("site/tooltip/boot", {}, { root: true });
            dispatch("site/settings/boot", {}, { root: true });
            await dispatch("changeLocale", { lang: state.locale });

            const modals = Object.keys(state.modals);
            const modalsComponents = {};
            modals.forEach((modal) => {
                modalsComponents[modal] = () => import(`@/components/modal/${_.upperFirst(modal)}Modal.vue`);
            });
            commit("setModalsComponents", modalsComponents);

            const defaultRPC = isDevelopmentMode ? RPCS.testnet : RPCS.mainnet;
            const defaultSigner = new ethers.JsonRpcProvider(defaultRPC);
            const chainId = isDevelopmentMode ? CHAIN_IDS.testnet : CHAIN_IDS.mainnet;

            commit("setDefaultSigner", defaultSigner);
            commit("user/setChainId", chainId, { root: true });

            await dispatch("contracts/boot", {}, { root: true });
            await dispatch("user/getStats", {}, { root: true });

            const interval = setInterval(() => {
                dispatch("user/getStats", {}, { root: true });
            }, 30000);

            commit("setInitialized", true);
            commit("setUserRefreshInterval", interval);

            setTimeout(async () => {
                window.ethereum.on("accountsChanged", async (accounts) => {
                    if (accounts.length === 0) {
                        localStorage.setItem("cv:stored_account", ZERO_ADDRESS);
                        dispatch("disconnectWallet");
                    } else {
                        const account = accounts[0];
                        localStorage.setItem("cv:stored_account", account);
                        window.location.reload();
                    }
                });

                window.ethereum.on("chainChanged", async (chainId) => {
                    commit("user/setChainId", Number(chainId), { root: true });
                });
            }, 100);
        } catch (error) {
            console.log(error);
        }
    },

    async connectWallet({ commit, dispatch, rootState, state }, { forceSigner = false }) {
        if (!window.ethereum) return;

        try {
            const storedAccount = localStorage.getItem("cv:stored_account") || ZERO_ADDRESS;

            const provider = new ethers.BrowserProvider(window.ethereum);

            if (!storedAccount || storedAccount === ZERO_ADDRESS || forceSigner) {
                const ethAccounts = await window.ethereum.request({
                    method: "eth_accounts",
                });
                const account = ethAccounts[0];

                const currentChainId = Number(
                    await window.ethereum.request({
                        method: "eth_chainId",
                    }),
                );

                //
                if (account) {
                    commit("user/setAccount", account, { root: true });
                    const signer = new ethers.JsonRpcSigner(provider, account);
                    commit("setSigner", signer);

                    await dispatch("contracts/boot", {}, { root: true });
                    return;
                }

                if (isDevelopmentMode && currentChainId !== CHAIN_IDS.testnet) {
                    createNotification({
                        dispatch,
                        type: "error",
                        message: "Please connect to Blast Sepolia testnet!",
                        id: "invalid-chain",
                        duration: 5000,
                    });

                    state.invalidChainError = true;
                    return;
                } else if (!isDevelopmentMode && currentChainId !== CHAIN_IDS.mainnet) {
                    createNotification({
                        dispatch,
                        type: "error",
                        message: "Please connect to Blast Mainnet!",
                        id: "invalid-chain",
                        duration: 5000,
                    });
                    state.invalidChainError = true;

                    return;
                }

                const signer = await provider.getSigner();
                const network = await provider.getNetwork();
                const chainId = parseInt(network.chainId);
                const accounts = await provider.listAccounts();

                commit("user/setAccount", accounts[0].address, { root: true });
                commit("user/setChainId", chainId, { root: true });
                commit("setSigner", signer);
                // reload contracts
                await dispatch("contracts/boot", {}, { root: true });
                await dispatch("user/getStats", {}, { root: true });
                return;
            }

            commit("user/setAccount", storedAccount, { root: true });
            const signer = new ethers.JsonRpcSigner(provider, storedAccount);
            commit("setSigner", signer);
        } catch (err) {
            console.log(err);
        }
    },

    disconnectWallet({ commit, dispatch, state }) {
        commit("user/setAccount", ZERO_ADDRESS, { root: true });
        commit("setSigner", undefined);
        localStorage.removeItem("cv:stored_account");
        dispatch("user/getPrgetStatsofile", {}, { root: true });
        dispatch("resetCurrentModal");
    },

    async requestChangeNetwork({ commit, dispatch, state }) {
        try {
            const [mainnet, testnet] = ["0x13e31", "0xA0C71FD"];
            const chainId = isDevelopmentMode ? testnet : mainnet;

            await window.ethereum.request({
                method: "wallet_switchEthereumChain",
                params: [{ chainId }],
            });

            const changedChainId = await window.ethereum.request({
                method: "eth_chainId",
            });

            if (Number(chainId) == Number(changedChainId)) {
                state.invalidChainError = false;

                commit("user/setChainId", Number(changedChainId), {
                    root: true,
                });

                await dispatch("contracts/boot", {}, { root: true });
            }
        } catch (error) {
            if (error.code === 4902) {
                await dispatch("addChain");
            }
        }
    },

    async addChain({ commit, dispatch, state }, payload) {
        const blastMainnet = {
            chainId: "0x13e31",
            chainName: "Blast Mainnet",
            nativeCurrency: {
                name: "Ethereum",
                symbol: "ETH",
                decimals: 18,
            },
            blockExplorerUrls: ["https://rpc.blast.io/"],
            rpcUrls: ["https://rpc.blast.io/"],
        };
        const blastSepolia = {
            chainId: "0xA0C71FD",
            chainName: "Blast Sepolia",
            nativeCurrency: {
                name: "Blast Sepolia",
                symbol: "ETH",
                decimals: 18,
            },
            blockExplorerUrls: ["https://testnet.blastscan.io/"],
            rpcUrls: ["https://sepolia.blast.io/"],
        };
    },

    setCurrentModal({ commit, dispatch, state }, modalName) {
        commit("setCurrentModal", modalName);
    },

    resetCurrentModal({ commit, dispatch, state }) {
        commit("setCurrentModal", null);
    },

    clearUserRefreshInterval({ commit, dispatch, state }) {
        commit("clearUserRefreshInterval");
    },

    setLoading({ commit, dispatch, state }, payload) {
        commit("setLoading", payload);
    },

    setShowSpinner({ commit, dispatch, state }, payload) {
        commit("setShowSpinner", payload);
    },

    showLoadingTxSpinner({ commit }, { message = "Waiting for transaction..." }) {
        commit("setShowSpinner", { showSpinner: true, fullscreen: true, type: "txWait", message });
    },

    setButtonLoading({ commit, dispatch, state }, { buttonId, loading }) {
        console.log("HUH:" + buttonId + " " + loading);
        commit("setButtonLoader", { buttonId, loading });
    },

    async changeLocale({ commit }, { lang }) {
        const currentLocale = localStorage.getItem("site:locale");
        if (!currentLocale) {
            const languages = navigator.languages;
            for (const l in languages) {
                let language = languages[l];
                if (countryLocales.hasOwnProperty(language)) {
                    lang = language;
                    break;
                }
            }
        }
        document.querySelector("html").classList.add("en");
        /*await ensureLocaleLoaded(lang);

        i18n.locale = lang;
        document.querySelector("html").setAttribute("lang", lang);
        document.querySelector("html").classList.add(lang);
        localStorage.setItem("site:locale", lang);

        commit("setLocale", lang);*/
    },
};

/**
 * @type {import("vuex").GetterTree<State, import("@/store/index").RootState>}
 */
const getters = {};

export default {
    state,
    mutations,
    getters,
    actions,
    namespaced: true,
    modules: {
        settings: settingsModule,
        notifications: notificationsModule,
        tooltip: tooltipModule,
        npcDialogue: npcDialogueModule,
    },
};

import { ethers } from "ethers";
import { createNotification, getTxErrorMessage } from "@/libs/storeHelper";

const state = () => ({
    bankContract: null,

    tokenRatio: 0,
});

const mutations = {
    initializeContract(state, contract) {
        state.bankContract = contract;
    },

    setTokenRatio(state, ratio) {
        state.tokenRatio = Number(ratio);
    },
};

const actions = {
    boot({ commit, dispatch, rootState }, { contractAddress }) {
        const abi = require("@/plugins/artefacts/bank.json");

        const { signer, defaultSigner } = rootState.site;
        const contract = new ethers.Contract(contractAddress, abi, signer || defaultSigner);

        commit("initializeContract", contract);
        dispatch("getBankStats");
    },

    async getBankStats({ state, commit, rootState }) {
        const contract = state.bankContract;
        const { storedAccount } = rootState.user;

        const data = await contract.getPlayerGeneralInfo(storedAccount);
        const { balance, ratio } = data;

        commit(
            "user/setBalance",
            {
                token: "xyield",
                value: Number(balance),
            },
            { root: true },
        );
        commit("setTokenRatio", Number(ratio) / 1e18);
    },

    async deposit({ state, dispatch, rootState }, { amount }) {
        try {
            const contract = state.bankContract;
            const { baseGwei } = rootState.site.settings;

            const tx = await contract.enter(amount, {
                //gasPrice: baseGwei,
            });

            createNotification({
                dispatch,
                message: `Tx: Bank deposit!`,
                type: "warn",
                id: tx.hash,
                showSpinner: true,
            });

            await tx.wait(1);

            dispatch("getBankStats");
            dispatch("site/notifications/remove", tx.hash, { root: true });

            createNotification({
                dispatch,
                message: `Deposit successfully!`,
                duration: 5000,
            });
        } catch (error) {
            dispatch("site/notifications/clearAll", {}, { root: true });
            getTxErrorMessage(dispatch, error);
        }
    },

    async withdraw({ state, dispatch, rootState }, { amount }) {
        try {
            const contract = state.bankContract;
            const { baseGwei } = rootState.site.settings;

            const tx = await contract.leave(amount, {
                //gasPrice: baseGwei,
            });

            createNotification({
                dispatch,
                message: `Tx: Bank withdraw!`,
                type: "warn",
                id: tx.hash,
                showSpinner: true,
            });

            await tx.wait(1);

            dispatch("getBankStats");
            dispatch("site/notifications/remove", tx.hash, { root: true });

            createNotification({
                dispatch,
                message: `Withdraw successfully!`,
                duration: 5000,
            });
        } catch (error) {
            dispatch("site/notifications/clearAll", {}, { root: true });
            getTxErrorMessage(dispatch, error);
        }
    },
};

const getters = {};

export default {
    state,
    mutations,
    getters,
    actions,
    namespaced: true,
};

import { MaxInt256 } from "ethers";

const { axios } = require("@/libs/axios");

export async function getMarketplaceOrders({ market, owner = "", seller = "", maxPricePerItem, orderBy, orderDirection, searchFilters, page, perPage = 500 }) {
    const query = fetchMarketNFTs(searchFilters);

    const tokenId = market.toLowerCase();

    const variables = {
        market: tokenId,
        owner,
        seller,
        orderBy,
        orderDirection,
        searchFilters,
        page,
        perPage,
        skip: page * perPage,
        maxPricePerItem,
    };

    const result = await axios.post("/marketplace", {
        headers: {
            "Content-Type": "application/json",
        },
        query,
        variables,
    });

    const nfts = result?.data.data.nfts || [];
    nfts.forEach((nft) => {
        nft.isSelected = true;
        nft.sellerAddress = nft.seller.id.toLowerCase();
        nft.balance = Number(nft.balance);
        nft.price = Number(nft.price);
        nft.rawPrice = Number(nft.price) / 1e18;
    });

    return nfts;
}

export async function getRecentActivity({
    market,
    seller = "",
    buyer = "",
    searchFilters,
    page,
    orderBy = "timestamp",
    orderDirection = "desc",
    perPage = 500,
}) {
    const query = fetchMarketActivity(searchFilters);

    const variables = {
        market: market.toLowerCase(),
        seller,
        buyer,
        orderBy: orderBy,
        orderDirection: orderDirection,
        searchFilters,
        page,
        perPage,
        skip: page * perPage,
    };

    if (seller) {
        variables.seller = seller.toLowerCase();
    }

    if (buyer) {
        variables.buyer = buyer.toLowerCase();
    }

    const result = await axios.post("/marketplace", {
        headers: {
            "Content-Type": "application/json",
        },
        query,
        variables,
    });

    return result?.data.data.sales || [];
}

export async function getMarketStats() {
    const query = fetchMarketStats();

    const data = await axios.post("/marketplace", {
        headers: {
            "Content-Type": "application/json",
        },
        query,
    });

    const markets = data?.data.data.markets || [];

    markets.forEach((market) => {
        const floorPrice = market.listings[0]?.price || 0;
        market.floorPrice = floorPrice;
    });

    return data?.data.data.markets[0] || [];
}

export async function getFloorPrice({ tokenId }) {
    const query = fetchFloorPrice();

    const variables = {
        tokenId,
    };

    const result = await axios.post("/marketplace", {
        headers: {
            "Content-Type": "application/json",
        },
        query,
        variables,
    });

    return result.data.data.listings[0].price;
}

// QUERIES

const fetchMarketNFTs = (searchFilters) => {
    if (searchFilters && searchFilters.length > 0) {
        return `
        query ($market: String!, $seller: String, $owner: String, $orderBy: Listing_orderBy, $orderDirection: OrderDirection!, $searchFilters: [BigInt!], $perPage: Int!, $skip: Int!, $maxPricePerItem: BigInt) {
         nfts: listings(orderBy: $orderBy, orderDirection: $orderDirection, first: $perPage, skip: $skip, where: {tokenId_in: $searchFilters, seller_not: $owner, market: $market, seller_contains: $seller,active: true, price_lte: $maxPricePerItem}) {
           tokenId
           market{
             id
           }
           orderId
           active
           price
           balance
     
           seller {
             id
           }
         }
       }
       `;
    }

    return `
    query ($market: String!, $seller: String, $owner: String, $orderBy: Listing_orderBy, $orderDirection: OrderDirection!, $perPage: Int!, $skip: Int!, $maxPricePerItem: BigInt) {
     nfts: listings(orderBy: $orderBy, orderDirection: $orderDirection, first: $perPage, skip: $skip, where: {seller_not: $owner, market: $market, seller_contains: $seller, active: true, price_lte: $maxPricePerItem}) {
       tokenId
       market{
         id
       }
       orderId
       active
       price
       balance
 
       seller {
         id
       }
     }
   }
   `;
};

const fetchMarketStats = () => {
    return `
    {
     markets {
       id
       token
       totalFees
       totalSales
       volume
       totalListings
       
       listings(first: 1, orderBy: price, orderDirection: asc, where: {active: true}) {
         price
       }
     }
   }
     `;
};

const fetchMarketActivity = (searchFilters) => {
    if (searchFilters && searchFilters.length > 0) {
        return `
        query ($market: String!, $seller: String, $buyer: String, $orderBy: Sale_orderBy, $orderDirection: OrderDirection!, $searchFilters: [BigInt!], $perPage: Int!, $skip: Int!) {
         sales(orderBy: $orderBy, orderDirection: $orderDirection, first: $perPage, skip: $skip, where: {tokenId_in: $searchFilters, market: $market, seller_contains: $seller, buyer_contains: $buyer}) {
           tokenId
           market {
             id
           }
           price
           balance
           timestamp
           image
           buyer {
             id
           }
           seller {
             id
           }
         }
       }
       `;
    } else {
        return `
        query ($market: String!, $seller: String, $buyer: String, $orderBy: Sale_orderBy, $orderDirection: OrderDirection!, $perPage: Int!, $skip: Int!) {
         sales(orderBy: $orderBy, orderDirection: $orderDirection, first: $perPage, skip: $skip, where: {market: $market, seller_contains: $seller, buyer_contains: $buyer}) {
           tokenId
           market {
             id
           }
           price
           balance
           timestamp
           image
           buyer {
             id
           }
           seller {
             id
           }
         }
       }
       `;
    }
};

const fetchGlobalOffers = () => {
    return `
   query ($market: String) {
     globalOffers(orderBy: timestamp, where: {active: true, market_contains: $market}) {
       id: chainId
       owner {
        id
      }
      token: market {
        id
      }
      value
      expiresAt
      timestamp
     }
   }
  `;
};

const fetchFloorPrice = () => {
    return `
  query ($tokenId: BigInt!){
    listings(where: {tokenId: $tokenId, active: true}, orderBy: price, orderDirection: asc, first: 1 ) {
      price
    }
  }`;
};

const Country = Object.freeze({
    AF: {
        name: "Afghanistan",
        id: "AF",
        code: "004",
    },
    AL: {
        name: "Albania",
        id: "AL",
        code: "008",
    },
    DZ: {
        name: "Algeria",
        id: "DZ",
        code: "012",
    },
    AS: {
        name: "American Samoa",
        id: "AS",
        code: "012",
    },
    AD: {
        name: "Andorra",
        id: "AD",
        code: "020",
    },
    AO: {
        name: "Angola",
        id: "AO",
        code: "024",
    },
    AI: {
        name: "Anguilla",
        id: "AI",
        code: "660",
    },
    AG: {
        name: "Antigua and Barbuda",
        id: "AG",
        code: "028",
    },
    AR: {
        name: "Argentina",
        id: "AR",
        code: "032",
    },
    AM: {
        name: "Armenia",
        id: "AM",
        code: "051",
    },
    AW: {
        name: "Aruba",
        id: "AW",
        code: "533",
    },
    AU: {
        name: "Australia",
        id: "AU",
        code: "036",
    },
    AT: {
        name: "Austria",
        id: "AT",
        code: "040",
    },
    AZ: {
        name: "Azerbaijan",
        id: "AZ",
        code: "031",
    },
    BS: {
        name: "Bahamas",
        id: "BS",
        code: "044",
    },
    BH: {
        name: "Bahrain",
        id: "BH",
        code: "048",
    },
    BD: {
        name: "Bangladesh",
        id: "BD",
        code: "050",
    },
    BB: {
        name: "Barbados",
        id: "BB",
        code: "052",
    },
    BY: {
        name: "Belarus",
        id: "BY",
        code: "112",
    },
    BE: {
        name: "Belgium",
        id: "BE",
        code: "056",
    },
    BZ: {
        name: "Belize",
        id: "BZ",
        code: "084",
    },
    BJ: {
        name: "Benin",
        id: "BJ",
        code: "204",
    },
    BM: {
        name: "Bermuda",
        id: "BM",
        code: "060",
    },
    BT: {
        name: "Bhutan",
        id: "BT",
        code: "064",
    },
    BO: {
        name: "Bolivia",
        id: "BO",
        code: "068",
    },
    BQ: {
        name: "Bonaire, Sint Eustatius and Saba",
        id: "BQ",
        code: "535",
    },
    BA: {
        name: "Bosnia and Herzegovina",
        id: "BA",
        code: "070",
    },
    BW: {
        name: "Botswana",
        id: "BW",
        code: "072",
    },
    BR: {
        name: "Brazil",
        id: "BR",
        code: "076",
        nativeLanguageName: "Português (BR)",
        continueButtonText: "Continue em Português do Brasil",
    },
    BN: {
        name: "Brunei Darussalam",
        id: "BN",
        code: "096",
    },
    BG: {
        name: "Bulgaria",
        id: "BG",
        code: "100",
    },
    BF: {
        name: "Burkina Faso",
        id: "BF",
        code: "854",
    },
    BI: {
        name: "Burundi",
        id: "BI",
        code: "108",
    },
    CV: {
        name: "Cabo Verde",
        id: "CV",
        code: "132",
    },
    KH: {
        name: "Cambodia",
        id: "KH",
        code: "116",
    },
    CM: {
        name: "Cameroon",
        id: "CM",
        code: "120",
    },
    CA: {
        name: "Canada",
        id: "CA",
        code: "124",
    },
    KY: {
        name: "Cayman Islands",
        id: "KY",
        code: "136",
    },
    CF: {
        name: "Central African Republic",
        id: "CF",
        code: "140",
    },
    TD: {
        name: "Chad",
        id: "TD",
        code: "148",
    },
    CL: {
        name: "Chile",
        id: "CL",
        code: "152",
    },
    CN: {
        name: "China",
        id: "CN",
        code: "156",
        nativeLanguageName: "中文",
        continueButtonText: "继续用中文",
    },
    CX: {
        name: "Christmas Island",
        id: "CX",
        code: "162",
    },
    CC: {
        name: "Cocos (Keeling) Islands",
        id: "CC",
        code: "166",
    },
    CO: {
        name: "Colombia",
        id: "CO",
        code: "170",
    },
    KM: {
        name: "Comoros",
        id: "KM",
        code: "174",
    },
    CG: {
        name: "Congo",
        id: "CG",
        code: "178",
    },
    CD: {
        name: "Congo, DRC",
        id: "CD",
        code: "180",
    },
    CK: {
        name: "Cook Islands",
        id: "CK",
        code: "184",
    },
    CR: {
        name: "Costa Rica",
        id: "CR",
        code: "188",
    },
    CI: {
        name: "Côte d'Ivoire",
        id: "CI",
        code: "384",
    },
    HR: {
        name: "Croatia",
        id: "HR",
        code: "191",
    },
    CU: {
        name: "Cuba",
        id: "CU",
        code: "192",
    },
    CW: {
        name: "Curaçao",
        id: "CW",
        code: "531",
    },
    CY: {
        name: "Cyprus",
        id: "CY",
        code: "196",
    },
    CZ: {
        name: "Czechia",
        id: "CZ",
        code: "203",
    },
    DK: {
        name: "Denmark",
        id: "DK",
        code: "208",
        nativeLanguageName: "Dansk",
        continueButtonText: "Fortsæt på Dansk",
    },
    DJ: {
        name: "Djibouti",
        id: "DJ",
        code: "262",
    },
    DM: {
        name: "Dominica",
        id: "DM",
        code: "212",
    },
    DO: {
        name: "Dominican Republic",
        id: "DO",
        code: "214",
    },
    EC: {
        name: "Ecuador",
        id: "EC",
        code: "218",
    },
    EG: {
        name: "Egypt",
        id: "EG",
        code: "818",
    },
    SV: {
        name: "El Salvador",
        id: "SV",
        code: "222",
    },
    GQ: {
        name: "Equatorial Guinea",
        id: "GQ",
        code: "226",
    },
    ER: {
        name: "Eritrea",
        id: "ER",
        code: "232",
    },
    EE: {
        name: "Estonia",
        id: "EE",
        code: "233",
    },
    SZ: {
        name: "Eswatini",
        id: "SZ",
        code: "748",
    },
    ET: {
        name: "Ethiopia",
        id: "ET",
        code: "231",
    },
    FK: {
        name: "Falkland Islands (Malvinas)",
        id: "FK",
        code: "238",
    },
    FO: {
        name: "Faroe Islands",
        id: "FO",
        code: "234",
    },
    FJ: {
        name: "Fiji",
        id: "FJ",
        code: "242",
    },
    FI: {
        name: "Finland",
        id: "FI",
        code: "246",
        nativeLanguageName: "Suomi",
        continueButtonText: "Jatka Suomeksi",
    },
    FR: {
        name: "France",
        id: "FR",
        code: "250",
    },
    GF: {
        name: "French Guiana",
        id: "GF",
        code: "254",
    },
    PF: {
        name: "French Polynesia",
        id: "PF",
        code: "258",
    },
    GA: {
        name: "Gabon",
        id: "GA",
        code: "266",
    },
    GM: {
        name: "Gambia",
        id: "GM",
        code: "270",
    },
    GE: {
        name: "Georgia",
        id: "GE",
        code: "268",
    },
    DE: {
        name: "Germany",
        id: "DE",
        code: "276",
        nativeLanguageName: "Deutsch",
        continueButtonText: "Weiter auf Deutsch",
    },
    GH: {
        name: "Ghana",
        id: "GH",
        code: "288",
    },
    GI: {
        name: "Gibraltar",
        id: "GI",
        code: "292",
    },
    GR: {
        name: "Greece",
        id: "GR",
        code: "300",
    },
    GL: {
        name: "Greenland",
        id: "GL",
        code: "304",
    },
    GD: {
        name: "Grenada",
        id: "GD",
        code: "308",
    },
    GP: {
        name: "Guadeloupe",
        id: "GP",
        code: "312",
    },
    GU: {
        name: "Guam",
        id: "GU",
        code: "316",
    },
    GT: {
        name: "Guatemala",
        id: "GT",
        code: "320",
    },
    GN: {
        name: "Guinea",
        id: "GN",
        code: "324",
    },
    GW: {
        name: "Guinea-Bissau",
        id: "GW",
        code: "624",
    },
    GY: {
        name: "Guyana",
        id: "GY",
        code: "328",
    },
    HT: {
        name: "Haiti",
        id: "HT",
        code: "332",
    },
    VA: {
        name: "Holy See",
        id: "VA",
        code: "336",
    },
    HN: {
        name: "Honduras",
        id: "HN",
        code: "340",
    },
    HK: {
        name: "Hong Kong",
        id: "HK",
        code: "344",
    },
    HU: {
        name: "Hungary",
        id: "HU",
        code: "348",
    },
    IS: {
        name: "Iceland",
        id: "IS",
        code: "352",
    },
    IN: {
        name: "India",
        id: "IN",
        code: "356",
    },
    ID: {
        name: "Indonesia",
        id: "ID",
        code: "360",
        nativeLanguageName: "Bahasa Indonesia",
        continueButtonText: "Lanjutkan dalam Bahasa Indonesia",
    },
    IR: {
        name: "Iran",
        id: "IR",
        code: "364",
    },
    IQ: {
        name: "Iraq",
        id: "IQ",
        code: "368",
    },
    IE: {
        name: "Ireland",
        id: "IE",
        code: "372",
    },
    IM: {
        name: "Isle of Man",
        id: "IM",
        code: "833",
    },
    IL: {
        name: "Israel",
        id: "IL",
        code: "376",
    },
    IT: {
        name: "Italy",
        id: "IT",
        code: "380",
    },
    JM: {
        name: "Jamaica",
        id: "JM",
        code: "388",
    },
    JP: {
        name: "Japan",
        id: "JP",
        code: "392",
        nativeLanguageName: "日本語",
        continueButtonText: "日本語で続ける",
    },
    JE: {
        name: "Jersey",
        id: "JE",
        code: "832",
    },
    JO: {
        name: "Jordan",
        id: "JO",
        code: "400",
    },
    KZ: {
        name: "Kazakhstan",
        id: "KZ",
        code: "398",
    },
    KE: {
        name: "Kenya",
        id: "KE",
        code: "404",
    },
    KI: {
        name: "Kiribati",
        id: "KI",
        code: "296",
    },
    KP: {
        name: "Korea, North",
        id: "KP",
        code: "408",
    },
    KR: {
        name: "Korea, South",
        id: "KR",
        code: "410",
        nativeLanguageName: "한국어",
        continueButtonText: "한국어로 계속하기",
    },
    KW: {
        name: "Kuwait",
        id: "KW",
        code: "414",
    },
    KG: {
        name: "Kyrgyzstan",
        id: "KG",
        code: "417",
    },
    LA: {
        name: "Lao People's Democratic Republic",
        id: "LA",
        code: "418",
    },
    LV: {
        name: "Latvia",
        id: "LV",
        code: "428",
    },
    LB: {
        name: "Lebanon",
        id: "LB",
        code: "422",
        nativeLanguageName: "العربية",
        continueButtonText: "استمر بالعربية",
    },
    LS: {
        name: "Lesotho",
        id: "LS",
        code: "426",
    },
    LR: {
        name: "Liberia",
        id: "LR",
        code: "430",
    },
    LY: {
        name: "Libya",
        id: "LY",
        code: "434",
    },
    LI: {
        name: "Liechtenstein",
        id: "LI",
        code: "438",
    },
    LT: {
        name: "Lithuania",
        id: "LT",
        code: "440",
    },
    LU: {
        name: "Luxembourg",
        id: "LU",
        code: "442",
    },
    MO: {
        name: "Macao",
        id: "MO",
        code: "446",
    },
    MG: {
        name: "Madagascar",
        id: "MG",
        code: "450",
    },
    MW: {
        name: "Malawi",
        id: "MW",
        code: "454",
    },
    MY: {
        name: "Malaysia",
        id: "MY",
        code: "458",
    },
    MV: {
        name: "Maldives",
        id: "MV",
        code: "462",
    },
    ML: {
        name: "Mali",
        id: "ML",
        code: "466",
    },
    MT: {
        name: "Malta",
        id: "MT",
        code: "470",
    },
    MH: {
        name: "Marshall Islands",
        id: "MH",
        code: "584",
    },
    MQ: {
        name: "Martinique",
        id: "MQ",
        code: "474",
    },
    MR: {
        name: "Mauritania",
        id: "MR",
        code: "478",
    },
    MU: {
        name: "Mauritius",
        id: "MU",
        code: "480",
    },
    YT: {
        name: "Mayotte",
        id: "YT",
        code: "175",
    },
    MX: {
        name: "Mexico",
        id: "MX",
        code: "484",
    },
    FM: {
        name: "Micronesia",
        id: "FM",
        code: "583",
    },
    MD: {
        name: "Moldova, Republic of",
        id: "MD",
        code: "498",
    },
    MC: {
        name: "Monaco",
        id: "MC",
        code: "492",
    },
    MN: {
        name: "Mongolia",
        id: "MN",
        code: "496",
    },
    ME: {
        name: "Montenegro",
        id: "ME",
        code: "499",
    },
    MS: {
        name: "Montserrat",
        id: "MS",
        code: "500",
    },
    MA: {
        name: "Morocco",
        id: "MA",
        code: "504",
    },
    MZ: {
        name: "Mozambique",
        id: "MZ",
        code: "508",
    },
    MM: {
        name: "Myanmar",
        id: "MM",
        code: "104",
    },
    NA: {
        name: "Namibia",
        id: "NA",
        code: "516",
    },
    NR: {
        name: "Nauru",
        id: "NR",
        code: "520",
    },
    NP: {
        name: "Nepal",
        id: "NP",
        code: "524",
    },
    NL: {
        name: "Netherlands",
        id: "NL",
        code: "528",
    },
    NC: {
        name: "New Caledonia",
        id: "NC",
        code: "540",
    },
    NZ: {
        name: "New Zealand",
        id: "NZ",
        code: "554",
    },
    NI: {
        name: "Nicaragua",
        id: "NI",
        code: "558",
    },
    NE: {
        name: "Niger",
        id: "NE",
        code: "562",
    },
    NG: {
        name: "Nigeria",
        id: "NG",
        code: "566",
    },
    NU: {
        name: "Niue",
        id: "NU",
        code: "570",
    },
    NF: {
        name: "Norfolk Island",
        id: "NF",
        code: "574",
    },
    MK: {
        name: "North Macedonia",
        id: "MK",
        code: "807",
    },
    MP: {
        name: "Northern Mariana Islands",
        id: "MP",
        code: "580",
    },
    NO: {
        name: "Norway",
        id: "NO",
        code: "578",
        nativeLanguageName: "Norsk",
        continueButtonText: "Fortsett på Norsk",
    },
    OM: {
        name: "Oman",
        id: "OM",
        code: "512",
    },
    PK: {
        name: "Pakistan",
        id: "PK",
        code: "586",
    },
    PW: {
        name: "Palau",
        id: "PW",
        code: "585",
    },
    PS: {
        name: "Palestine",
        id: "PS",
        code: "275",
    },
    PA: {
        name: "Panama",
        id: "PA",
        code: "591",
    },
    PG: {
        name: "Papua New Guinea",
        id: "PG",
        code: "598",
    },
    PY: {
        name: "Paraguay",
        id: "PY",
        code: "600",
    },
    PE: {
        name: "Peru",
        id: "PE",
        code: "604",
    },
    PH: {
        name: "Philippines",
        id: "PH",
        code: "608",
    },
    PL: {
        name: "Poland",
        id: "PL",
        code: "616",
        nativeLanguageName: "Polski",
        continueButtonText: "Kontynuuj po Polsku",
    },
    PT: {
        name: "Portugal",
        id: "PT",
        code: "620",
        nativeLanguageName: "Português",
        continueButtonText: "Continuar em Português",
    },
    PR: {
        name: "Puerto Rico",
        id: "PR",
        code: "630",
    },
    QA: {
        name: "Qatar",
        id: "QA",
        code: "634",
    },
    RE: {
        name: "Réunion",
        id: "RE",
        code: "638",
    },
    RO: {
        name: "Romania",
        id: "RO",
        code: "642",
        nativeLanguageName: "Română",
    },
    RU: {
        name: "Russian Federation",
        id: "RU",
        code: "643",
        nativeLanguageName: "Русский",
        continueButtonText: "Продолжить на Русском",
    },
    RW: {
        name: "Rwanda",
        id: "RW",
        code: "646",
    },
    BL: {
        name: "Saint Barthélemy",
        id: "BL",
        code: "652",
    },
    SH: {
        name: "Saint Helena, Ascension and Tristan da Cunha",
        id: "SH",
        code: "654",
    },
    KN: {
        name: "Saint Kitts and Nevis",
        id: "KN",
        code: "659",
    },
    LC: {
        name: "Saint Lucia",
        id: "LC",
        code: "662",
    },
    PM: {
        name: "Saint Pierre and Miquelon",
        id: "PM",
        code: "666",
    },
    VC: {
        name: "Saint Vincent and the Grenadines",
        id: "VC",
        code: "670",
    },
    WS: {
        name: "Samoa",
        id: "WS",
        code: "882",
    },
    SM: {
        name: "San Marino",
        id: "SM",
        code: "674",
    },
    ST: {
        name: "Sao Tome and Principe",
        id: "ST",
        code: "678",
    },
    SA: {
        name: "Saudi Arabia",
        id: "SA",
        code: "682",
    },
    SN: {
        name: "Senegal",
        id: "SN",
        code: "686",
    },
    RS: {
        name: "Serbia",
        id: "RS",
        code: "688",
    },
    SC: {
        name: "Seychelles",
        id: "SC",
        code: "690",
    },
    SL: {
        name: "Sierra Leone",
        id: "SL",
        code: "694",
    },
    SG: {
        name: "Singapore",
        id: "SG",
        code: "702",
    },
    SX: {
        name: "Sint Maarten (Dutch part)",
        id: "SX",
        code: "534",
    },
    SK: {
        name: "Slovakia",
        id: "SK",
        code: "703",
    },
    SI: {
        name: "Slovenia",
        id: "SI",
        code: "705",
    },
    SB: {
        name: "Solomon Islands",
        id: "SB",
        code: "090",
    },
    SO: {
        name: "Somalia",
        id: "SO",
        code: "706",
    },
    ZA: {
        name: "South Africa",
        id: "ZA",
        code: "710",
    },
    SS: {
        name: "South Sudan",
        id: "SS",
        code: "728",
    },
    ES: {
        name: "Spain",
        id: "ES",
        code: "724",
        nativeLanguageName: "Español",
        continueButtonText: "Continuar en Español",
    },
    LK: {
        name: "Sri Lanka",
        id: "LK",
        code: "144",
    },
    SD: {
        name: "Sudan",
        id: "SD",
        code: "729",
    },
    SR: {
        name: "Suriname",
        id: "SR",
        code: "740",
    },
    SE: {
        name: "Sweden",
        id: "SE",
        code: "752",
        nativeLanguageName: "Svenska",
        continueButtonText: "Fortsätt på Svenska",
    },
    CH: {
        name: "Switzerland",
        id: "CH",
        code: "756",
    },
    SY: {
        name: "Syrian Arab Republic",
        id: "SY",
        code: "760",
    },
    TW: {
        name: "Taiwan, Province of China",
        id: "TW",
        code: "158",
    },
    TJ: {
        name: "Tajikistan",
        id: "TJ",
        code: "762",
    },
    TZ: {
        name: "Tanzania",
        id: "TZ",
        code: "834",
    },
    TH: {
        name: "Thailand",
        id: "TH",
        code: "764",
    },
    TL: {
        name: "Timor-Leste",
        id: "TL",
        code: "626",
    },
    TG: {
        name: "Togo",
        id: "TG",
        code: "768",
    },
    TK: {
        name: "Tokelau",
        id: "TK",
        code: "772",
    },
    TO: {
        name: "Tonga",
        id: "TO",
        code: "776",
    },
    TT: {
        name: "Trinidad and Tobago",
        id: "TT",
        code: "780",
    },
    TN: {
        name: "Tunisia",
        id: "TN",
        code: "788",
    },
    TR: {
        name: "Turkey",
        id: "TR",
        code: "792",
        nativeLanguageName: "Türkçe",
        continueButtonText: "Türkçe olarak devam et",
    },
    TM: {
        name: "Turkmenistan",
        id: "TM",
        code: "795",
    },
    TC: {
        name: "Turks and Caicos Islands",
        id: "TC",
        code: "796",
    },
    TV: {
        name: "Tuvalu",
        id: "TV",
        code: "798",
    },
    UG: {
        name: "Uganda",
        id: "UG",
        code: "800",
    },
    UA: {
        name: "Ukraine",
        id: "UA",
        code: "804",
        nativeLanguageName: "Український",
        continueButtonText: "Продовжити українською",
    },
    AE: {
        name: "United Arab Emirates",
        id: "AE",
        code: "784",
    },
    GB: {
        name: "United Kingdom",
        id: "GB",
        code: "826",
        nativeLanguageName: "English",
        continueButtonText: "Continue in English",
    },
    US: {
        name: "USA",
        id: "US",
        code: "840",
    },
    UY: {
        name: "Uruguay",
        id: "UY",
        code: "858",
    },
    UZ: {
        name: "Uzbekistan",
        id: "UZ",
        code: "860",
    },
    VU: {
        name: "Vanuatu",
        id: "VU",
        code: "548",
    },
    VE: {
        name: "Venezuela",
        id: "VE",
        code: "862",
    },
    VN: {
        name: "Viet Nam",
        id: "VN",
        code: "704",
        nativeLanguageName: "Tiếng Việt",
    },
    VG: {
        name: "Virgin Islands (BVI)",
        id: "VG",
        code: "092",
    },
    VI: {
        name: "US Virgin Islands",
        id: "VI",
        code: "850",
    },
    WF: {
        name: "Wallis and Futuna",
        id: "WF",
        code: "876",
    },
    EH: {
        name: "Western Sahara",
        id: "EH",
        code: "732",
    },
    YE: {
        name: "Yemen",
        id: "YE",
        code: "887",
    },
    ZM: {
        name: "Zambia",
        id: "ZM",
        code: "894",
    },
    ZW: {
        name: "Zimbabwe",
        id: "ZW",
        code: "716",
    },
});

// not initialized until getCountryById is called because it might not be needed (and would just take up memory?)
let countries;

export function getCountryList() {
    if (!countries) {
        countries = Object.values(Country);
    }

    return countries;
}

export function getCountryById(countryId) {
    if (!countries) {
        countries = Object.values(Country);
    }

    return countries.find((country) => country.id === countryId);
}

export const countryISOMapping = {
    AFG: "AF",
    ALA: "AX",
    ALB: "AL",
    DZA: "DZ",
    ASM: "AS",
    AND: "AD",
    AGO: "AO",
    AIA: "AI",
    ATA: "AQ",
    ATG: "AG",
    ARG: "AR",
    ARM: "AM",
    ABW: "AW",
    AUS: "AU",
    AUT: "AT",
    AZE: "AZ",
    BHS: "BS",
    BHR: "BH",
    BGD: "BD",
    BRB: "BB",
    BLR: "BY",
    BEL: "BE",
    BLZ: "BZ",
    BEN: "BJ",
    BMU: "BM",
    BTN: "BT",
    BOL: "BO",
    BIH: "BA",
    BWA: "BW",
    BVT: "BV",
    BRA: "BR",
    VGB: "VG",
    IOT: "IO",
    BRN: "BN",
    BGR: "BG",
    BFA: "BF",
    BDI: "BI",
    KHM: "KH",
    CMR: "CM",
    CAN: "CA",
    CPV: "CV",
    CYM: "KY",
    CAF: "CF",
    TCD: "TD",
    CHL: "CL",
    CHN: "CN",
    HKG: "HK",
    MAC: "MO",
    CXR: "CX",
    CCK: "CC",
    COL: "CO",
    COM: "KM",
    COG: "CG",
    COD: "CD",
    COK: "CK",
    CRI: "CR",
    CIV: "CI",
    HRV: "HR",
    CUB: "CU",
    CYP: "CY",
    CZE: "CZ",
    DNK: "DK",
    DJI: "DJ",
    DMA: "DM",
    DOM: "DO",
    ECU: "EC",
    EGY: "EG",
    ENG: "GB",
    SLV: "SV",
    GNQ: "GQ",
    ERI: "ER",
    EST: "EE",
    ETH: "ET",
    FLK: "FK",
    FRO: "FO",
    FJI: "FJ",
    FIN: "FI",
    FRA: "FR",
    GUF: "GF",
    PYF: "PF",
    ATF: "TF",
    GAB: "GA",
    GMB: "GM",
    GEO: "GE",
    DEU: "DE",
    GHA: "GH",
    GIB: "GI",
    GRC: "GR",
    GRL: "GL",
    GRD: "GD",
    GLP: "GP",
    GUM: "GU",
    GTM: "GT",
    GGY: "GG",
    GIN: "GN",
    GNB: "GW",
    GUY: "GY",
    HTI: "HT",
    HMD: "HM",
    VAT: "VA",
    HND: "HN",
    HUN: "HU",
    ISL: "IS",
    IND: "IN",
    IDN: "ID",
    IRN: "IR",
    IRQ: "IQ",
    IRL: "IE",
    IMN: "IM",
    ISR: "IL",
    ITA: "IT",
    JAM: "JM",
    JPN: "JP",
    JEY: "JE",
    JOR: "JO",
    KAZ: "KZ",
    KEN: "KE",
    KIR: "KI",
    PRK: "KP",
    KOR: "KR",
    KWT: "KW",
    KGZ: "KG",
    LAO: "LA",
    LVA: "LV",
    LBN: "LB",
    LSO: "LS",
    LBR: "LR",
    LBY: "LY",
    LIE: "LI",
    LTU: "LT",
    LUX: "LU",
    MKD: "MK",
    MDG: "MG",
    MWI: "MW",
    MYS: "MY",
    MDV: "MV",
    MLI: "ML",
    MLT: "MT",
    MHL: "MH",
    MTQ: "MQ",
    MRT: "MR",
    MUS: "MU",
    MYT: "YT",
    MEX: "MX",
    FSM: "FM",
    MDA: "MD",
    MCO: "MC",
    MNG: "MN",
    MNE: "ME",
    MSR: "MS",
    MAR: "MA",
    MOZ: "MZ",
    MMR: "MM",
    NAM: "NA",
    NRU: "NR",
    NPL: "NP",
    NLD: "NL",
    ANT: "AN",
    NCL: "NC",
    NZL: "NZ",
    NIC: "NI",
    NER: "NE",
    NGA: "NG",
    NIU: "NU",
    NFK: "NF",
    MNP: "MP",
    NOR: "NO",
    OMN: "OM",
    PAK: "PK",
    PLW: "PW",
    PSE: "PS",
    PAN: "PA",
    PNG: "PG",
    PRY: "PY",
    PER: "PE",
    PHL: "PH",
    PCN: "PN",
    POL: "PL",
    PRT: "PT",
    PRI: "PR",
    QAT: "QA",
    REU: "RE",
    ROU: "RO",
    RUS: "RU",
    RWA: "RW",
    BLM: "BL",
    SHN: "SH",
    KNA: "KN",
    LCA: "LC",
    MAF: "MF",
    SPM: "PM",
    VCT: "VC",
    WSM: "WS",
    SMR: "SM",
    STP: "ST",
    SAU: "SA",
    SEN: "SN",
    SRB: "RS",
    SYC: "SC",
    SLE: "SL",
    SGP: "SG",
    SVK: "SK",
    SVN: "SI",
    SLB: "SB",
    SOM: "SO",
    ZAF: "ZA",
    SGS: "GS",
    SSD: "SS",
    ESP: "ES",
    LKA: "LK",
    SDN: "SD",
    SUR: "SR",
    SJM: "SJ",
    SWZ: "SZ",
    SWE: "SE",
    CHE: "CH",
    SYR: "SY",
    TWN: "TW",
    TJK: "TJ",
    TZA: "TZ",
    THA: "TH",
    TLS: "TL",
    TGO: "TG",
    TKL: "TK",
    TON: "TO",
    TTO: "TT",
    TUN: "TN",
    TUR: "TR",
    TKM: "TM",
    TCA: "TC",
    TUV: "TV",
    UGA: "UG",
    UKR: "UA",
    ARE: "AE",
    GBR: "GB",
    USA: "US",
    UMI: "UM",
    URY: "UY",
    UZB: "UZ",
    VUT: "VU",
    VEN: "VE",
    VNM: "VN",
    VIR: "VI",
    WLF: "WF",
    ESH: "EH",
    YEM: "YE",
    ZMB: "ZM",
    ZWE: "ZW",
    XKX: "XK",
};

// When adding/removing a language, make sure to update the object at the API endpoint:
// casino/game/gameurl
export const countryLocales = {
    en: Country.GB,
    de: Country.DE,
    es: Country.ES,
    ja: Country.JP,
    pl: Country.PL,
    ro: Country.RO,
    ru: Country.RU,
    tr: Country.TR,
    id: Country.ID,
    ko: Country.KR,
    fr: Country.FR,
    nl: Country.NL,
};

export default Country;

<template>
    <div>
        <MapBanner :secondary="secondary" v-if="hasBanner" @clicked="handleClick" :text="characterText" :size="bannerSize"
            :position="{ x: position.x - (secondary ? 48 : 64), y: position.y - (secondary ? 64 : 48) }"></MapBanner>
        <div @click="handleClick" :style="characterStyle" class="map-character" :class="[!flat ? 'animated' : '']">
        </div>
    </div>
</template>

<script>
import { mapActions } from "vuex";
export default {
    name: "Character",

    components: {},

    props: {
        character: {
            type: String,
            default: "farmer",
        },
        position: {
            type: Object,
            default: () => { },
        },
        hasBanner: {
            type: Boolean,
            default: true,
        },
        secondary: {
            type: Boolean,
            default: false,
        },
        flat: {
            type: Boolean,
            default: false,
        },
        size: {
            type: Number,
            default: 2,
        },
        bannerSize: {
            type: String,
            default: "small",
        }
    },

    data() {
        return {};
    },

    methods: {
        ...mapActions("site/settings", ["playSound"]),

        handleClick() {
            this.$emit("clicked");
            this.playSound({
                track: "click.wav",
                volume: 0.1,
            });
        },
    },

    computed: {
        characterStyle() {
            const character = require(`../../assets/characters/${this.character}.png`);
            const style = {
                position: "absolute",
                left: `${this.position?.x || 0}px`,
                top: `${this.position?.y || 0}px`,
                background: `url('${character}') 0 0`,
                width: `${this.size}rem`,
                height: `${this.size}rem`,
            };


            return style;
        },

        characterText() {
            const text = this.$t(`npcs.${this.character}`);
            return text;
        },
    },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.map-character {
    transform: scale(2);
    cursor: pointer;
}

.animated {
    animation: float 3s steps(1, end) infinite;
}

@keyframes float {
    0% {
        background-position-x: 0;
    }

    50% {
        background-position-x: -32px;
    }

    75% {
        background-position-x: 0;
    }

    100% {
        background-position-x: 0;
    }
}
</style>

<template>
    <div class="game-frame" ref="game">
        <div class="game-frame-marketplace">
            <img style="width: 100%; height: 100%" src="../../../public/images/tavern.gif" />

            <MapBanner @clicked="openCraftingStation()" :text="'Potion Seller'" :position="{ x: 205, y: 60 }">
            </MapBanner>
        </div>
    </div>
</template>

<script>
import { mapState, mapActions } from "vuex";

export default {
    name: "TavernScreen",

    components: {},

    props: {},

    data() {
        return {};
    },

    mounted() {
        const panzoomContainer = this.$refs.game;
        this.initializePanzoom(panzoomContainer);
        this.resetZoom();
    },

    beforeDestroy() {
        this.destroyPanzoom();
        this.resetZoom();
    },

    methods: {
        ...mapActions("site", ["setCurrentModal"]),
        ...mapActions("panzoom", ["initializePanzoom", "destroyPanzoom", "zoomTo", "resetZoom"]),
        ...mapActions("site/npcDialogue", ["setSelectedNPC"]),

        openCraftingStation() {
            this.setCurrentModal("craftingStation");
            this.setSelectedNPC({ selectedNPC: "potions", show: true });
        },
    },

    computed: {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.game-frame {
    min-width: 960px;
    min-height: 480px;
    width: 100%;
    height: 100%;

    &-marketplace {
        width: 960px;
        height: 480px;
        border-radius: 0.25rem;

        border-image: url("../../../public/images/buttonBg.png") 6 fill repeat;
        border-image-width: 1rem;
        border-image-outset: 0.5rem;

        position: absolute;
        top: calc(50% - 240px);
        left: calc(50% - 480px);
    }
}
</style>

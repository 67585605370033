const { axios } = require("@/libs/axios");

export async function getSeedVendorRevealData({ blockNumber, user, seedType }) {

    const result = await axios.post("/seedVendorReveal", {
        blockNumber,
        user,
        seedType,
    });


    const { blockHash, signature } = result.data;

    return { blockHash, signature };
}

import { ethers } from "ethers";
import { createNotification } from "@/libs/storeHelper";

const state = () => ({
    wageContract: null,

    wageTillNextEpoch: 0,
    unlockRate: 0.01,
});

const mutations = {
    initializeContract(state, contract) {
        state.wageContract = contract;
    },

    setTimeTillNextEpoch(state, time) {
        state.wageTillNextEpoch = Number(time) * 1000;
    },
};

const actions = {
    boot({ commit, rootState }, { contractAddress }) {
        const abi = require("@/plugins/artefacts/sage.json");

        const { signer, defaultSigner } = rootState.site;
        const contract = new ethers.Contract(contractAddress, abi, signer || defaultSigner);

        commit("initializeContract", contract);
    },

    async initializeWageData({ state, commit }) {
        const time = await state.wageContract.timeTillNextEpoch();
        commit("setTimeTillNextEpoch", Number(time));
    },

    async claimWage({ state, dispatch, rootState }) {
        const contract = state.wageContract;
        const { baseGwei } = rootState.site.settings;

        const tx = await contract.claim({
            //gasPrice: baseGwei,
        });

        createNotification({
            dispatch,
            message: `Tx: Claiming wage!`,
            type: "warn",
            id: tx.hash,
            showSpinner: true,
        });

        await tx.wait(1);

        dispatch("user/getProfile", {}, { root: true });
        dispatch("site/notifications/remove", tx.hash, { root: true });

        createNotification({
            dispatch,
            message: `Wage claimed!`,
            duration: 5000,
        });
    },
};

const getters = {};

export default {
    state,
    mutations,
    getters,
    actions,
    namespaced: true,
};

<template>
    <div class="avatar">
        <div class="avatar-container">
            <div class="avatar-container-wrapper">
                <div @click="tryOpenPfpModal" class="avatar-container-pfp">
                    <img :class="[hasPfp ? '' : 'empty']" :src="pfpUrl" />
                </div>
                <div @click="tryOpenWalletModal" v-if="isLoggedIn" class="avatar-container-text">
                    <p class="small-text" style="font-size: 2rem">
                        {{ name }}
                    </p>
                    <WalletIcon class="wallet-icon"></WalletIcon>
                </div>
                <div v-else class="avatar-container-text">
                    <p class="small-text" style="font-size: 2rem">Not Connected</p>
                </div>

                <template>
                    <div class="avatar-container-balances">
                        <SidebarBalance title="Locked Yield Balance" :text="`${tokenBalances.lockedYield}`" :icon="'lockedToken'"></SidebarBalance>
                        <SidebarBalance title="Yield Balance" :text="`${tokenBalances.yield}`" :value="'$Y'"></SidebarBalance>
                        <RightMenu class="right-menu-container"></RightMenu>
                    </div>

                    <div class="avatar-container-buttons">
                        <RightMenu></RightMenu>
                    </div>
                </template>
            </div>
        </div>
    </div>
</template>

<script>
import RightMenu from "@/components/sidebar/RightMenu.vue";
import SidebarBalance from "@/components/sidebar/SidebarBalance.vue";
import WalletIcon from "@/components/icons/WalletIcon.vue";

import { mapGetters, mapState, mapActions } from "vuex";

export default {
    name: "SideMenu",

    components: { SidebarBalance, RightMenu, WalletIcon },

    props: {},

    data() {
        return {};
    },

    methods: {
        ...mapActions("site", ["setCurrentModal"]),

        tryOpenWalletModal() {
            this.setCurrentModal("wallet");
        },

        tryOpenPfpModal() {
            this.setCurrentModal("pfp");
        },
    },

    computed: {
        ...mapState("user", ["name", "farmLevel", "tokenBalances", "account", "profilePictureId", "pfpTokenIds"]),
        ...mapGetters("user", ["isLoggedIn"]),

        truncatedAccount() {
            if (!this.account) return "";

            return `${this.account.slice(0, 4)}...${this.account.slice(-4)}`;
        },

        pfpUrl() {
            if (this.profilePictureId === 0 && this.pfpTokenIds.find((x) => x === 0) === undefined) {
                return "/images/avatar-left-placeholder.png";
            }
            return `https://datavalleys.info/api/content/images/farmers/${this.profilePictureId}`;
        },

        hasPfp() {
            return this.profilePictureId !== 0 || this.pfpTokenIds.find((x) => x === 0) !== undefined;
        },
    },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.wallet-icon {
    width: 1.5rem;
    height: 1.5rem;

    cursor: pointer;
    pointer-events: all;
    &:hover {
        transform: scale(1.1);

        ::v-deep svg {
            path {
                fill: #ffd664 !important;
            }
        }
    }

    z-index: 100;

    ::v-deep svg {
        width: 1.5rem;
        height: 1.5rem;
        path {
            fill: #ffeaaf !important;
        }
    }
}

.avatar {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;

    &-container {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 0.5rem;
        flex-direction: column;

        background: url("../../../public/images/avatar-none.png");
        background-size: 100% 100%;
        width: 512px;
        height: 170px;

        &-wrapper {
            width: 100%;
            height: 100%;
            position: relative;
        }

        &-pfp {
            position: absolute;
            left: 5%;
            top: 19%;
            width: 7rem;
            height: 7rem;
            cursor: pointer;
            pointer-events: all;

            border-image: url("../../../public/images/itemBg.png") 6 fill repeat;
            border-image-width: 0.5rem;

            img.empty {
                image-rendering: pixelated;
                width: 95%;
                height: 95%;
            }

            img {
                image-rendering: pixelated;
                width: 100%;
                height: 100%;
            }
        }

        &-text {
            position: absolute;
            left: 40%;
            width: 50%;
            top: 36.5%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            height: 3rem;
        }

        &-balances {
            position: absolute;
            left: 37.5%;
            width: 55%;
            bottom: -0.25rem;
            display: flex;
            justify-content: flex-end;
            align-items: center;
            height: 3rem;
            gap: 1.5rem;

            pointer-events: all;
        }

        &-buttons {
            position: absolute;
            left: 70.5%;
            width: 55.5%;
            top: 37.5%;
            display: flex;
            justify-content: flex-end;
            align-items: center;
            height: 3rem;
            gap: 1.5rem;

            pointer-events: all;

            @media screen and (max-width: 512px) {
                display: none;
            }
        }

        &-icons {
            position: absolute;
            left: 35%;
            bottom: 0.25rem;
        }
    }

    &-info {
        width: 20rem;
        height: 6rem;
        background-color: #303a42;
        border: 2px solid #303a42;
        backdrop-filter: blur(2px);
        border-radius: 0.5rem;

        padding: 0.5rem;

        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        gap: 0.5rem;
    }
}

.right-menu-container {
    display: none;

    @media (max-width: 512px) {
        display: flex;
    }
}
</style>

const { axios } = require("@/libs/axios");

import { StandardMerkleTree } from "@openzeppelin/merkle-tree";

const whitelistData = require("@/plugins/whitelistData.json");

const tree = StandardMerkleTree.of(whitelistData, ["address", "uint256"]);

export function getMerkleProof(address) {
    const leafData = whitelistData.find((c) => c[0].toLowerCase() === address.toLowerCase());
    const leafIndex = whitelistData.indexOf(leafData);

    if (!leafData) {
        return null;
    }


    return {
        amount: leafData[1],
        proof: tree.getProof(leafIndex),
    };
}

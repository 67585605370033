<template>
    <div class="game-frame" ref="game">
        <div class="game-frame-marketplace">
            <img style="width: 100%; height: 100%" src="../../../public/images/marketplace.gif" />

            <MapBanner @clicked="openSeedShop()" :text="'vendor'" :position="{ x: 564, y: 290 }">
            </MapBanner>

            <MapBanner @clicked="openSage()" :text="'sage'" :position="{ x: 444, y: -10 }"></MapBanner>

            <MapBanner @clicked="openDex()" :text="'dex'" :position="{ x: 110, y: 230 }">
            </MapBanner>

            <MapBanner @clicked="openBanker()" :text="'banker'" :position="{ x: 780, y: 120 }"></MapBanner>

            <MapBanner @clicked="openMarketplaceHelper()" :secondary="true" :text="'Helper'"
                :character="'helper'" :position="{ x: 360, y: 330 }"></MapBanner>

            <MapBanner @clicked="openMarketplace()" :text="'Market'" :position="{ x: 280, y: 270 }"></MapBanner>

            <Character v-if="false" @clicked="setCurrentModal('lottery')" :character="'lottery'"
                :position="{ x: 200, y: 122 }"></Character>

            <MapBanner @clicked="setCurrentModal('leaderboard')" :text="$t('menu.leaderboard')"
                :position="{ x: 630, y: 120 }"></MapBanner>
        </div>
    </div>
</template>

<script>
import { mapState, mapActions } from "vuex";

export default {
    name: "MarketplaceScreen",

    components: {},

    props: {},

    data() {
        return {};
    },

    mounted() {
        const panzoomContainer = this.$refs.game;
        this.initializePanzoom(panzoomContainer);
        this.resetZoom();
    },

    beforeDestroy() {
        this.destroyPanzoom();
        this.resetZoom();
    },

    methods: {
        ...mapActions("site", ["setCurrentModal"]),
        ...mapActions("panzoom", ["initializePanzoom", "destroyPanzoom", "zoomTo", "resetZoom"]),
        ...mapActions("site/npcDialogue", ["setSelectedNPC"]),

        goToKickstarter() {
            window.open("https://lge.cryptovalleys.io/", "_blank");
        },

        openMarketplace() {
            this.setCurrentModal('marketplace');
            this.setSelectedNPC({ selectedNPC: 'marketplace', show: true });
        },
        
        openMarketplaceHelper() {
            this.setCurrentModal('marketplaceHelper');
            this.setSelectedNPC({ selectedNPC: 'marketplaceHelper', show: true });
        },

        openSeedShop() {
            this.setCurrentModal('seedShop');
            this.setSelectedNPC({ selectedNPC: 'seedShop', show: true })
        },

        openSage() {
            this.setCurrentModal('sage');
            this.setSelectedNPC({ selectedNPC: 'sage', show: true });
        },

        openDex() {
            this.setCurrentModal('dexInterface');
            this.setSelectedNPC({ selectedNPC: 'dex', show: true });
        },

        openBanker() {
            this.setCurrentModal('banker');
            this.setSelectedNPC({ selectedNPC: 'banker', show: true });
        }
    },

    computed: {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.game-frame {
    min-width: 960px;
    min-height: 480px;
    width: 100%;
    height: 100%;

    &-marketplace {
        width: 960px;
        height: 480px;
        border-radius: 0.25rem;

        border-image: url("../../../public/images/buttonBg.png") 6 fill repeat;
        border-image-width: 1rem;
        border-image-outset: 0.5rem;

        position: absolute;
        top: calc(50% - 240px);
        left: calc(50% - 480px);
    }
}
</style>

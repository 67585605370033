export function createNotification({ dispatch, message, type, id, duration = 30000, showSpinner = false }) {
    /*dispatch(
        "site/notifications/create",
        {
            type: type || "success",
            message,
            duration,
            id: id || `${Date.now()}-${Math.random()}`,
        },
        { root: true },
    );
*/
    if (showSpinner) {
        dispatch("site/showLoadingTxSpinner", { message }, { root: true });
    } else {
        dispatch(
            "site/notifications/create",
            {
                type: type || "success",
                message,
                duration,
                id: id || `${Date.now()}-${Math.random()}`,
            },
            { root: true },
        );
    }
}

export function finalizeNotification({ dispatch, id, message }) {
    dispatch("site/notifications/remove", id, { root: true });
    createNotification({
        dispatch,
        message: message,
        duration: 5000,
    });
}

export function getTxErrorMessage(dispatch, error) {
    console.error(error);
    const pattern = /"([^"]*)"/;
    /** @type {string} */
    const errorString = error?.toString() || error?.message || "unknown error";
    const errorMessage = errorString.match(pattern)?.[1] || errorString;

    dispatch(
        "site/notifications/create",
        {
            type: "error",
            message: errorMessage,
            duration: 5000,
            id: `${Date.now()}-${Math.random()}`,
        },
        { root: true },
    );
}

export const ZERO_ADDRESS = "0x0000000000000000000000000000000000000000";
export const isDevelopmentMode = window.location.href.includes("localhost") || window.location.href.includes("demo");

export const CHAIN_IDS = {
    mainnet: 81457,
    testnet: 168587773,
};

//const availableRpcs = [process.env.VUE_APP_RPC_URL];
//const pickedRpc = availableRpcs[Math.floor(Math.random() * availableRpcs.length)];

export const RPCS = {
    mainnet: "https://blastl2-mainnet.public.blastapi.io",
    testnet: "https://dawn-restless-pool.blast-sepolia.quiknode.pro/d8c1c09651a55fff6b365b39fdc278d4531ed988/",
};

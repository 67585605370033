const state = () => ({
    selectedNPC: undefined,
    showNPCDialogue: false,

    maxDialogueDuration: 180 * 1000,
});

const mutations = {
    initializeContract(state, contract) {
        state.adventurersContract = contract;
    },

    setSelectedNPC(state, npc) {
        state.selectedNPC = npc;
    },

    setShowNPCDialogue(state, show) {
        state.showNPCDialogue = show;
    },

    closeDialogue(state) {
        state.showNPCDialogue = false;
        state.selectedNPC = undefined;
    },
};

const actions = {
    closeDialogue({ commit }) {
        commit("closeDialogue");
    },

    setSelectedNPC({ commit }, { selectedNPC, show = false }) {
        commit("setSelectedNPC", selectedNPC);
        window.innerWidth < 768 ? commit("setShowNPCDialogue", false) : commit("setShowNPCDialogue", show);
    },
};

const getters = {};

export default {
    state,
    mutations,
    getters,
    actions,
    namespaced: true,
};

import farmModule from "./farm";
import yieldModule from "./yield";
import profileModule from "./profile";
import relayerModule from "./relayer";
import seedVendorModule from "./seedVendor";
import cropsModule from "./crops";
import faucetModule from "./faucet";
import pointsModule from "./points";
import itemsModule from "./items";
import badgeModule from "./_template";
import itemController from "./itemController";
import sageModule from "./sage";
import dataAggregatorModule from "./dataAggregator";
import tokenReaderModule from "./tokenReader";
import bankModule from "./bank";
import wageModule from "./wage";
import dailyChestModule from "./dailyChest";
import leaderboardModule from "./leaderboard";
import pfpControllerModule from "./pfpController";
import pfpLensModule from "./pfpLens";
import pfpModule from "./pfp";
import marketplaceModule from "./marketplace";
import lotteryModule from "./lottery";
import craftingStationModule from "./craftingStation";
import adventurersModule from "./adventurers";
import fishingModule from "./fishing";

import { CHAIN_IDS, createNotification, getTxErrorMessage, isDevelopmentMode } from "@/libs/storeHelper";

const state = {
    initialized: false,
};

const mutations = {};

import { ethers } from "ethers";

const actions = {
    async boot({ dispatch }) {
        await dispatch("contracts/relayer/boot", {}, { root: true });
    },

    async callContractFunction({ rootState, dispatch, commit }, { contract, functionName, params }) {
        const { signer } = rootState.site;
        const { account } = rootState.user;
        const chainId = Number(await window.ethereum.request({ method: "eth_chainId" }));

        window.ethereum.on("message", (message) => {
            console.log("GOT MESSAGE: ", message);
        });

        if (isDevelopmentMode && chainId !== CHAIN_IDS.testnet) {
            createNotification({
                dispatch,
                type: "error",
                message: "Please connect to Blast Sepolia Testnet!",
                id: "invalid-chain",
                duration: 5000,
            });

            await dispatch("site/requestChangeNetwork", {}, { root: true });
            return;
        } else if (!isDevelopmentMode && chainId !== CHAIN_IDS.mainnet) {
            createNotification({
                dispatch,
                type: "error",
                message: "Please connect to Blast Mainnet!",
                id: "invalid-chain",
                duration: 5000,
            });

            await dispatch("site/requestChangeNetwork", {}, { root: true });
            return;
        }

        if (!contract || !functionName) return;
        if (!signer) {
            await dispatch("site/connectWallet", { forceSigner: true }, { root: true });
        }

        dispatch("site/setShowSpinner", { showSpinner: true, message: "Confirm transaction...", type: "txConfirm" }, { root: true });
        dispatch("site/setButtonLoading", { buttonId: functionName, loading: true }, { root: true });

        let concluded = false;

        /* setTimeout(() => {
            if (concluded) return;

            dispatch("site/setShowSpinner", { showSpinner: false, message: "", type: "txWait" }, { root: true });
            dispatch("site/setButtonLoading", { buttonId: functionName, loading: false }, { root: true });
        }, 20 * 1000);*/

        this.dispatch(`contracts/${contract}/${functionName}`, params, {
            root: true,
        })
            .then(() => {})
            .catch((error) => {
                dispatch("site/notifications/clearAll", {}, { root: true });
                dispatch("site/setShowSpinner", { showSpinner: false, message: "Encountered an error or tx was declined!", type: "txWait" }, { root: true });
                dispatch("site/setButtonLoading", { buttonId: functionName, loading: false }, { root: true });
                getTxErrorMessage(dispatch, error);
            })
            .finally(() => {
                dispatch("site/setShowSpinner", { showSpinner: false, message: "Transaction confirmed", type: "txWait" }, { root: true });
                dispatch("site/setButtonLoading", { buttonId: functionName, loading: false }, { root: true });

                concluded = true;
            });
    },
};

const getters = {};

const contractModule = {
    state: () => state,
    mutations,
    getters,
    actions,
    modules: {
        farm: farmModule,
        yield: yieldModule,
        profile: profileModule,
        relayer: relayerModule,
        seedVendor: seedVendorModule,
        crops: cropsModule,
        faucet: faucetModule,
        points: pointsModule,
        items: itemsModule,
        itemController: itemController,
        dataAggregator: dataAggregatorModule,
        badge: badgeModule,
        tokenReader: tokenReaderModule,
        bank: bankModule,
        sage: sageModule,
        wage: wageModule,
        dailyChest: dailyChestModule,
        leaderboard: leaderboardModule,
        pfpController: pfpControllerModule,
        pfpLens: pfpLensModule,
        pfp: pfpModule,
        marketplace: marketplaceModule,
        lottery: lotteryModule,
        craftingStation: craftingStationModule,
        adventurers: adventurersModule,
        fishing: fishingModule
    },
    namespaced: true,
};

export default contractModule;

<template>
    <div class="checkbox-wrapper">
        <p class="small-text">{{ label }}</p>
        <div class="base-input">
            <input v-if="!dummy" type="checkbox" v-model="statement" />
            <input v-else @click="$emit('clicked')" type="checkbox" :checked="dummySelected" />
        </div>
    </div>
</template>

<script>
import { mapActions } from "vuex";
export default {
    name: "BaseInput",

    components: {},

    props: {
        label: {
            type: String,
            default: "",
        },
        value: {
            type: Boolean,
            default: true,
        },

        dummy: {
            type: Boolean,
            default: false,
        },
        dummySelected: {
            type: Boolean,
            default: false,
        },
    },

    data() {
        return {};
    },

    methods: {
        ...mapActions("site/settings", ["playSound"]),
    },

    computed: {
        statement: {
            get() {
                return this.value;
            },
            set(newValue) {
                this.$emit("input", newValue);
            },
        },
    },

    watch: {
        statement() {
            this.playSound({
                track: "click.wav",
                volume: 0.1,
            });
        },
    },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.checkbox-wrapper {
    display: flex;
    width: 100%;

    align-items: center;
    justify-content: space-between;
}
.base-input {
    min-width: 2rem;
    min-height: 2rem;

    transition: all 0.1s ease-in;
    cursor: pointer;

    user-select: none;

    input[type="checkbox" i] {
        width: 100%;
        height: 100%;
        min-height: 2rem;
        appearance: none;
        background: url("../../assets/gui/checkbox_empty.png") no-repeat;
        background-size: 100% 100%;
        cursor: pointer;
        transition: all 0.1s ease-in;
        border: none;
        outline: none;

        &:checked {
            background: url("../../assets/gui/checkbox_checked.png") no-repeat;
            background-size: 100% 100%;
        }
    }
}
</style>

<template>
    <div class="item-display">
        <img :src="itemImage" class="item-display-icon" />
        <p v-if="displayCount" class="item-display-count">x{{ count }}</p>
    </div>
</template>

<script>
import { mapState } from "vuex";

export default {
    name: "ItemDisplay",

    components: {},

    props: {
        itemType: {
            type: String,
            default: "tomato",
        },
        count: {
            type: Number,
            default: 0,
        },
        displayCount: {
            type: Boolean,
            default: false,
        },
    },

    data() {
        return {};
    },

    methods: {},

    computed: {
        ...mapState("content", ["allItemsData"]),

        itemImage() {
            const itemData = this.allItemsData.find((c) => c.name === this.itemType);
            const image = require(`@/assets/items/${itemData.tokenId}.png`);

            return image;
        },
    },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.item-display {
    display: flex;
    justify-content: center;
    flex-direction: column;
    gap: 0.25rem;
    align-items: center;

    background: url("../../../public/images/roundContainer.png");
    background-size: 100% 100%;

    position: relative;

    &:hover {
        filter: brightness(1.1);
    }

    cursor: pointer;

    &-count {
        position: absolute;
        top: 1.75rem;
        left: 1.75rem;
    }

    img {
        height: 50%;
        width: 50%;
        image-rendering: pixelated;
        transform-origin: 32px 64px;
    }
}

@keyframes jiggle {
    0% {
        transform: scale(2) rotate(0deg);
    }

    50% {
        transform: scale(2) rotate(5deg);
    }

    100% {
        transform: scale(2) rotate(0deg);
    }
}
</style>

<template>
    <div class="game-frame" ref="game">
        <div class="game-frame-overworld">
            <img style="width: 100%; height: 100%" src="../../../public/images/overworld.gif" />

            <MapBanner
                :position="{
                    x: 2165,
                    y: 1050,
                }"
                :size="'big'"
                :text="$t('menu.farm')"
                @clicked="goToRoute('farm')"
            ></MapBanner>

            <MapBanner
                :position="{
                    x: 1300,
                    y: 1150,
                }"
                :size="'big'"
                :text="$t('menu.tavern')"
                @clicked="goToRoute('tavern')"
            ></MapBanner>

            <MapBanner
                :position="{
                    x: 2480,
                    y: 950,
                }"
                :size="'big'"
                :text="$t('menu.house')"
                @clicked="goToRoute('house')"
            ></MapBanner>

            <MapBanner
                :position="{
                    x: 380,
                    y: 460,
                }"
                :size="'big'"
                :text="$t('menu.hermit')"
                @clicked="goToRoute('hermit-cave')"
            ></MapBanner>

            <MapBanner
                :position="{
                    x: 1650,
                    y: 1370,
                }"
                :text="$t('menu.marketplace')"
                :size="'big'"
                @clicked="goToRoute('marketplace')"
            ></MapBanner>
        </div>

        <div class="cloud-container">
            <div v-for="cloud in clouds" :key="cloud.id" :style="getCloudShadowStyle(cloud.id)">
                <img :src="getCloudImage(cloud.id)" />
            </div>
        </div>

        <div class="cloud-container" :class="[scaleLevel < 0.75 ? 'fade-in' : 'fade-out']">
            <div v-for="cloud in clouds" :key="cloud.id" :style="getCloudStyle(cloud.id)">
                <img :src="getCloudImage(cloud.id)" />
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions, mapState } from "vuex";

export default {
    name: "OverworldScreen",

    components: {},

    props: {},

    data() {
        return {
            clouds: [],
            cloudImages: [],
            cloudSin: 0,
            cloudInterval: 0,
        };
    },

    mounted() {
        const panzoomContainer = this.$refs.game;
        this.initializePanzoom(panzoomContainer);

        setTimeout(() => {
            this.zoomTo({
                x: -1000,
                y: -1000,
                scale: 0.75,
            });
        });

        for (let i = 0; i < 50; i++) {
            const scale = Math.random() * 5 + 2;
            const speed = 0.5 / scale;

            this.clouds.push({
                id: i,
                x: Math.floor(Math.random() * 4600) - 500,
                y: Math.floor(Math.random() * 3100) - 500,

                speed: speed,
                scale: scale,
                opacity: Math.random() * 0.3 + 0.7,
                cloudType: 1 + Math.floor(Math.random() * 3),
            });
        }

        for (let i = 1; i <= 4; i++) {
            const cloud = require("@/assets/images/cloud_" + i + ".png");
            this.cloudImages.push(cloud);
        }

        this.cloudInterval = setInterval(() => {
            for (let i = 0; i < this.clouds.length; i++) {
                const cloud = this.clouds[i];

                cloud.x += cloud.speed;

                if (cloud.x > 4500) {
                    cloud.x = -200;
                    cloud.y = Math.floor(Math.random() * 2640);
                }
            }
        }, 16.6);
    },

    beforeDestroy() {
        this.destroyPanzoom();
    },

    methods: {
        ...mapActions("panzoom", ["initializePanzoom", "destroyPanzoom", "zoomTo", "resetZoom"]),

        getCloudStyle(index) {
            const cloud = this.clouds[index];

            return {
                position: "absolute",
                left: `${cloud.x}px`,
                top: `${cloud.y}px`,
                transform: `scale(${cloud.scale})`,
                opacity: cloud.opacity,
                zIndex: 1000,
            };
        },

        getCloudShadowStyle(index) {
            const cloud = this.clouds[index];

            return {
                position: "absolute",
                left: `${cloud.x + 10}px`,
                top: `${cloud.y + 500}px`,
                transform: `scale(${cloud.scale})`,
                opacity: 0.1,
                zIndex: 100,
                filter: "brightness(0%)",
            };
        },

        getCloudImage(index) {
            return this.cloudImages[this.clouds[index].cloudType];
        },

        goToRoute(route) {
            this.$router.push({ name: route });
        },

        tryOpenTavern() {
            this.$store.dispatch("site/notifications/create", {
                type: "info",
                message: this.$t("notice.tavernNotAvailable"),
                duration: 5000,
                id: "tavern-not-available",
            });
        },
    },

    computed: {
        ...mapState("panzoom", ["scaleLevel"]),
    },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.game-frame {
    width: 4128px;
    height: 2640px;
    overflow: hidden;

    border-image: url("../../../public/images/itemBg.png") 6 fill repeat;
    border-image-width: 2rem;
    border-image-outset: 0.5rem;

    &-overworld {
        width: 4128px;
        height: 2640px;

        z-index: 1;

        position: absolute;
        top: 0;
        left: 0;
    }
}

.cloud-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    pointer-events: none;
    z-index: 1000;
}

@keyframes fade-in {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@keyframes fade-out {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}

.fade-in {
    animation: fade-in 1s forwards;
}

.fade-out {
    animation: fade-out 1s forwards;
}
</style>

import { ethers } from "ethers";
import { RPCS, createNotification, finalizeNotification, getTxErrorMessage } from "@/libs/storeHelper";

import { getSeedVendorRevealData } from "@/api/seedVendor";

const state = () => ({
    fishingContract: null,
    commitData: undefined,

    itemFished: null,
    itemsFished: [],

    showFishingResultModal: false,
});

const mutations = {
    initializeContract(state, contract) {
        state.fishingContract = contract;
    },

    setCommitData(state, data) {
        state.commitData = data;
    },

    setItemFished(state, item) {
        state.itemFished = item;
    },

    setItemsFished(state, items) {
        state.itemsFished = items;
    },

    setShowFishingResultModal(state, value) {
        state.showFishingResultModal = value;
    },
};

const actions = {
    boot({ rootState, commit }, { contractAddress }) {
        const abi = require("@/plugins/artefacts/fishing.json");

        const { signer, defaultSigner } = rootState.site;
        const contract = new ethers.Contract(contractAddress, abi, signer || defaultSigner);

        commit("initializeContract", contract);
    },

    async initializeData({ commit, state, rootState }) {
        const contract = state.fishingContract;
        const { storedAccount } = rootState.user;

        const userCommitData = await contract.getCommitData(storedAccount);

        if (!userCommitData || Number(userCommitData.blockNumber) === 0) {
            commit("setCommitData", undefined);
            return;
        }
        const data = { commitBlock: Number(userCommitData.blockNumber), baitPower: Number(userCommitData.baitPower) };

        if (userCommitData.blockNumber) {
            commit("setCommitData", userCommitData);
        }
    },

    async fish({ state, dispatch, rootState }, { baitTier, amount }) {
        try {
            const contract = state.fishingContract;
            const { baseGwei } = rootState.site.settings;

            const tx = await contract.fish(baitTier, amount, {
                //gasPrice: baseGwei,
            });

            createNotification({
                dispatch,
                message: `Commit: Fishing with bait tier ${baitTier}!`,
                type: "warn",
                txHash: tx.hash,
                showSpinner: true,
            });

            await tx.wait();
            finalizeNotification({ dispatch, id: tx.hash, message: "Token Approved!" });

            await dispatch("initializeData");
        } catch (err) {
            dispatch("site/notifications/clearAll", {}, { root: true });
            getTxErrorMessage(dispatch, err);
        }
    },

    async revealCatch({ state, commit, rootState, dispatch }) {
        try {
            const { defaultSigner: provider } = rootState.site;
            const { storedAccount } = rootState.user;
            const { baseGwei } = rootState.site.settings;
            const blockNumber = await provider.getBlockNumber();

            const contract = state.fishingContract;
            const commitBlockNumber = Number(state.commitData.blockNumber);

            let result;
            let tx;

            if (commitBlockNumber + 250 > blockNumber) {
                tx = await contract.revealCatch({
                    //gasPrice: baseGwei,
                });
                result = await tx.wait();
            } else {
                const { blockHash, signature } = await getSeedVendorRevealData({
                    user: storedAccount,
                    seedType: 0,
                    blockNumber: commitBlockNumber,
                });

                tx = await contract.revealCatchWithSignature(blockHash, signature);
                result = await tx.wait();
            }

            const fishesCaught = [];

            for (let i = 0; i < result.logs.length; i++) {
                try {
                    const decodedData = contract.interface.parseLog(result.logs[i]);
                    if (decodedData.name === "FishCatch") {
                        const data = decodedData.args.toArray();
                        if (data[0]) {
                            fishesCaught.push(data[1]);
                        } else {
                            fishesCaught.push("boot");
                        }
                    }
                } catch (err) {
                    continue;
                }
            }

            const amounts = [];
            const ids = [];

            // remove duplicates and
            for (let i = 0; i < fishesCaught.length; i++) {
                if (fishesCaught[i] === "boot") continue;

                const index = ids.indexOf(fishesCaught[i]);
                if (index === -1) {
                    ids.push(fishesCaught[i]);
                    amounts.push(1);
                } else {
                    amounts[index]++;
                }
            }

            commit(
                "user/setLastItemsReceived",
                {
                    itemIds: ids,
                    itemAmounts: amounts,
                },
                { root: true },
            );

            const itemFished = fishesCaught[0];

            commit("setItemFished", itemFished);
            commit("setItemsFished", fishesCaught);

            if (fishesCaught.length > 0) {
                setTimeout(() => {
                    commit("setShowFishingResultModal", true);
                }, 1500);
            }
        } catch (err) {
            dispatch("site/notifications/clearAll", {}, { root: true });
            getTxErrorMessage(dispatch, err);
        }
    },

    async revealCatchForUser({ state, commit, rootState, dispatch }, { txHash }) {
        try {
            const contract = state.fishingContract;
            const { baseGwei } = rootState.site.settings;
            const { storedAccount } = rootState.user;

            const provider = new ethers.JsonRpcProvider(RPCS.mainnet);
            const transactionDetails = await provider.getTransaction(txHash);
            const { from, blockNumber } = transactionDetails;
            const blockDetails = await provider.getBlock(blockNumber);
            const { hash } = blockDetails;

            const tx = await contract.revealCatchForUser(from, hash);

            var result = await tx.wait();
        } catch (err) {
            dispatch("site/notifications/clearAll", {}, { root: true });
            getTxErrorMessage(dispatch, err);
        }
    },

    async craftBait({ state, commit, rootState, dispatch }, { baitTier, tokenIds, tokenAmounts, amount }) {
        try {
            const contract = state.fishingContract;
            const { baseGwei } = rootState.site.settings;
            const tx = await contract.craftBait(baitTier, tokenIds, tokenAmounts, amount);

            createNotification({
                dispatch,
                message: `Crafting ${amount} baits!`,
                type: "warn",
                txHash: tx.hash,
                showSpinner: true,
            });

            var result = await tx.wait({
                //gasPrice: baseGwei,
            });

            finalizeNotification({ dispatch, id: tx.hash, message: `${amount} bait(s) crafted!` });
        } catch (err) {
            dispatch("site/notifications/clearAll", {}, { root: true });
            getTxErrorMessage(dispatch, err);
        }
    },
};

const getters = {};

export default {
    state,
    mutations,
    getters,
    actions,
    namespaced: true,
};

<template>
    <div class="base-modal">
        <div @click="$emit('close')" class="modal-wrapper"></div>
        <div ref="container" :style="getStyle" class="modal bounce-enter-active">
            <div @click="$emit('close')" class="modal-close"></div>
            <div class="modal-header-row">
                <div class="modal-header">
                    <ModalHeader :headerType="headerType" :text="title.toUpperCase()"></ModalHeader>
                </div>
            </div>

            <div class="modal-content">
                <slot name="content"></slot>
            </div>
            <div class="modal-footer"></div>
        </div>
    </div>
</template>

<script>
import ModalHeader from "@/components/ui/ModalHeader.vue";
export default {
    name: "BaseModal",

    components: { ModalHeader },
    props: {
        width: {
            type: Number,
            default: 32,
        },
        height: {
            type: Number,
            default: 32,
        },
        maxWidth: {
            type: Number,
            default: 9999,
        },
        maxHeight: {
            type: Number,
            default: 9999,
        },
        minWidth: {
            type: Number,
            default: 32,
        },
        minHeight: {
            type: Number,
            default: 32,
        },
        title: {
            type: String,
            default: "",
        },
        visible: {
            type: Boolean,
            default: false,
        },

        updateKey: {
            type: String,
            default: "",
        },

        headerType: {
            type: String,
            default: "default",
        }
    },

    data() {
        return {
            showWrapper: false,
            initialized: false,

            refresh: 0,
        };
    },

    mounted() {
        this.initialized = true;
    },

    watch: {
        updateKey() {
            this.initialized = false;
            this.refresh++;

            setTimeout(() => {
                this.initialized = true;
            });
        },
    },

    computed: {
        getStyle() {
            if (!this.initialized) {
                return {
                    visibility: "hidden",
                };
            }

            const modalContainer = this.$refs.container;
            let width = this.width;
            let height = Math.max(this.height, modalContainer.offsetHeight);

            const windowWidth = window.innerWidth;
            const windowHeight = window.innerHeight;

            if (width >= windowWidth * 0.9) {
                width = windowWidth * 0.85;
            }

            if (height >= windowHeight) {
                height = windowHeight * 0.8;
            }

            this.refresh;

            return {
                width: `${width}px`,
                height: `auto`,
                minHeight: `${this.minHeight}px`,
                maxHeight: `${this.maxHeight}px`,
                maxWidth: `${this.maxWidth}px`,
                display: "flex",
            };
        },
    },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.base-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;

    z-index: 10000;

    display: flex;
    justify-content: center;
    align-items: center;
}

.modal {
    &-row {
        width: 100%;
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        gap: 1rem;
    }
}

.modal {
    &::before {
        background-image: url("../../assets/gui/modalTopLeft.png"), url("../../assets/gui/modalTopRight.png"), url("../../assets/gui/modalBottomLeft.png"),
            url("../../assets/gui/modalBottomRight.png");
        background-position: 0 0, 100% 0, 0 100%, 100% 100%;
        background-repeat: no-repeat;
        content: "";
        display: block;
        height: 100%;
        image-rendering: pixelated;
        left: 0;
        pointer-events: none;
        position: absolute;
        top: 0;
        width: 100%;
    }

    position: fixed;
    z-index: 10000;

    background-image: url("../../assets/gui/modalBorderLeft.png"),
    url("../../assets/gui/modalBorderRight.png"),
    url("../../assets/gui/modalBorderTop.png"),
    url("../../assets/gui/modalBorderBottom.png");
    background-position: 0 0,
    100% 0,
    0 0,
    0 100%;
    background-repeat: repeat-y,
    repeat-y,
    repeat-x,
    repeat-x;
    border-radius: 70px;
    position: relative;

    box-shadow: none;

    padding: 50px 35px 23px 35px;

    &-close {
        width: 3rem;
        height: 3rem;
        position: absolute;
        right: -3rem;
        top: 1rem;
        background: url("../../../public/images/modalClose.png");
        background-size: 100% 100%;
        cursor: pointer;
        z-index: 10002;

        &:hover {
            filter: brightness(1.2);
        }

        @media screen and (max-width: 512px) {
            top: 0rem;
            right: 0rem;
        }
    }

    &-header-row {
        width: 100%;
        height: 3.5rem;
        z-index: 10001;

        position: absolute;
        top: -1rem;
        left: 0;

        display: flex;
        justify-content: center;
        align-items: center;
    }

    &-header {
        min-width: 16rem;
        height: 100%;
        margin-bottom: 1rem;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    &-wrapper {
        width: 100%;
        height: 100%;
        position: absolute;
        z-index: 10000;
        transition: none !important;
        background-color: rgba(0, 0, 0, 0.75);
        backdrop-filter: blur(1px);
    }

    &-content {
        width: 100%;
        height: 100%;
        border-image: url('../../assets/gui/wrapperBorder.png') 30 fill repeat;
        border-image-width: 84px;
        padding: 3rem 2rem 2rem 2rem;
    }
}

.bounce-enter-active {
    animation: bounce-in 0.5s;
}

.bounce-leave-active {
    animation: bounce-in 0.5s reverse;
}

@keyframes bounce-in {
    0% {
        transform: scale(0);
    }

    50% {
        transform: scale(1.05);
    }

    100% {
        transform: scale(1);
    }
}
</style>

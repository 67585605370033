<template>
    <div class="legacy-skill-container">
        <div class="legacy-skill">
            <img width="32px" :src="icon" />
        </div>
        <div class="skill-info">
            <p class="small-text skill-name">{{ name }}</p>
            <p class="small-text skill-description">{{ description }}</p>
        </div>
    </div>
</template>

<script>
import { mapState } from "vuex";

export default {
    name: "LegacySkill",

    components: {},

    props: {
        id: {
            type: Number,
            default: 0,
        },
    },

    data() {
        return {};
    },

    methods: {},

    computed: {
        ...mapState("content", ["legacySkills"]),

        icon() {
            return require(`@/assets/adventurers/legacySkills/${this.id || 0}.png`);
        },

        name() {
            return this.legacySkills[this.id].name || "Unknown";
        },

        description() {
            return this.legacySkills[this.id].description || "";
        },
    },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.legacy-skill-container {
    display: flex;
    gap: 0.5rem;
}

.skill-info {
    display: flex;
    flex-direction: column;
    gap: 0.1rem;
    justify-content: start;
    align-items: start;
}

.skill-name {
    font-size: 1rem !important;
    text-align: start;
}

.skill-description {
    font-size: 0.65rem !important;
    text-align: start;
    opacity: 0.75 !important;
}

.legacy-skill {
    border-top: 2px solid #fff0f0;
    border-bottom: 2px solid #8a97a6;
    border-right: 2px solid #8a97a6;
    border-left: 2px solid #fff0f0;
    background: rgba(0, 0, 0, 0.25);

    width: 2rem;
    height: 2rem;

    display: flex;
    justify-content: center;
    align-items: center;
}
</style>

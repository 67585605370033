import { ethers } from "ethers";
import { createNotification } from "@/libs/storeHelper";

const state = () => ({
    leaderboardContract: null,

    leaderboardData: [],
    leaderboardRewards: [],

    userScore: 0,
    currentEpoch: 0,

    genesisDate: 1711738939000,
    epochDuration: 604800000,
    epochsPassed: Math.floor((Date.now() - 1711738939000) / 604800000),
});
//
const mutations = {
    initializeContract(state, contract) {
        state.leaderboardContract = contract;
    },

    setLeaderboardData(state, data) {
        state.leaderboardData = data;
    },

    setLeaderboardRewards(state, rewards) {
        state.leaderboardRewards = rewards;
    },

    setUserScore(state, score) {
        state.userScore = (Number(score) / 1e18).toFixed(2);
    },

    setCurrentEpoch(state, epoch) {
        state.currentEpoch = Number(epoch);
    },
};

const actions = {
    boot({ commit, rootState }, { contractAddress }) {
        const abi = require("@/plugins/artefacts/leaderboard.json");

        const { signer, defaultSigner } = rootState.site;
        const contract = new ethers.Contract(contractAddress, abi, signer || defaultSigner);
        commit("initializeContract", contract);
    },

    async initializeLeaderboardData({ state, rootState, commit }) {
        const contract = state.leaderboardContract;

        const currentEpoch = await contract.getEpoch();
        const leaderboardWithNames = await contract.getLeaderboardWithNames(currentEpoch);

        const { storedAccount } = rootState.user;
        const currentUserScore = await contract.getCurrentEpochScore(storedAccount);

        commit("setLeaderboardData", leaderboardWithNames);
        commit("setUserScore", currentUserScore);
        commit("setCurrentEpoch", currentEpoch);
    },

    async navigateEpoch({ commit, state, rootState }, direction) {
        const contract = state.leaderboardContract;
        const currentEpoch = state.currentEpoch;

        if (direction === "prev" && currentEpoch === 0) return;
        if (direction === "next" && currentEpoch === state.epochsPassed) return;

        const newEpoch = direction === "next" ? currentEpoch + 1 : currentEpoch - 1;

        commit("setCurrentEpoch", newEpoch);

        const leaderboardWithNames = await contract.getLeaderboardWithNames(newEpoch);

        const { storedAccount } = rootState.user;
        const currentUserScore = await contract.getScore(storedAccount, newEpoch);

        commit("setUserScore", currentUserScore);
        commit("setLeaderboardData", leaderboardWithNames);
    },

    async claimHarvest({ state, dispatch, rootState }) {
        const contract = state.sageContract;
        const { baseGwei } = rootState.site.settings;

        const tx = await contract.claim();

        createNotification({
            dispatch,
            message: `Tx: Claiming harvest!`,
            type: "warn",
            id: tx.hash,
        });

        await tx.wait(1);

        dispatch("user/getProfile", {}, { root: true });
        dispatch("site/notifications/remove", tx.hash, { root: true });

        createNotification({
            dispatch,
            message: `Harvest claimed!`,
            duration: 5000,
        });
    },
};

const getters = {};

export default {
    state,
    mutations,
    getters,
    actions,
    namespaced: true,
};

<template>
    <div class="login-page">
        <div class="login-page-image"></div>
        <div class="login-page-cover"></div>

        <div class="login-page-container" :class="[isOnBlast ? '' : 'small']">
            <div class="login-page-container-content">
                <div class="content-wrapper">
                    <div class="login-page-container-logo">
                        <img src="../assets/logo.png" />
                    </div>

                    <template v-if="isNewUser">
                        <br />

                        <p style="width: 90%" class="big-text">Welcome to the Valleys!</p>

                        <p style="width: 90%; margin-top: -5px">
                            <span class="highlight">A farming & adventuring game on Blast.</span>
                        </p>

                        <br />

                        <div class="content-buttons">
                            <BaseButton @clicked="tryConnectWallet" :text="'Connect Wallet'" :disabled="invalidChainError"></BaseButton>
                            <br />
                            <BaseButton v-if="invalidChainError" :text="'Connect to Blast'" @clicked="tryChangeNetwork"> </BaseButton>
                        </div>

                        <div style="margin-top: auto" class="helpful-links">
                            <div class="divider"></div>
                            <a href="https://twitter.com/CryptoValleys" target="#blank">
                                <TwitterIcon title="Official Twitter"></TwitterIcon>
                            </a>
                            <a href="https://discord.gg/cryptovalleys" target="#blank">
                                <DiscordIcon title="Discord Group"></DiscordIcon>
                            </a>
                            <a href="https://cryptovalleys.gitbook.io/docs" target="#blank">
                                <GitbookIcon title="Official Documentation"></GitbookIcon>
                            </a>

                            <div class="divider"></div>
                        </div>
                    </template>
                    <template v-if="!isNewUser && isUnregisteredUser">
                        <div class="register-container">
                            <p class="big-text">Create your Profile!</p>
                            <div class="divider"></div>
                            <div style="display: flex; justify-content: space-around; align-items: center">
                                <div>
                                    <div class="login-input">
                                        <BaseInput v-model="name" :placeholder="'Username (Max 32)'"></BaseInput>
                                    </div>
                                    <div style="margin-top: 1rem" class="login-input">
                                        <BaseInput v-model="referralCode" :placeholder="'Referral Code (Optional)'"> </BaseInput>
                                    </div>
                                </div>
                            </div>
                            <div class="content-buttons">
                                <BaseButton @clicked="tryCreateAccount" :text="'Create Profile'"></BaseButton>
                            </div>
                            <br />
                            <div class="helpful-links stick-bottom">
                                <div class="divider"></div>
                                <a href="https://twitter.com/CryptoValleys" target="#blank">
                                    <TwitterIcon title="Official Twitter"></TwitterIcon>
                                </a>
                                <a href="https://discord.gg/cryptovalleys" target="#blank">
                                    <DiscordIcon title="Discord Group"></DiscordIcon>
                                </a>
                                <a href="https://cryptovalleys.gitbook.io/docs" target="#blank">
                                    <GitbookIcon title="Official Documentation"></GitbookIcon>
                                </a>
                                <div class="divider"></div>
                            </div>
                        </div>
                    </template>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";

import DiscordIcon from "@/components/icons/DiscordIcon.vue";
import TwitterIcon from "@/components/icons/TwitterIcon.vue";
import GitbookIcon from "@/components/icons/GitbookIcon.vue";

export default {
    name: "LoginPage",

    components: { DiscordIcon, TwitterIcon, GitbookIcon },

    props: {},

    data() {
        return {
            name: "",
            referralCode: "",
        };
    },

    mounted() {
        const urlParameters = this.$route.query;
        if (urlParameters.referralCode) {
            this.referralCode = urlParameters.referralCode;
        }
    },

    beforeDestroy() {},

    methods: {
        ...mapActions("site", ["connectWallet", "setCurrentModal", "requestChangeNetwork"]),
        ...mapActions("contracts", ["callContractFunction"]),

        tryConnectWallet() {
            this.connectWallet({ forceSigner: true });
        },

        tryChangeNetwork() {
            this.requestChangeNetwork();
        },

        async tryCreateAccount() {
            if (this.name.length < 3 || this.name.length > 32) {
                this.$store.dispatch("site/notifications/create", {
                    type: "error",
                    message: "Username must be between 3 and 32 characters",
                    id: Date.now(),
                    duration: 5000,
                });
            }

            await this.callContractFunction({
                contract: "profile",
                functionName: "registerProfile",
                params: {
                    name: this.name,
                    refCode: this.referralCode,
                },
            });
        },
    },

    computed: {
        ...mapState("site", ["invalidChainError"]),
        ...mapGetters("user", ["isNewUser", "isUnregisteredUser", "isOnBlast"]),

        showRegisterScreen() {
            return this.isNewUser;
        },
    },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.login-page {
    width: 100%;
    height: 97.5%;

    display: flex;
    justify-content: center;
    align-items: center;

    &-image {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;

        background: url("../../public/images/overworld.gif") no-repeat center center fixed;
        background-size: cover;

        filter: blur(4px);
        z-index: 5;
    }

    &-cover {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.25);

        z-index: 10;
    }

    &-container {
        position: relative;
        width: 40rem;
        height: max(40rem, 65%);
        z-index: 100;

        &.small {
            width: max(24rem, 35%);
            height: 60%;
        }

        @media screen and (max-width: 1024px) {
            width: 60%;
            height: 44rem;
        }

        @media screen and (max-width: 512px) {
            width: 90%;
            height: 40rem;
        }

        &-logo {
            height: 22.5%;
            margin-bottom: 2rem;
            margin-top: 2rem;

            @media screen and (max-width: 768px) {
                height: 25%;
                margin-bottom: 1rem;
                width: 60%;
            }

            @media screen and (max-width: 512px) {
                height: 25%;
                margin-bottom: 0.5rem;
            }

            img {
                height: 100%;
                width: 100%;
            }
        }

        &-content {
            position: relative;
            width: 100%;
            height: 100%;
            border-image: url("../../public/images/modalBg.png") 6 fill repeat;
            border-image-width: 1rem;
            box-shadow: 4px 4px 32px #0000006c;
            display: flex;
            justify-content: center;
            align-items: center;

            &::before {
                background-position: 0 0, 100% 0, 0 100%, 100% 100%;
                background-repeat: no-repeat;
                content: "";
                display: block;
                height: 106.5%;
                image-rendering: pixelated;
                top: -3.5%;
                left: -4%;
                pointer-events: none;
                position: absolute;
                width: 108%;
            }
        }
    }
}

.video-box {
    width: 75%;
    height: 20rem !important;

    @media screen and (max-width: 512px) {
        width: 90%;
        height: 90%;
        height: 16rem !important;
    }

    iframe {
        width: 85%;
        height: 85%;
        border-radius: 0.25rem;
    }
}

.content-buttons {
    width: 20rem;
    height: 3.5rem;
    margin-top: 1rem;

    @media screen and (max-width: 512px) {
        margin-top: 0.5rem;
        width: 100%;
        height: 3rem;
    }
}

.helpful-links {
    width: 75%;
    height: 3rem;

    @media screen and (max-width: 768px) {
        height: 2rem;
    }

    display: flex;
    gap: 1rem;
    justify-content: center;
    align-items: center;

    ::v-deep svg {
        @media screen and (max-width: 768px) {
            width: 2rem;
            height: 2rem;
        }

        @media screen and (max-width: 512px) {
            width: 1.5rem;
            height: 1.5rem;
        }

        path {
            fill: #ffd664 !important;
        }
    }
}

.login {
    &-input {
        width: 20rem;
        display: flex;
        gap: 0.5rem;
        align-items: center;
        justify-content: center;

        @media screen and (max-width: 512px) {
            width: 100%;
        }

        flex-direction: column;

        p {
            width: 100%;
        }

        .base-input {
            height: 3rem;
            width: 100%;
        }
    }

    &-picture-box {
        min-width: 8rem;
        min-height: 8rem;
        background: rgba(0, 0, 0, 0.25);
        border-radius: 0.5rem;
        border-image: url("../../public/images/itemBg.png") 6 fill repeat;
        border-image-width: 1rem;

        cursor: pointer;

        &:hover {
            transform: scale(1.05);
        }
    }
}

.register-container {
    width: 75%;
    height: 90%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1rem;
}

.stick-bottom {
    margin-top: auto;
}

.content-wrapper {
    height: 90%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    gap: 1rem;
}
</style>

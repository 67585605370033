import { ethers } from "ethers";
import { createNotification, finalizeNotification } from "@/libs/storeHelper";

const state = () => ({
    lotteryContract: null,

    epoch: 0,
    tickets: 0,
    timeLeft: 0,

    totalTickets: 0,

    started: false,
    startsIn: 0,
});

const mutations = {
    initializeContract(state, contract) {
        state.lotteryContract = contract;
    },

    setEpoch(state, epoch) {
        state.epoch = epoch;
    },

    setTickets(state, tickets) {
        state.tickets = tickets;
    },

    setTimeLeft(state, timeLeft) {
        state.timeLeft = timeLeft * 1000;
    },

    setTotalTickets(state, totalTickets) {
        state.totalTickets = totalTickets;
    },

    setStarted(state, started) {
        state.started = started;
    },

    setStartsIn(state, startsIn) {
        state.startsIn = startsIn;
    },
};

const actions = {
    boot({ commit, rootState }, { contractAddress }) {
        const abi = require("@/plugins/artefacts/lottery.json");

        const { signer, defaultSigner } = rootState.site;
        const contract = new ethers.Contract(contractAddress, abi, signer || defaultSigner);

        commit("initializeContract", contract);
    },

    async initializeContractData({ rootState, state, commit }) {
        const contract = state.lotteryContract;
        const { storedAccount } = rootState.user;

        const startTimestamp = 1713124800000;
        const now = Date.now();

        const started = now > startTimestamp;
        commit("setStarted", started);

        if (!started) {
            commit("setStartsIn", startTimestamp - now);
            return;
        }

        const data = await contract.getCurrentEpochData(storedAccount);

        const { epoch, tickets, timeleft, totalTickets } = data;

        commit("setEpoch", Number(epoch));
        commit("setTickets", Number(tickets));
        commit("setTimeLeft", Number(timeleft));
        commit("setTotalTickets", Number(totalTickets));
    },

    async buyTickets({ state, dispatch, rootState }, { amount }) {
        const contract = state.lotteryContract;
        const { baseGwei } = rootState.site.settings;

        const tx = await contract.buyTickets(amount, {});

        createNotification({
            dispatch,
            message: `Tx: Buying ${amount} tickets!`,
            type: "warn",
            id: tx.hash,
            showSpinner: true,
        });

        await tx.wait(1);

        dispatch("initializeContractData");
        finalizeNotification({ dispatch, id: tx.hash, message: "Tickets bought successfully!" });
    },
};

const getters = {};

export default {
    state,
    mutations,
    getters,
    actions,
    namespaced: true,
};

<template>
    <div class="card-base" :class="[rarityDisplay]" style="position: relative; display: flex">
        <div class="flip-card">
            <div class="flip-card-inner">
                <div class="flip-card-front">
                    <div class="adventurer-card">
                        <div class="adventurer-card-background">
                            <img :src="bgImage" />

                            <div class="adventurer-card-background-adventurer">
                                <img :src="adventurerImage" />
                            </div>
                        </div>

                        <div class="adventurer-card-canvas">
                            <img :src="canvasImage" />
                        </div>

                        <div class="adventurer-card-metadata">
                            <p class="small-text name">Unknown</p>

                            <div class="class-info">
                                <p class="small-text">Soul</p>

                                <p class="tiny-text">Lv. 1</p>
                            </div>

                            <div class="progress-bars">
                                <div class="progress-container">
                                    <p class="progress-text">En</p>
                                    <ProgressBar :type="'energy'"></ProgressBar>
                                </div>

                                <br />

                                <div class="progress-container">
                                    <p class="progress-text">Exp</p>
                                    <ProgressBar :type="'experience'"></ProgressBar>
                                </div>
                            </div>

                            <div class="generation-info">
                                <p class="tiny-text">Gen 0</p>
                            </div>
                        </div>

                        <div class="adventurer-card-frame">
                            <img :src="frameImage" />
                        </div>
                    </div>
                </div>
            </div>

            <div
                v-show="false"
                class="info-container"
                style="position: absolute; height: 360px; width: 220px; right: -300px; top: -32px; background: red"
            ></div>
        </div>

        <div class="card-info-container" :class="[rarityDisplay]">
            <div class="card-info-container-stats">
                <p style="font-size: 1.25rem" class="small-text">Stats</p>
                <div style="width: 100%; height: 1px; background: rgba(0, 0, 0, 0.5); margin-top: 0.1rem"></div>

                <p class="tiny-text" style="font-size: 0.8rem; margin-top: 0.25rem; margin-bottom: 0.25rem">Allocation Points: {{ allocationPoints }}</p>

                <div class="card-info-container-stats-grid">
                    <div v-for="(stat, index) in mainStats" :key="index">
                        <div class="primary-stat-row">
                            <img height="12" :src="getPrimaryStatImage(stat)" />

                            <p class="small-text">{{ parseStat(stat) }}</p>

                            <p class="small-text highlight">{{ adventurerMetadata.stats[stat] }}</p>
                        </div>

                        <div
                            :title="secondaryStatDescription[secondaryStat]"
                            class="secondary-stat-row"
                            v-for="(secondaryStat, sIndex) in secondaryStats[stat]"
                            :key="sIndex"
                        >
                            <p class="tiny-text">
                                {{ parseSecondaryStat(secondaryStat) }}
                            </p>

                            <p class="tiny-text highlight">{{ secondaryStatsValues[secondaryStat] }}</p>
                        </div>
                    </div>
                </div>

                <br />

                <p class="small-text" style="font-size: 1.25rem">Legacy Skills</p>
                <div style="width: 100%; height: 1px; background: rgba(0, 0, 0, 0.5); margin-top: 0.25rem; margin-bottom: 0.5rem"></div>

                <div style="display: flex; flex-direction: column; gap: 0.5rem">
                    <LegacySkill v-for="(skill, index) in legacySkills" :id="skill" :key="'s' + index"></LegacySkill>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import ProgressBar from "@/components/adventurers/ProgressBar.vue";
import LegacySkill from "@/components/adventurers/LegacySkill.vue";
import _ from "lodash";

export default {
    name: "AdventurerCard",

    components: { ProgressBar, LegacySkill },

    props: {
        adventurerMetadata: {
            type: Object,
            default: () => ({
                seed: Math.random() * 1000,
                rarity: 2,
                stats: {
                    vigor: 8,
                    strength: 8,
                    agility: 8,
                    intelligence: 8,
                    spirit: 8,
                    luck: 1,
                },
                skills: [0, 0],
            }),
        },
    },

    data() {
        return {
            mainStats: ["vigor", "strength", "agility", "intelligence", "spirit", "luck"],
            secondaryStats: {
                vigor: ["health", "energy", "poisonResistance"],
                strength: ["physicalDamage", "physicalDefense", "bleedResistance"],
                agility: ["speed", "evasionChance", "criticalChance"],
                intelligence: ["mana", "magicDamage", "curseResistance"],
                spirit: ["magicDefense", "healthRegeneration", "manaRegeneration"],
                luck: ["hitChance", "criticalDamage", "immunity"],
            },

            primaryStatDisplay: {
                vigor: "Vi",
                strength: "Str",
                agility: "Agi",
                intelligence: "Int",
                spirit: "Sp",
                luck: "Lk",
            },

            secondaryStatDisplay: {
                health: "HEALTH",
                energy: "ENERGY",
                poisonResistance: "POISON RES",
                physicalDamage: "P. DMG",
                physicalDefense: "P. DEF",
                bleedResistance: "BLEED RES",
                speed: "SPEED",
                evasionChance: "DODGE",
                criticalChance: "CRIT",
                mana: "MANA",
                magicDamage: "M. DMG",
                curseResistance: "CURSE RES",
                magicDefense: "M. DEF",
                healthRegeneration: "HP REGEN",
                manaRegeneration: "MP REGEN",
                hitChance: "HIT",
                criticalDamage: "CRIT DMG",
                immunity: "IMMUNITY",
            },

            secondaryStatDescription: {
                immunity: "Chance to ignore negative effects",
                criticalDamage: "Damage multiplier on critical hits",
                hitChance: "Chance to hit enemies with attacks & spells",
                manaRegeneration: "Mana regenerated per second",
                healthRegeneration: "Health regenerated per second",
                magicDefense: "Resistance to magical attacks",
                curseResistance: "Reduces the effectiveness & duration of curse debuffs",
                magicDamage: "Damage dealt by magical attacks",
                mana: "Mana pool for casting spells",
                criticalChance: "Chance to deal critical hits",
                evasionChance: "Chance to dodge attacks",
                speed: "The frequency of turns in combat",
                bleedResistance: "Reduces the effectiveness & duration of bleed debuffs",
                physicalDefense: "Resistance to physical attacks",
                physicalDamage: "Damage dealt by physical attacks",
                poisonResistance: "Reduces the effectiveness & duration of poison debuffs",
                energy: "Energy pool for expeditions",
                health: "Health pool for taking damage",
            },

            debuffs: {
                poison: "Take damage over time",
                curses: "Negative effects on stats",
                bleed: "Reduces health & mana regeneration effects",
                stun: "Skip turns in combat",
            }
        };
    },

    methods: {
        parseStat(stat) {
            return this.primaryStatDisplay[stat];
        },

        parseSecondaryStat(stat) {
            return this.secondaryStatDisplay[stat];
        },

        getPrimaryStatImage(stat) {
            return require(`@/assets/adventurers/stats/${stat}.png`);
        },

        getSecondaryStatImage(stat) {
            return require(`@/assets/adventurers/stats/${stat}.png`);
        },
    },

    computed: {
        rarity() {
            return this.adventurerMetadata?.rarity || 0;
        },

        legacySkills() {
            return this.adventurerMetadata?.skills.filter((c) => c != 0) || [];
        },

        rarityDisplay() {
            if (this.rarity === 0) {
                return "common";
            } else if (this.rarity === 1) {
                return "uncommon";
            } else if (this.rarity === 2) {
                return "rare";
            } else if (this.rarity === 3) {
                return "epic";
            } else if (this.rarity === 4) {
                return "legendary";
            } else {
                return "unknown";
            }
        },

        frameImage() {
            return require(`@/assets/adventurers/cards/${this.rarity}_frame.png`);
        },

        canvasImage() {
            return require(`@/assets/adventurers/cards/${this.rarity}_canvas.png`);
        },

        bgImage() {
            const bg = (this.adventurerMetadata.tokenId % 12) + 1;

            return require(`@/assets/adventurers/cards/bg_${bg}.png`);
        },

        adventurerImage() {
            return require(`@/assets/adventurers/souls/soul_${this.rarity}.gif`);
        },

        backImage() {
            return require(`@/assets/adventurers/cards/${this.rarity}_back.png`);
        },

        allocationPoints() {
            return this.adventurerMetadata?.allocationPoints || 0;
        },

        secondaryStatsValues() {
            const secondaryStats = {};

            const vigor = this.adventurerMetadata.stats.vigor;
            secondaryStats.health = (vigor * 16).toFixed(0);
            secondaryStats.energy = (vigor * 8).toFixed(0);
            secondaryStats.poisonResistance = (vigor * 0.1).toFixed(1);

            const strength = this.adventurerMetadata.stats.strength;
            secondaryStats.physicalDamage = strength.toFixed(0);
            secondaryStats.physicalDefense = (strength * 0.15).toFixed(1);
            secondaryStats.bleedResistance = (strength * 0.08).toFixed(1);

            const agility = this.adventurerMetadata.stats.agility;
            secondaryStats.speed = (1 + agility * 0.025).toFixed(1);
            secondaryStats.evasionChance = `${(2.5 + agility * 0.03).toFixed(1)}%`;
            secondaryStats.criticalChance = `${(5 + agility * 0.1).toFixed(1)}%`;

            const intelligence = this.adventurerMetadata.stats.intelligence;
            secondaryStats.mana = (intelligence * 8).toFixed(0);
            secondaryStats.magicDamage = (intelligence * 1.25).toFixed(0);
            secondaryStats.curseResistance = (intelligence * 0.1).toFixed(1);

            const spirit = this.adventurerMetadata.stats.spirit;
            secondaryStats.magicDefense = (spirit * 0.1).toFixed(1);
            secondaryStats.healthRegeneration = (spirit / 8).toFixed(1);
            secondaryStats.manaRegeneration = (spirit / 12).toFixed(1);

            const luck = this.adventurerMetadata.stats.luck;
            secondaryStats.hitChance = `${(luck * 0.5).toFixed(1)}%`;
            secondaryStats.criticalDamage = `${(1 + luck * 0.025).toFixed(1)}x`;
            secondaryStats.immunity = (luck * 0.1).toFixed(1);

            return secondaryStats;
        },
    },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.info-container {
    visibility: hidden;
}

.adventurer-card {
    display: flex;
    align-items: center;
    justify-content: center;

    position: relative;
    width: 180px;
    height: 270px;

    img {
        width: 100%;
        height: 100%;
    }

    &-canvas {
        position: absolute;

        width: 100%;
        height: 100%;

        z-index: 2;
    }

    &-back {
        position: absolute;

        width: 100%;
        height: 100%;
    }

    &-background {
        position: absolute;

        width: 100%;
        height: 100%;

        z-index: 1;

        &-adventurer {
            position: absolute;
            top: 12.5%;
            left: 12%;

            width: 8rem;
            height: 8rem;
            opacity: 0.9;
        }
    }

    &-metadata {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;

        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;

        padding: 1rem;

        .name {
            position: absolute;
            top: 8.5%;
            font-weight: bold;
            color: white;

            font-size: 1.25rem !important;

            width: 50%;
            height: 5%;
        }

        .class-info {
            position: absolute;
            top: 60.2%;
            left: 7.5%;
            font-weight: bold;
            color: white;

            width: 85%;

            display: flex;
            align-items: center;
            justify-content: space-around;

            line-height: 100%;

            p {
                text-align: start;

                &.small-text {
                    font-size: 0.95rem !important;
                }

                &.tiny-text {
                    font-size: 0.6rem !important;
                    color: #dbd958;
                    opacity: 0.9;
                }
            }
        }

        .progress-bars {
            position: absolute;

            width: 57.5%;
            top: 72%;
            left: 20%;

            height: 10%;
        }

        .generation-info {
            position: absolute;
            top: 88.85%;

            p {
                font-size: 0.9rem !important;
                color: white;
            }
        }

        z-index: 2;
    }

    &-frame {
        position: absolute;

        width: 100%;
        height: 100%;

        z-index: 3;
    }
}

.progress-container {
    display: flex;

    align-items: center;
    justify-content: center;
    height: 0.5rem;

    .progress-text {
        margin-top: 4px;

        border-top: 2px solid #fff0f0;
        border-bottom: 2px solid #8a97a6;

        border-left: 2px solid #fff0f0;

        background-color: #281e28;

        border-top-left-radius: 2px;
        border-bottom-left-radius: 2px;

        font-size: 0.6rem !important;
        color: white;

        line-height: 10px;

        width: 24px;
        height: 10px;
    }
}

.primary-stat-row {
    display: flex;
    align-items: center;
    justify-content: space-around;

    width: 90%;
    gap: 0rem;
    margin-left: auto;
    margin-right: auto;
    margin-top: 0.25rem;

    border-top: 2px solid #fff0f0;
    border-bottom: 2px solid #8a97a6;
    border-right: 2px solid #8a97a6;
    border-left: 2px solid #fff0f0;
    background: rgba(0, 0, 0, 0.25);

    img {
        width: 1rem;
        height: 1rem;
    }

    border-radius: 2px;

    padding-bottom: 0.1rem;
    padding-top: 0.1rem;
}

.secondary-stat-row {
    cursor: pointer !important;

    display: flex;

    align-items: center;
    justify-content: space-between;

    width: 80%;
    margin-left: auto;
    margin-right: auto;

    background-color: rgba(0, 0, 0, 0.5);
    margin-top: 0.25rem;

    border: 1px solid rgba(0, 0, 0, 0.5);

    border-radius: 2px;

    padding-right: 0.25rem;
    padding-left: 0.25rem;
}

// flip efect

.flip-card {
    position: relative;
    background-color: transparent;
    width: 180px;
    height: 270px;

    max-height: 270px;
    perspective: 1000px; /* Remove this if you don't want the 3D effect */

    pointer-events: none;

    z-index: 10000;
}

.card-info-container {
    width: 260px;
    height: 320px;

    margin-left: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;

    transform: scale(1);
    z-index: 9999;

    background-position: 0 0, 100% 0, 0 0, 0 100%;
    background-repeat: repeat-y, repeat-y, repeat-x, repeat-x;
    border-radius: 3rem;
    background-size: 3rem;

    &::before {
        background-size: 3rem;
        background-position: 0 0, 100% 0, 0 100%, 100% 100%;
        background-repeat: repeat;
        content: "";
        display: block;
        height: 100%;
        image-rendering: pixelated;
        left: 0;
        pointer-events: none;
        position: absolute;
        top: 0;
        width: 100%;
    }

    &::after {
        background-size: 3rem;
        background-repeat: repeat;
        content: "";
        display: block;
        height: calc(100% - 3rem);
        image-rendering: pixelated;
        width: calc(100% - 3rem);

        position: absolute;
        top: 1.5rem;
        left: 1.5rem;
        border-radius: 3rem;
    }

    &.common {
        background-image: url("../../assets/gui/commonBorderLeft.png"), url("../../assets/gui/commonBorderRight.png"),
            url("../../assets/gui/commonBorderTop.png"), url("../../assets/gui/commonBorderBottom.png");

        &::before {
            background-image: url("../../assets/gui/commonTopLeft.png"), url("../../assets/gui/commonTopRight.png"),
                url("../../assets/gui/commonBottomLeft.png"), url("../../assets/gui/commonBottomRight.png");
            background-position: 0 0, 100% 0, 0 100%, 100% 100%;
            background-repeat: no-repeat;
        }

        &::after {
            background-image: url("../../assets/gui/commonCenter.png");
            background-position: 0 0;
        }
    }

    &.uncommon {
        background-image: url("../../assets/gui/uncommonBorderLeft.png"), url("../../assets/gui/uncommonBorderRight.png"),
            url("../../assets/gui/uncommonBorderTop.png"), url("../../assets/gui/uncommonBorderBottom.png");

        &::before {
            background-image: url("../../assets/gui/uncommonTopLeft.png"), url("../../assets/gui/uncommonTopRight.png"),
                url("../../assets/gui/uncommonBottomLeft.png"), url("../../assets/gui/uncommonBottomRight.png");
            background-position: 0 0, 100% 0, 0 100%, 100% 100%;
            background-repeat: no-repeat;
        }

        &::after {
            background-image: url("../../assets/gui/uncommonCenter.png");
            background-position: 0 0;
        }
    }

    &.rare {
        background-image: url("../../assets/gui/rareBorderLeft.png"), url("../../assets/gui/rareBorderRight.png"), url("../../assets/gui/rareBorderTop.png"),
            url("../../assets/gui/rareBorderBottom.png");

        &::before {
            background-image: url("../../assets/gui/rareTopLeft.png"), url("../../assets/gui/rareTopRight.png"), url("../../assets/gui/rareBottomLeft.png"),
                url("../../assets/gui/rareBottomRight.png");
            background-position: 0 0, 100% 0, 0 100%, 100% 100%;
            background-repeat: no-repeat;
        }

        &::after {
            background-image: url("../../assets/gui/rareCenter.png");
            background-position: 0 0;
        }
    }

    &.epic {
        background-image: url("../../assets/gui/epicBorderLeft.png"), url("../../assets/gui/epicBorderRight.png"), url("../../assets/gui/epicBorderTop.png"),
            url("../../assets/gui/epicBorderBottom.png");

        &::before {
            background-image: url("../../assets/gui/epicTopLeft.png"), url("../../assets/gui/epicTopRight.png"), url("../../assets/gui/epicBottomLeft.png"),
                url("../../assets/gui/epicBottomRight.png");
            background-position: 0 0, 100% 0, 0 100%, 100% 100%;
            background-repeat: no-repeat;
        }

        &::after {
            background-image: url("../../assets/gui/epicCenter.png");
            background-position: 0 0;
        }
    }

    &.legendary {
        background-image: url("../../assets/gui/legendaryBorderLeft.png"), url("../../assets/gui/legendaryBorderRight.png"),
            url("../../assets/gui/legendaryBorderTop.png"), url("../../assets/gui/legendaryBorderBottom.png");

        &::before {
            background-image: url("../../assets/gui/legendaryTopLeft.png"), url("../../assets/gui/legendaryTopRight.png"),
                url("../../assets/gui/legendaryBottomLeft.png"), url("../../assets/gui/legendaryBottomRight.png");
            background-position: 0 0, 100% 0, 0 100%, 100% 100%;
            background-repeat: no-repeat;
        }

        &::after {
            background-image: url("../../assets/gui/legendaryCenter.png");
            background-position: 0 0;
        }
    }

    &-stats {
        overflow-y: overlay !important;

        position: relative;
        width: calc(90% - 3rem);
        height: calc(100% - 4rem);
        max-height: calc(100% - 4rem);

        z-index: 10000;

        &-grid {
            display: grid;
            grid-template-columns: 1fr 1fr;
            column-gap: 0.5rem;
            grid-template-rows: 1fr 1fr 1fr;
        }

        p {
            &.small-text {
                font-size: 1rem;

                &.highlight {
                    color: #f8da53;
                }
            }

            &.tiny-text {
                font-size: 0.65rem;

                opacity: 0.9;
                &.highlight {
                    color: #f8da53;
                }
            }
        }
    }
}

.card-base {
    display: flex;
    align-items: center;

    &.common {
        ::-webkit-scrollbar {
            width: 1rem;
            max-width: 0.5rem;
            margin-left: 1rem;
            padding: 1rem;
        }

        /* Track */
        ::-webkit-scrollbar-track {
            background: #3d3636;
            border-radius: 0.2rem;
            border: 2px solid #4c5452;
        }

        /* Handle */
        ::-webkit-scrollbar-thumb {
            background: #b4b3a8;
            border-radius: 0.2rem;
            border: 2px solid #281e28;
        }

        /* Handle on hover */
        ::-webkit-scrollbar-thumb:hover {
            background: #d5d4d0;
            border: 2px solid #281e28;
        }
    }

    &.uncommon {
        ::-webkit-scrollbar {
            width: 1rem;
            max-width: 0.5rem;
            margin-left: 1rem;
            padding: 1rem;
        }

        /* Track */
        ::-webkit-scrollbar-track {
            background: #281e28;
            border-radius: 0.2rem;
            border: 2px solid #246010;
        }

        /* Handle */
        ::-webkit-scrollbar-thumb {
            background: #a17537;
            border-radius: 0.2rem;
            border: 2px solid #281e28;
        }

        /* Handle on hover */
        ::-webkit-scrollbar-thumb:hover {
            background: #b8a64e;
            border: 2px solid #281e28;
        }
    }

    &.rare {
        ::-webkit-scrollbar {
            width: 1rem;
            max-width: 0.5rem;
            margin-left: 1rem;
            padding: 1rem;
        }

        /* Track */
        ::-webkit-scrollbar-track {
            background: #281e28;
            border-radius: 0.2rem;
            border: 2px solid #21446a;
        }

        /* Handle */
        ::-webkit-scrollbar-thumb {
            background: #aba885;
            border-radius: 0.2rem;
            border: 2px solid #281e28;
        }

        /* Handle on hover */
        ::-webkit-scrollbar-thumb:hover {
            background: #e6e2b8;
            border: 2px solid #281e28;
        }
    }

    &.epic {
        ::-webkit-scrollbar {
            width: 1rem;
            max-width: 0.5rem;
            margin-left: 1rem;
            padding: 1rem;
        }

        /* Track */
        ::-webkit-scrollbar-track {
            background: #3d3636;
            border-radius: 0.2rem;
            border: 2px solid #592b78;
        }

        /* Handle */
        ::-webkit-scrollbar-thumb {
            background: #9c9aa5;
            border-radius: 0.2rem;
            border: 2px solid #281e28;
        }

        /* Handle on hover */
        ::-webkit-scrollbar-thumb:hover {
            background: #d5d2e2;
            border: 2px solid #281e28;
        }
    }

    &.legendary {
        ::-webkit-scrollbar {
            width: 1rem;
            max-width: 0.5rem;
            margin-left: 1rem;
            padding: 1rem;
        }

        /* Track */
        ::-webkit-scrollbar-track {
            background: #3d3636;
            border-radius: 0.2rem;
            border: 2px solid #4c5452;
        }

        /* Handle */
        ::-webkit-scrollbar-thumb {
            background: #b4b3a8;
            border-radius: 0.2rem;
            border: 2px solid #281e28;
        }

        /* Handle on hover */
        ::-webkit-scrollbar-thumb:hover {
            background: #d5d4d0;
            border: 2px solid #281e28;
        }
    }
}
</style>

import Vue from "vue";
import { ethers } from "ethers";
import { createNotification } from "@/libs/storeHelper";
import { getChestERC1155Rewards } from "@/libs/web3Helper";

const CHEST_ABI = require("@/plugins/artefacts/chest.json");

const state = () => ({
    itemControllerContract: null,

    currentItem: "",
    consumerContracts: {},

    AVAILABLE_CHESTS: ["woodenChest", "bronzeChest", "silverChest", "goldenChest"],

    pendingCommits: {
        woodenChest: undefined,
        bronzeChest: undefined,
        silverChest: undefined,
        goldenChest: undefined,
    },

    chestStatus: {
        woodenChest: false,
        bronzeChest: false,
        silverChest: false,
        goldenChest: false,
    },
});

const mutations = {
    initializeContract(state, contract) {
        state.itemControllerContract = contract;
    },

    setConsumerContracts(state, contracts) {
        state.consumerContracts = contracts;
    },

    setCurrentItem(state, item) {
        state.currentItem = item;
    },

    setCommitData(state, { chest, commitData }) {
        Vue.set(state.pendingCommits, chest, commitData);
    },

    setChestStatus(state, { chest, status }) {
        Vue.set(state.chestStatus, chest, status);
    },
};

const actions = {
    async boot({ commit, dispatch, rootState }, { contractAddress }) {
        const abi = require("@/plugins/artefacts/itemController.json");

        const { signer, defaultSigner } = rootState.site;
        const contract = new ethers.Contract(contractAddress, abi, signer || defaultSigner);

        commit("initializeContract", contract);

        await dispatch("getConsumerContracts");
    },

    setCurrentItem({ commit }, item) {
        commit("setCurrentItem", item);
    },

    async getConsumerContracts({ commit, state, dispatch, rootState }) {
        const { relayerContract } = rootState.contracts.relayer;
        const { itemsDataRaw } = rootState.content;
        const { storedAccount } = rootState.user;

        let contractNames = [...new Set(itemsDataRaw.map((item) => item.contract))];

        const consumerContracts = await relayerContract.getMultipleContracts(contractNames);
        const consumerContractsData = {};

        const { signer, defaultSigner } = rootState.site;

        for (let i = 0; i < consumerContracts.length; i++) {
            consumerContractsData[consumerContracts[i].name] = consumerContracts[i].addr;

            if (state.AVAILABLE_CHESTS.includes(consumerContracts[i].name)) {
                if (Number(consumerContracts[i].addr) == 0) continue;

                const contract = new ethers.Contract(consumerContracts[i].addr, CHEST_ABI, signer || defaultSigner);

                const commitData = await contract.commits(storedAccount);

                if (Number(commitData.commitBlock) !== 0) {
                    const data = {
                        commitBlock: Number(commitData.commitBlock),
                        amount: Number(commitData.amount),
                        randomNumber: Number(commitData.randomNumber),
                    };
                    commit("setCommitData", {
                        chest: consumerContracts[i].name,
                        commitData: data,
                    });
                }
            }
        }

        commit("setConsumerContracts", consumerContractsData);
    },

    async getChestsCommits({ state, dispatch, rootState }) {
        const { signer, defaultSigner } = rootState.site;
        const { storedAccount } = rootState.user;

        for (let i = 0; i < state.AVAILABLE_CHESTS.length; i++) {
            const chest = state.AVAILABLE_CHESTS[i];
            const contract = new ethers.Contract(state.consumerContracts[chest], CHEST_ABI, signer || defaultSigner);

            const commitData = await contract.commits(storedAccount);

            if (Number(commitData.commitBlock) !== 0) {
                state.pendingCommits[chest] = {
                    commitBlock: Number(commitData.commitBlock),
                    amount: Number(commitData.amount),
                    randomNumber: Number(commitData.randomNumber),
                };
            }
        }
    },

    async useSelectedItem({ state, dispatch, rootState }, { selectedItem }) {
        const consumerContract = state.itemControllerContract;
        const { baseGwei } = rootState.site.settings;
        const { balances } = rootState.user;
        const { planterSpots } = rootState.planter;

        const indexes = [];

        for (let i = 0; i < planterSpots.length; i++) {
            if (!planterSpots[i] || planterSpots[i] === "") continue;
            indexes.push(i);
        }

        const { itemsData } = rootState.content;
        const itemUses = itemsData[selectedItem].uses;
        const consumableAddress = state.consumerContracts[itemsData[selectedItem].contract];
        const tokenId = itemsData[selectedItem].tokenId;

        let splitIndexes = [];
        let tx;

        let amount = 1;

        if (indexes.length > itemUses) {
            amount = Math.ceil(indexes.length / itemUses);

            for (let i = 0; i < indexes.length; i += itemUses) {
                splitIndexes.push(indexes.slice(i, i + itemUses));
            }

            tx = await consumerContract.useItems(tokenId, consumableAddress, splitIndexes, amount, {
                //gasPrice: baseGwei,
            });
        } else {
            tx = await consumerContract.useItem(tokenId, consumableAddress, indexes, {
                //gasPrice: baseGwei,
            });
        }

        if (balances.items[selectedItem].count < amount) {
            createNotification({
                dispatch,
                message: `You don't have enough ${selectedItem} to use`,
                type: "error",
                id: `not-enough-${selectedItem}`,
            });
            return;
        }

        dispatch("planter/resetPlanter", {}, { root: true });

        createNotification({
            dispatch,
            message: `Tx: Using ${amount} x ${selectedItem}`,
            type: "warn",
            id: `${tx.hash}`,
            showSpinner: true,
        });
        await tx.wait();

        dispatch("user/getFarmStats", {}, { root: true });
        dispatch("site/notifications/remove", tx.hash, { root: true });

        createNotification({
            dispatch,
            message: `Used ${amount} x ${selectedItem}`,
            duration: 5000,
        });
    },

    async useChest({ state, dispatch, rootState }, { name, amount }) {
        const consumerContract = state.itemControllerContract;
        const { baseGwei } = rootState.site.settings;
        const { balances } = rootState.user;
        const { itemsDataRaw } = rootState.content;
        const chestData = itemsDataRaw.find((item) => item.name === name);
        const tokenId = chestData.tokenId;
        const chestBalance = balances.items[chestData.name];

        if (amount < 0 || amount > 20) {
            createNotification({
                dispatch,
                message: `Invalid amount! (0-20)`,
                type: "error",
                id: `invalid-amount`,
            });
            return;
        }

        const consumableAddress = state.consumerContracts[chestData.name];

        if (chestBalance < amount) {
            createNotification({
                dispatch,
                message: `You don't have enough ${chestData.name} to use`,
                type: "error",
                id: `not-enough-${chestData.name}`,
            });
            return;
        }
        let tx;
        if (amount > 1) {
            const values = [];
            for (let i = 0; i < amount; i++) {
                values.push([]);
            }
            tx = await consumerContract.useItems(tokenId, consumableAddress, values, amount, {
                //gasPrice: baseGwei,
            });
        } else {
            tx = await consumerContract.useItem(tokenId, consumableAddress, [], {
                //gasPrice: baseGwei,
            });
        }

        createNotification({
            dispatch,
            message: `Tx: Comitting ${amount} x ${chestData.name}`,
            type: "warn",
            id: `${tx.hash}`,
            showSpinner: true,
        });
        await tx.wait();

        dispatch("site/notifications/remove", tx.hash, { root: true });

        createNotification({
            dispatch,
            message: `${amount} x ${chestData.name} commited!`,
            duration: 5000,
            id: "opened-chest",
        });

        dispatch("getChestsCommits");
    },

    async revealChest({ state, commit, dispatch, rootState }, { name }) {
        const { baseGwei } = rootState.site.settings;
        const { itemsDataRaw } = rootState.content;
        const chestData = itemsDataRaw.find((item) => item.name === name);

        const consumableAddress = state.consumerContracts[chestData.name];

        const { signer, defaultSigner } = rootState.site;
        const contract = new ethers.Contract(consumableAddress, CHEST_ABI, signer || defaultSigner);

        const tx = await contract.open();

        commit("setChestStatus", { chest: chestData.name, status: true });

        createNotification({
            dispatch,
            message: `Tx: Revealing ${chestData.name}`,
            type: "warn",
            id: `${tx.hash}`,
            showSpinner: true,
        });

        const receipt = await tx.wait({
            //gasPrice: baseGwei,
        });
        const itemsReceived = getChestERC1155Rewards(receipt, contract);
        commit("user/setLastItemsReceived", itemsReceived, { root: true });

        dispatch("site/setCurrentModal", "chestResult", { root: true });

        commit("setChestStatus", { chest: chestData.name, status: false });
        dispatch("site/notifications/remove", tx.hash, { root: true });
        createNotification({
            dispatch,
            message: `Chests Revealed!`,
            duration: 5000,
        });
        commit("setCommitData", {
            chest: chestData.name,
            commitData: undefined,
        });
        dispatch("user/getBalances", {}, { root: true });
    },
};

const getters = {};

export default {
    state,
    mutations,
    getters,
    actions,
    namespaced: true,
};

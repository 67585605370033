<template>
    <div v-show="planterState === 'inactive' && initialized && !loading" class="side-menu">
        <div class="side-menu-avatar">
            <SideMenuAvatar></SideMenuAvatar>
        </div>

        <div class="side-menu-buttons">
            <SideMenuItem v-if="!isLoggedIn" @clicked="tryConnectMetamask" :name="'connect'"></SideMenuItem>

            <template v-if="isLoggedIn">
                <SideMenuItem @clicked="goToRoute('house')" :name="'house'"></SideMenuItem>
                <SideMenuItem @clicked="goToRoute('farm')" :name="'farm'"></SideMenuItem>
                <SideMenuItem @clicked="goToRoute('marketplace')" :name="'market'"></SideMenuItem>
                <SideMenuItem @clicked="goToRoute('tavern')" :name="'tavern'"></SideMenuItem>
            </template>

            <SideMenuItem @clicked="goToRoute('overworld')" v-if="!isOverworld" :name="'overworld'"></SideMenuItem>
        </div>
    </div>
</template>

<script>
import SideMenuAvatar from "@/components/sidebar/SideMenuAvatar.vue";
import SideMenuItem from "@/components/sidebar/SideMenuItem.vue";
import { mapActions, mapGetters, mapState } from "vuex";

export default {
    name: "SideMenu",

    components: {
        SideMenuAvatar,
        SideMenuItem,
    },

    props: {},

    data() {
        return {};
    },

    async mounted() {},

    methods: {
        ...mapActions("site", ["setCurrentModal", "connectWallet"]),

        goToRoute(name) {
            if (this.$route.name === name) return;

            this.$router.push({ name });
        },

        async tryConnectMetamask() {
            await this.connectWallet({
                forceConnect: true,
            });
        },
    },

    computed: {
        ...mapState("site", ["initialized", "loading"]),
        ...mapGetters("user", ["isLoggedIn"]),
        ...mapState("planter", ["planterState"]),

        isOverworld() {
            return this.$route.name === "overworld";
        },
    },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.side-menu {
    position: fixed;
    top: 1.5rem;
    left: 1.5rem;
    z-index: 10000;
    transform: scale(0.9) translateX(0.5rem);
    transform-origin: top left;

    transform-origin: 0 0;
    gap: 0.25rem;

    pointer-events: none;

    @media screen and (max-width: 768px) {
        top: 0;
        left: 0;
    }

    &-buttons {
        pointer-events: all;
        width: 10rem;
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
        align-items: center;

        @media screen and (max-width: 768px) {
            transform: scale(0.9) translateY(-2.5rem);
            transform-origin: top left;
        }

        @media screen and (max-width: 512px) {
            transform: scale(0.75);
            transform-origin: top left;
        }
    }

    animation: slide-in 0.2s ease-in-out;
}

.side-menu-avatar {
    transform: scale(1);

    @media screen and (max-width: 768px) {
        transform: scale(0.9) translateY(0);
        transform-origin: top left;
    }

    @media screen and (max-width: 512px) {
        transform: scale(0.75) translateY(2.5rem);
        transform-origin: top left;
    }
}

@keyframes slide-in {
    0% {
        left: -2rem;
    }
    100% {
        left: 1.5rem;
    }
}
</style>

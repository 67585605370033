import Vue from "vue";

const state = {
    gachaTxes: [],
    gachaResults: [],
    gachaLastResult: new Array(10).fill(1),
    gachaResultRaw: [],
    gachaLastSize: 1,
    gachaMaxSize: 9,
    gachaLoading: false,
    gachaLastType: "basic",

    feebleSeedCost: 1,
    picoSeedCost: 20,
    seedCost: 100,
    premiumSeedCost: 250,
};

const mutations = {
    addGachaResult(state, result) {
        state.gachaResults.push(result);
        state.gachaLastResult = result;
        state.gachaResultRaw = result.cropData;
    },

    addGachaTx(state, tx) {
        state.gachaTxes.push(tx);
    },

    clearGachaResults(state) {
        state.gachaResults = [];
    },

    clearGachaTxes(state) {
        state.gachaTxes = [];
    },

    setGachaType(state, type) {
        state.gachaLastType = type;
    },

    clearGacha(state) {
        state.gachaResults = [];
        state.gachaTxes = [];
        state.gachaLastResult = new Array(10).fill(1);
        state.gachaResultRaw = [];

        Vue.set(state, "gachaLoading", false);
    },

    setGachaLastSize(state, size) {
        state.gachaLastSize = size;
    },

    setGachaLoading(state, loading) {
        state.gachaLoading = loading;
    },
};

const actions = {
    drawGacha({ commit, dispatch, state, rootState }, payload) {
        const { size, result } = payload;

        const gachaTx = {};
        commit("addGachaTx", gachaTx);

        const { cropsDataRaw } = rootState.content;

        result.cropData = [];
        for (let i = 0; i < result.length; i++) {
            const cropData = cropsDataRaw.find((c) => c.tokenId === result[i]);
            result.cropData.push(cropData);
        }

        commit("addGachaResult", result);
        commit("setGachaLastSize", size);

        dispatch("site/setCurrentModal", "gachaResult", { root: true });
    },

    clearGacha({ commit }) {
        commit("clearGacha");
    },
};

const getters = {};

const panzoomModule = {
    state: () => state,
    mutations,
    getters,
    actions,
    namespaced: true,
};

export default panzoomModule;

<template>
  <div class="header">
      <img :src="require('../../assets/gui/defaultHeader.png')">
      <img v-if="headerType !== 'default'" :src="require('../../assets/gui/' + headerType + 'Header.png')"
          class="overlay">
      <p class="header-text">
          {{ text }}
      </p>
  </div>
</template>

<script>
export default {
  name: "ModalHeader",

  components: {},

  props: {
      text: {
          type: String,
          default: "",
      },
      headerType: {
          type: String,
          default: "default",
      }
  },

  data() {
      return {};
  },

  methods: {},

  computed: {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.header {
  width: 100%;
  height: 100%;
  min-height: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;

  transition: all 0.1s ease-in;
  cursor: pointer;

  
  user-select: none;

  padding: 0 1rem;

  img {
      position: absolute;
      top: 0;
  }

  .overlay {
      position: absolute;
      top: -50px;
  }
}

::v-deep.header-text {
  font-size: 1.75rem!important;
  margin-bottom: 3rem;
  position: absolute;
  top: 3rem;

  @media screen and (max-width: 512px) {
      font-size: 1.5rem;
      margin-bottom: 0.25rem;
  }
}
</style>

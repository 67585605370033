import { ethers } from "ethers";
import { createNotification, getTxErrorMessage } from "@/libs/storeHelper";

const state = () => ({
    pfpContract: null,

    hasAllowance: false,
});

const mutations = {
    initializeContract(state, contract) {
        state.pfpContract = contract;
    },

    setAllowance(state, allowance) {
        state.hasAllowance = allowance;
    },
};

const actions = {
    boot({ commit, dispatch, rootState }, { contractAddress }) {
        const abi = require("@/plugins/artefacts/nft.json");

        const { signer, defaultSigner } = rootState.site;
        const contract = new ethers.Contract(contractAddress, abi, signer || defaultSigner);

        commit("initializeContract", contract);

        dispatch("getAllowanceForStake");
    },

    async getAllowanceForStake({ state, commit, rootState }) {
        const contract = state.pfpContract;
        const stakeContract = rootState.contracts.pfpController.pfpControllerContract;

        const { storedAccount } = rootState.user;

        const allowance = await contract.isApprovedForAll(storedAccount, stakeContract);

        commit("setAllowance", allowance);
    },

    async setAllowanceForStake({ state, rootState, dispatch }, { approved = true }) {
        const contract = state.pfpContract;
        const stakeContract = rootState.contracts.pfpController.pfpControllerContract;

        const { baseGwei } = rootState.site.settings;

        const tx = await contract.setApprovalForAll(stakeContract, approved, {});

        createNotification({
            dispatch,
            message: `Tx: Approving PFP!`,
            type: "warn",
            id: tx.hash,
        });

        await tx.wait(1);

        dispatch("getAllowanceForStake");
        dispatch("site/notifications/remove", tx.hash, { root: true });
    },
};

const getters = {};

export default {
    state,
    mutations,
    getters,
    actions,
    namespaced: true,
};

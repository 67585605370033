<template>
    <div>
        <div class="game-frame" ref="game">
            <div class="game-frame-marketplace">
                <img style="width: 100%; height: 100%" src="../../../public/images/hermit.gif" />
                <img
                    :class="[hasSummonPending ? 'dim-runes' : '']"
                    style="width: 100%; height: 100%; position: absolute; top: 0; left: 0"
                    src="../../../public/images/hermit_runes.png"
                />

                <MapBanner @clicked="openDruid()" :text="'Druid'" :position="{ x: 145, y: 170 }"></MapBanner>

                <MapBanner @clicked="openAdventurers()" :text="'Adventurers'" :position="{ x: 350, y: 25 }"></MapBanner>

                <MapBanner @clicked="openDruidSummon()" :text="'Summon'" :position="{ x: 650, y: 290 }"></MapBanner>

                <div :class="[isSummoning ? 'fade-in' : 'fade-out']" class="clouds-container">
                    <img
                        v-for="(animation, index) in animations"
                        v-show="summonAnimationsToggle[animation]"
                        :key="index"
                        style="pointer-events: none; width: 960px; position: absolute; top: 0; left: 8px"
                        :src="getAnimation(animation)"
                    />
                </div>

                <div v-if="showSpiritAnimation" :key="summoningUpdateKey">
                    <img
                        v-for="(animation, index) in soulAnimations"
                        v-show="summonAnimationsToggle[animation]"
                        :key="index"
                        style="pointer-events: none; width: 960px; position: absolute; top: 0; left: 8px"
                        :src="getAnimation(animation)"
                    />
                </div>

                <div v-if="lastTokenRevealed" class="altar-summon" @click="tryShowAdventurerCard">
                    <MapBanner :secondary="true" :text="'Ancient Soul'"></MapBanner>
                    <img :src="getLastSummonedSpirit" />
                </div>

                <div v-if="lastTokenId != 0 && !buttonLoaders['revealLastSummon']" class="altar-button">
                    <SmolButton v-if="countdown === 0" :disabled="countdown > 0" :buttonId="'revealLastSummon'" @clicked="tryReveal" :text="revealText" />

                    <p v-else class="big-text pulse-text">{{ countdown }}</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";

export default {
    name: "HermitCaveScreen",

    components: {},

    props: {},

    data() {
        return {
            seed: 0,

            adventurerMetadata: {
                rarity: 4,
            },

            animations: ["start_summon", "loop_summon", "end_summon"],
            soulAnimations: ["spawn_common", "spawn_epic", "spawn_rare", "spawn_uncommon", "spawn_legendary"],
        };
    },

    mounted() {
        const panzoomContainer = this.$refs.game;
        this.initializePanzoom(panzoomContainer);
        this.resetZoom();

        this.seed = Math.floor(Math.random() * 4) + 1;
    },

    beforeDestroy() {
        this.destroyPanzoom();
        this.resetZoom();
        this.resetCountdown();
    },

    methods: {
        ...mapActions("site", ["setCurrentModal", "buttonLoaders"]),
        ...mapActions("contracts", ["callContractFunction"]),
        ...mapActions("contracts/adventurers", ["resetCountdown", "setSelectedAdventurer"]),
        ...mapActions("panzoom", ["initializePanzoom", "destroyPanzoom", "zoomTo", "resetZoom"]),
        ...mapActions("site/npcDialogue", ["setSelectedNPC"]),

        getAnimation(animationName) {
            return require(`@/assets/adventurers/summon/${animationName}.gif`);
        },

        tryReveal() {
            if (this.countdown > 0) {
                return;
            }

            this.callContractFunction({
                contract: "adventurers",
                functionName: "revealLastSummon",
                params: {},
            });
        },

        tryShowAdventurerCard() {
            const adventurerMetadata = this.selectedAdventurer;

            this.setSelectedAdventurer({
                adventurerMetadata,
                show: true,
            });
        },

        openDruid() {
            this.setCurrentModal("druid");
            this.setSelectedNPC({ selectedNPC: "druid", show: true });
        },

        openAdventurers() {
            this.setCurrentModal("adventurers");
            this.setSelectedNPC({ selectedNPC: "adventurers", show: true });
        },

        openDruidSummon() {
            this.setCurrentModal("druidSummon");
            this.setSelectedNPC({ selectedNPC: "summon", show: true });
        },
    },

    computed: {
        ...mapGetters("contracts/adventurers", ["hasSummonPending"]),
        ...mapState("contracts/adventurers", [
            "summonAnimationsToggle",
            "lastTokenId",
            "summoningUpdateKey",
            "countdown",
            "lastTokenRarity",
            "lastTokenRevealed",
            "showSpiritAnimation",
            "isSummoning",
            "selectedAdventurer",
        ]),

        slimeIdle() {
            const slime = require("@/assets/slime_idle.gif");
            return slime;
        },

        soulIdle() {
            const soul = require(`@/assets/soul_${this.seed}.gif`);
            return soul;
        },

        revealText() {
            if (this.countdown > 0) {
                return this.countdown + "...";
            } else {
                return "Reveal";
            }
        },

        getLastSummonedSpirit() {
            return require(`@/assets/adventurers/souls/soul_${this.lastTokenRarity}.gif`);
        },
    },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.game-frame {
    min-width: 960px;
    min-height: 480px;
    width: 100%;
    height: 100%;

    &-marketplace {
        width: 960px;
        height: 480px;
        border-radius: 0.25rem;

        border-image: url("../../../public/images/buttonBg.png") 6 fill repeat;
        border-image-width: 1rem;
        border-image-outset: 0.5rem;

        position: absolute;
        top: calc(50% - 240px);
        left: calc(50% - 480px);
    }
}

.clouds-container {
    transition: opacity 1s linear;
}

.altar-button {
    position: absolute;
    top: 160px;
    left: 660px;

    width: 8rem;

    .smol-button-wrapper {
        height: 1.5rem !important;

        &::v-deep p {
            font-size: 1rem !important;
        }
    }

    &::v-deep p {
        font-size: 2rem !important;
    }

    animation: hover 6s infinite;
}

.altar-summon {
    position: absolute;
    top: 145px;
    left: 655px;

    width: 8rem;

    img {
        width: 6rem;
        height: 6rem;

        opacity: 0.9;
    }

    cursor: pointer;

    .smol-button-wrapper {
        height: 1.5rem !important;
    }

    .map-banner {
        pointer-events: none;
    }

    &::v-deep p {
        font-size: 1rem !important;
    }

    &:hover {
        transform: scale(1.1);
        .map-banner {
            pointer-events: none;
            animation: none;
        }
    }
}

@keyframes hover {
    0% {
        transform: translateY(0);
    }
    50% {
        transform: translateY(-0.25rem);
    }
    100% {
        transform: translateY(0);
    }
}

@keyframes fade-in {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@keyframes fade-out {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}

@keyframes pulse-text {
    0% {
        transform: scale(1);
    }
    100% {
        transform: scale(1.1);
    }
}

.pulse-text {
    transform: none;
    p {
        font-size: 2rem !important;
    }
    animation: pulse-text 1s infinite;
}

.fade-in {
    opacity: 1;
}

.fade-out {
    opacity: 0;
}

.dim-runes {
    animation: dim-animation 1s infinite;
}
</style>

<template>
    <div class="notification-container" :class="[notification.type || '']">
        <p class="notification-container-text">
            {{ notification?.message || "" }}
        </p>
    </div>
</template>

<script>
export default {
    name: "BaseNotification",

    components: {},

    props: {
        notification: {
            type: Object,
            default: () => {},
        },
    },

    data() {
        return {};
    },

    methods: {},

    computed: {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.notification-container {
    width: 24rem;
    height: 5rem;
    border-image: url("../../../public/images/modalBg.png") 6 fill repeat;
    border-image-width: 1rem;

    display: flex;
    justify-content: center;
    align-items: center;

    &-text {
        font-size: 1.5rem;
    }

    animation: slide-in 0.15s ease-in-out;

    &.error {
        filter: hue-rotate(-20deg);
    }

    &.success {
        filter: hue-rotate(90deg);
    }

    &.warn {
        filter: hue-rotate(17deg);
    }
}

@keyframes slide-in {
    0% {
        transform: translateX(100%) translateY(32px);
    }
    100% {
        transform: translateX(0%) translateY(0px);
    }
}
</style>

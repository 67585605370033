<template>
    <div class="progress">
        <div class="bar" :class="[type]">
            <div class="progress-value"></div>
        </div>
    </div>
</template>

<script>
export default {
    name: "AdventurerCard",

    components: {},

    props: {
        progress: {
            type: Number,
            default: 0,
        },
        type: {
            type: String,
            default: "health",
        },
    },

    data() {
        return {};
    },

    methods: {},

    computed: {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
body {
    background: black;
}

.progress {
    --progress: 100%;

    width: 100%;
    height: 6px;

    position: relative;

    &::before {
        content: "";
        position: absolute;
        bottom: -6px;
        left: 0px;
        width: calc(100% + 4px);
        height: 2px;
        background: rgba(0, 0, 0, 0.25);
        z-index: 0;
    }
}

.progress .bar {
    width: var(--progress);
    height: 100%;
    background-repeat: repeat;
    animation: shine 4s ease-in infinite;

    border-top: 2px solid #fff0f0;
    border-bottom: 2px solid #8a97a6;
    border-right: 3px solid #8a97a6;
    border-left: 1px solid #fff0f0;

    transition: width 3s ease;

    &.health {
        background: linear-gradient(#fc1c1f, #8b0909, #d20e12);
    }

    &.experience {
        background: linear-gradient(#8a97a6, #8a97a6, #fff0f0);
    }

    &.mana {
        background: linear-gradient(#1e90ff, #0d4d8e, #0d4d8e);
    }

    &.energy {
        background: linear-gradient(#83d859, #2c6738, #589e35);
    }
}

@property --progress {
    syntax: "<length>";
    initial-value: 0%;
    inherits: true;
}

@keyframes shine {
    0% {
        background-position: 0 0;
    }
    100% {
        background-position: 50px 0;
    }
}

@keyframes end {
    0%,
    100% {
        box-shadow: 0 0 10px 0px #32844d;
    }
    50% {
        box-shadow: 0 0 15px 5px #32844d;
    }
}
</style>

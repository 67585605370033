<template>
    <div class="growth-stage">
        <div class="growth-stage-connect">
            <div class="divider"></div>
        </div>

        <div class="growth-stage-wrapper">
            <div v-for="(s, sI) in maxStage" :key="'_s' + s" class="growth-stage-box" :class="sI <= stage ? 'filled' : 'empty'"></div>
        </div>
    </div>
</template>

<script>
export default {
    name: "BasicBox",

    components: {},

    props: {
        stage: {
            type: Number,
            default: 1,
        },
    },

    data() {
        return {
            maxStage: 5,
        };
    },

    methods: {},

    computed: {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.growth-stage {
    display: flex;
    gap: 0.25rem;
    position: relative;
    width: 100%;
    height: 1.5rem;

    &-connect {
        position: absolute;
        top: 0;
        left: 0;
    }

    &-wrapper {
        width: 100%;
        display: flex;
        gap: 0.25rem;
    }

    &-box {
        width: 20%;
        height: 1rem;
        border: 1px solid black;
        background-color: red;

        border-radius: 0.25rem;

        &.empty {
            background-color: #743f39;
            border: 1px solid #3f2832;
        }

        &.filled {
            background-color: #5ab552;
            border: 1px solid #743f39;
        }
    }
}
</style>

<template>
    <div class="form-row">
        <p class="small-text base-form-input-label">{{ label }}</p>
        <div class="base-input">
            <input v-if="type === 'text'" :placeholder="placeholder" v-model="text" />
            <input v-else-if="type === 'number'" :min="minAmount" :placeholder="placeholder" type="number" v-model="text" />
            <RangeInput v-else-if="type === 'range'" :min="1" :max="maxAmount" :step="1" class="form-control-range"
                id="formControlRange" v-model="number" />
        </div>
    </div>
</template>

<script>
import RangeInput from "@/components/ui/RangeInput.vue";

export default {
    name: "BaseInput",

    components: { RangeInput },

    props: {
        value: {
            type: String,
            default: "",
        },
        label: {
            type: String,
            default: "",
        },
        placeholder: {
            type: String,
            default: "",
        },

        maxAmount: {
            type: Number,
            default: 100,
        },
        type: {
            type: String,
            default: "text",
        },
        minAmount: {
            type: Number,
            default: 0
        }
    },

    data() {
        return {};
    },

    methods: {},

    computed: {
        text: {
            get() {
                return this.value;
            },
            set(newValue) {
                this.$emit("input", newValue);
            },
        },
        number: {
            get() {
                return Number(this.value);
            },
            set(newValue) {
                this.$emit("input", String(newValue));
            }
        },
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.form-row {
    display: flex;
    gap: 1rem;

    justify-content: center;
    align-items: center;

    p {
        text-align: start;
        width: 40%;
    }
}

.base-input {
    width: 100%;
    height: 100%;
    min-height: 3rem;
    max-height: 3rem;

    display: flex;
    justify-content: space-between;
    align-items: center;

    transition: all 0.1s ease-in;
    cursor: pointer;

    user-select: none;

    border-image: url("../../assets/gui/inputBg.png") 6 fill repeat;
    border-image-width: 0.75rem;

    input {
        width: 100%;
        height: 100%;
        min-height: 2rem;

        border: none;
        outline: none;
        background: transparent;

        font-weight: 600;
        color: #fff;

        padding: 0rem 1rem;

        font-family: "Valley", sans-serif;
        font-size: 1.5rem;
        letter-spacing: 0.1rem;

        &::placeholder {
            color: #fff;
            opacity: 0.5;
        }
    }
}
</style>

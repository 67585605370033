import { ethers } from "ethers";
import { createNotification, getTxErrorMessage } from "@/libs/storeHelper";

const state = () => ({
    farmContract: null,
    farmProxyContract: null,

    levelCosts: [0, 100, 125, 150, 175, 250, 300, 350, 400, 450, 600, 700, 800, 900, 1000, 1250],
});

const mutations = {
    initializeContract(state, contract) {
        state.farmContract = contract;
    },

    initializeProxyContract(state, contract) {
        state.farmProxyContract = contract;
    },
};

const actions = {
    boot({ commit, rootState }, { contractAddress }) {
        const abi = require("@/plugins/artefacts/farm.json");

        const { signer, defaultSigner } = rootState.site;
        const contract = new ethers.Contract(contractAddress, abi, signer || defaultSigner);

        commit("initializeContract", contract);
    },

    async bootFarmProxy({ commit, dispatch, rootState }, { contractAddress }) {
        const abi = require("@/plugins/artefacts/farmProxy.json");

        const { signer, defaultSigner } = rootState.site;
        const contract = new ethers.Contract(contractAddress, abi, signer || defaultSigner);

        commit("initializeProxyContract", contract);
    },

    async getFarmerInfo({ state, rootState }) {
        const { storedAccount, farmLevel } = rootState.user;
        const contract = state.farmProxyContract;

        const contractData = await contract.getFarmerInfo(storedAccount);

        const lockedRatio = Number(await contract.LOCKED_RATIO()) / 1e18;

        const [farmPlots, oldPendingRewards] = contractData;
        let pendingRewards = 0;

        const { cropsDataRaw } = rootState.content;
        const currentTime = new Date().getTime();

        const farmPlotsData = farmPlots.map((plot, index) => {
            const cropId = Number(plot.cropId);
            const timestamp = Number(plot.plantTimestamp);
            const boostModifier = Number(plot.boostModifier);

            const cropData = cropsDataRaw.find((c) => c.tokenId === cropId);

            const farmPlots = 15 + farmLevel;

            const delta = currentTime - timestamp * 1000;
            const lifeDuration = (cropData?.life || 0) * 1000;
            const stageStep = lifeDuration / 5;

            const stage = Math.floor(delta / stageStep) - 1;

            if (cropData && stage >= 4) pendingRewards += cropData.reward + cropData.reward * (farmLevel * 0.0025);

            return {
                cropId,
                timestamp: timestamp * 1000,
                cropData: cropData || {},
                rarity: cropData?.rarity || "common",
                index,
                locked: index >= farmPlots,
                stage: stage > 4 ? 4 : stage,
                ready: stage >= 4,
                boostModifier: boostModifier,
            };
        });

        return {
            lockedRatio,
            farmPlotsData,
            pendingRewards,
        };
    },

    async plantCulture({ state, rootState, dispatch }) {
        const { planterSpots } = rootState.planter;
        const { cropsData } = rootState.content;
        const { baseGwei } = rootState.site.settings;

        const ids = [],
            indexes = [];

        for (let i = 0; i < planterSpots.length; i++) {
            if (!planterSpots[i] || planterSpots[i] === "") continue;

            const cropTokenId = cropsData[planterSpots[i]].tokenId;
            ids.push(cropTokenId);
            indexes.push(i);
        }

        const contract = state.farmProxyContract;
        const tx = await contract.plantCrops(ids, indexes, {
            //gasPrice: baseGwei,
        });

        dispatch("planter/resetPlanter", {}, { root: true });

        createNotification({
            dispatch,
            message: `Tx: Planting ${ids.length} seeds`,
            type: "warn",
            id: tx.hash,
            showSpinner: true,
        });

        await tx.wait();

        dispatch("user/getFarmStats", {}, { root: true });
        dispatch("site/notifications/remove", tx.hash, { root: true });

        dispatch("site/settings/playSound", { track: "plant.wav", volume: 0.1 }, { root: true });

        createNotification({
            dispatch,
            message: `Planted ${ids.length} seeds`,
            duration: 5000,
        });
    },

    async harvestCulture({ state, rootState, dispatch }) {
        const { planterSpots } = rootState.planter;
        const { baseGwei } = rootState.site.settings;

        const indexes = [];

        for (let i = 0; i < planterSpots.length; i++) {
            if (!planterSpots[i] || planterSpots[i] === "") continue;
            indexes.push(i);
        }

        const contract = state.farmProxyContract;
        const tx = await contract.harvestCrops(indexes, {
            //gasPrice: baseGwei,
        });

        dispatch("planter/resetPlanter", {}, { root: true });

        createNotification({
            dispatch,
            message: `Tx: Harvesting ${indexes.length} crops`,
            type: "warn",
            id: tx.hash,
            showSpinner: true,
        });

        await tx.wait();

        dispatch("user/getFarmStats", {}, { root: true });
        dispatch("site/notifications/remove", tx.hash, { root: true });

        dispatch("site/settings/playSound", { track: "harvest.wav", volume: 0.05 }, { root: true });

        createNotification({
            dispatch,
            message: `Harvested ${indexes.length} crops`,
            duration: 5000,
        });
    },

    async harvestAvailableCrops({ state, rootState, dispatch }) {
        const { farmPlots } = rootState.user;
        const { baseGwei } = rootState.site.settings;

        const indexes = farmPlots.filter((p) => p.ready && p.cropId).map((c) => c.index) || [];
        if (indexes.length === 0) return;

        const contract = state.farmProxyContract;
        const tx = await contract.harvestCrops(indexes, {
            //gasPrice: baseGwei,
        });

        dispatch("site/setCurrentModal", "planter", { root: true });

        createNotification({
            dispatch,
            message: `Tx: Harvesting ${indexes.length} crops`,
            type: "warn",
            id: tx.hash,
            showSpinner: true,
        });
        await tx.wait();

        dispatch("user/getFarmStats", {}, { root: true });
        dispatch("site/notifications/remove", tx.hash, { root: true });

        dispatch("site/settings/playSound", { track: "plant.wav", volume: 0.1 }, { root: true });

        createNotification({
            dispatch,
            message: `Harvested ${indexes.length} crops`,
            duration: 5000,
        });
    },

    async removeCulture({ state, rootState, dispatch }) {
        const { planterSpots } = rootState.planter;
        const { baseGwei } = rootState.site.settings;

        const indexes = [];

        for (let i = 0; i < planterSpots.length; i++) {
            if (!planterSpots[i] || planterSpots[i] === "") continue;
            indexes.push(i);
        }

        const tx = await state.farmContract.removeCrops(indexes, {
            //gasPrice: baseGwei,
        });

        dispatch("planter/resetPlanter", {}, { root: true });

        createNotification({
            dispatch,
            message: `Tx: Clearing ${indexes.length} crops`,
            type: "warn",
            id: tx.hash,
            showSpinner: true,
        });

        await tx.wait();

        dispatch("user/getFarmStats", {}, { root: true });
        dispatch("site/notifications/remove", tx.hash, { root: true });

        createNotification({
            dispatch,
            message: `Cleared ${indexes.length} crops`,
            duration: 5000,
        });
    },

    async upgradeFarm({ state, rootState, dispatch }, { nextLevel }) {
        const contract = state.farmContract;
        const { baseGwei } = rootState.site.settings;

        try {
            const tx = await contract.upgradeFarm(nextLevel, {
                //gasPrice: baseGwei,
            });

            createNotification({
                dispatch,
                message: `Tx: Upgrade valley!`,
                type: "warn",
                id: tx.hash,
                showSpinner: true,
            });

            await tx.wait();

            dispatch("user/getFarmStats", {}, { root: true });
            dispatch("site/notifications/remove", tx.hash, { root: true });

            createNotification({
                dispatch,
                message: `Upgraded valley to level ${nextLevel}!`,
                duration: 5000,
            });
        } catch (error) {
            dispatch("site/notifications/clearAll", {}, { root: true });
            getTxErrorMessage(dispatch, error);
        }
    },
};

const getters = {};

export default {
    state,
    mutations,
    getters,
    actions,
    namespaced: true,
};

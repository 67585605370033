<template>
    <div class="dialogue-container">
        <div @click="closeDialogue" class="close"></div>
        <img :src="require('../../assets/gui/characters/' + npcType + '.png')" class="portrait" />
        <div class="text-wrapper">
            <p class="small-text">{{ $t(`npcNames.${npcType}`) }}</p>
            <div class="divider"></div>
            <p class="small-text" v-for="(line, index) in displayedLines" :key="index">{{ line }}</p>
        </div>
    </div>
</template>

<script>
import { mapActions } from "vuex";
export default {
    name: "BaseNPCDialogue",

    props: {
        npcType: {
            type: String,
            default: "npc",
        },
    },

    data() {
        return {
            lines: [],
            displayedLines: [],
            currentLineIndex: 0,
            currentCharIndex: 0,
            currentLine: "",
        };
    },

    mounted() {
        this.lines = this.$t("npcsDialogue." + this.npcType + "." + (Math.floor(Math.random() * 2) + 1)).split("\n");
        this.typeLine();
    },

    methods: {
        ...mapActions("site/npcDialogue", ["closeDialogue"]),
        ...mapActions("site/settings", ["playSound"]),

        typeLine() {
            if (this.currentLineIndex < this.lines.length) {
                if (this.currentCharIndex < this.lines[this.currentLineIndex].length) {
                    this.currentLine += this.lines[this.currentLineIndex].charAt(this.currentCharIndex);
                    this.currentCharIndex++;
                    this.$nextTick(() => {
                        this.displayedLines.splice(this.currentLineIndex, 1, this.currentLine);
                    });
                    //this.playSound({ track: "typewriter_" + (Math.floor(Math.random() * 5) + 1) + ".wav", volume: 0.02 });

                    setTimeout(this.typeLine, 25);
                } else {
                    this.displayedLines.push("");
                    this.currentLineIndex++;
                    this.currentCharIndex = 0;
                    this.currentLine = "";
                    setTimeout(this.typeLine, 1000);
                }
            }
        },
    },
};
</script>

<style scoped lang="scss">
.dialogue-container {
    width: 602px;
    height: 150;
    background-image: url("../../assets/gui/dialogue.png");

    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: start;

    &-text {
        font-size: 1.5rem;
    }

    animation: bounce-in 0.5s ease-in-out;

    &.error {
        filter: hue-rotate(-20deg);
    }

    &.success {
        filter: hue-rotate(90deg);
    }

    &.warn {
        filter: hue-rotate(17deg);
    }

    .portrait {
        margin-top: 30px;
        margin-left: 40px;
    }
}

.close {
    width: 3rem;
    height: 3rem;
    position: absolute;
    right: -1.5rem;
    top: 0.5rem;
    background: url("../../../public/images/modalClose.png");
    background-size: 100% 100%;
    cursor: pointer;

    &:hover {
        filter: brightness(1.2);
    }

    @media screen and (max-width: 512px) {
        top: 0rem;
        right: 0rem;
    }
}

@keyframes bounce-in {
    0% {
        transform: scale(0);
    }

    50% {
        transform: scale(1.05);
    }

    100% {
        transform: scale(1);
    }
}

.text-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    text-align: left;
    max-width: 380px;
    margin-top: 30px;
    margin-left: 40px;
}

.console-text::after {
    content: "_";
    /* Cursor content */
    color: whitesmoke;
    display: inline-block;
    width: 10px;
    /* Cursor width */
    animation: blink-animation 1s infinite;
    /* Blinking animation */
}
</style>

import { ethers } from "ethers";
import { createNotification, getTxErrorMessage } from "@/libs/storeHelper";

const state = () => ({
    profileContract: null,
});

const mutations = {
    initializeContract(state, contract) {
        state.profileContract = contract;
    },
};

const actions = {
    boot({ commit, dispatch, rootState }, { contractAddress }) {
        const abi = require("@/plugins/artefacts/profile.json");

        const { signer, defaultSigner } = rootState.site;
        const contract = new ethers.Contract(contractAddress, abi, signer || defaultSigner);

        commit("initializeContract", contract);
    },

    async registerProfile({ state, dispatch, rootState }, { name, refCode }) {
        try {
            const contract = state.profileContract;

            const tx = refCode ? await contract.registerProfile(name, refCode) : await contract.changeName(name);

            createNotification({
                dispatch,
                message: `Tx: Registering profile`,
                type: "warn",
                id: tx.hash,
                showSpinner: true,
            });

            await tx.wait();

            dispatch("user/getProfile", {}, { root: true });
            dispatch("site/notifications/remove", tx.hash, { root: true });

            createNotification({
                dispatch,
                message: `Profile created!`,
                duration: 5000,
            });
        } catch (error) {
            dispatch("site/notifications/clearAll", {}, { root: true });
            getTxErrorMessage(dispatch, error);
        }
    },

    async registerReferralCode({ state, dispatch, rootState }, { referralCode }) {
        try {
            const contract = state.profileContract;
            const account = rootState.user.storedAccount;

            const tx = await contract.registerReferralCode(account, referralCode);

            createNotification({
                dispatch,
                message: `Tx: Registering referral code`,
                type: "warn",
                id: tx.hash,
                showSpinner: true,
            });

            await tx.wait();

            dispatch("user/getProfile", {}, { root: true });
            dispatch("site/notifications/remove", tx.hash, { root: true });

            createNotification({
                dispatch,
                message: `Referral code registered!`,
                duration: 5000,
            });
        } catch (error) {
            dispatch("site/notifications/clearAll", {}, { root: true });
            getTxErrorMessage(dispatch, error);
        }
    },

    async claimReferralCode({ state, dispatch, rootState }, { referralCode }) {
        try {
            const contract = state.profileContract;

            const tx = await contract.claimReferralCode(referralCode);

            createNotification({
                dispatch,
                message: `Tx: Claiming referral code`,
                type: "warn",
                id: tx.hash,
                showSpinner: true,
            });

            await tx.wait();

            dispatch("user/getProfile", {}, { root: true });
            dispatch("site/notifications/remove", tx.hash, { root: true });

            createNotification({
                dispatch,
                message: `Referral code claimed!`,
                duration: 5000,
            });
        } catch (error) {
            dispatch("site/notifications/clearAll", {}, { root: true });
            getTxErrorMessage(dispatch, error);
        }
    },
};

const getters = {};

export default {
    state,
    mutations,
    getters,
    actions,
    namespaced: true,
};
